import {
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState, useMemo, useCallback, useEffect, ChangeEvent } from "react";
import MultipleEmailInput from "components/MultipleEmailInput";
import Modal from "components/Modal";
import DataGrid from "components/Table/DataGrid";
import type { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import i18n from "common/i18n";
import useFetch from "utils/hook/useFetch";
import {
  inviteUserToWorkspaceBatch,
  searchWorkspaces,
} from "services/workspaceService";
import { WorkspaceModel } from "models/workspace";
import { PAGE_SIZE_OPTIONS } from "common/constants";
import { Collection, FilterCollectionPayload } from "models/collection";
import moment from "moment";
import { useToast } from "utils/hook/useNotification";
import { Role } from "models/team";
import RoleSelect from "components/RoleSelect";
import LoadingButton from "components/Button/LoadingButton";
import { assignUserAsTeamAdmin } from "services/teamService";
import { useParams } from "react-router-dom";

interface InviteTeamMemberModalProps {
  open: boolean;
  teamName: string;
  onClose: () => void;
}

const getColumns = (
  roles: {
    [wsId: string]: Role;
  },
  setRole: (wsId: string, role: Role) => void,
  disabled: boolean = false
): GridColDef<WorkspaceModel>[] => [
  {
    field: "Name",
    valueGetter: (_, row) => row.name,
    headerName: i18n.t(
      "TeamSettings.TeamUsers.InviteTeamMember.WorkspaceList.Header.Workspace"
    ),
    flex: 2,
    renderCell: (params) => (
      <Stack height="100%" direction="row" alignItems="center">
        <Typography color="primary">{params.value}</Typography>
      </Stack>
    ),
  },
  {
    field: "CreatedAt",
    valueGetter: (_, row) => moment(row.createdAt).format("MM/DD/YY"),
    headerName: i18n.t(
      "TeamSettings.TeamUsers.InviteTeamMember.WorkspaceList.Header.Created"
    ),
    renderCell: (params) => (
      <Stack height="100%" direction="row" alignItems="center">
        <Typography>{params.value}</Typography>
      </Stack>
    ),
    flex: 1,
  },
  {
    field: "Role",
    headerName: i18n.t("Common.Role"),
    valueGetter: (_, row) => row.id,
    renderCell: (params) => (
      <RoleSelect
        value={roles[params.value]}
        onRoleChange={(role) => setRole(params.value, role)}
        disabled={disabled}
      />
    ),
    flex: 1,
  },
];

export default function InviteTeamMemberModal({
  open,
  teamName,
  onClose,
}: InviteTeamMemberModalProps) {
  const { t } = useTranslation();
  const { showSuccess, showError } = useToast();

  const [userEmails, setEmails] = useState<string[]>([]);
  const [hasInvalidEmails, setHasInvalidEmail] = useState(false);
  const [payload, setPayload] = useState<FilterCollectionPayload>({
    page: 0,
    pageSize: PAGE_SIZE_OPTIONS[0],
    orderBy: "CreatedAt",
    order: "desc",
  });
  const [selection, setSelection] = useState<GridRowSelectionModel>();
  const [roles, setRole] = useState<{ [wsId: string]: Role }>({});
  const [invited, setInvited] = useState<{ [wsId: string]: Role }>({});

  const [value, setValue] = useState("AssignWorkspace");

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    setValue((event.target as HTMLInputElement).value);
  }

  const params = useParams();
  const currentTeamId = params.id;

  const {
    data,
    isLoading: isGettingWorkspaces,
    execute: getWorkspaces,
  } = useFetch<FilterCollectionPayload, Collection<WorkspaceModel>>({
    fn: (searchPayload) => {
      return searchWorkspaces(searchPayload);
    },
    payload: {
      filters: {
        text: "",
      },
      orderBy: "CreatedAt",
      order: "desc",
      page: 0,
      pageSize: PAGE_SIZE_OPTIONS[0],
    },
  });
  const {
    execute: inviteMembers,
    isLoading: isSendingInvitations,
    data: invitationResponse,
    error: invitationError,
  } = useFetch<
    {
      emails: string[];
      workspaceIds: string[];
      roles: { [wsId: string]: Role };
    },
    unknown
  >({
    fn: ({ emails, workspaceIds, roles: rolesObj }) =>
      inviteUserToWorkspaceBatch(
        emails,
        workspaceIds.map((w) => ({
          workspaceId: w,
          role: rolesObj[w] || Role.ReportViewer,
        }))
      ),
  });

  const { execute: executeChangeToAdmin, isLoading: isChangeToAdminLoading } =
    useFetch<{ teamId: string; emails: string[] }, string>({
      fn: ({ teamId, emails }) => assignUserAsTeamAdmin(teamId, emails),
    });

  const handleRequestData = useCallback(
    (searchPayload: FilterCollectionPayload) => {
      getWorkspaces(searchPayload);
      setPayload(searchPayload);
    },
    []
  );
  const handleSetRole = useCallback(
    (wsId: string, role: Role) => {
      setRole({
        ...roles,
        [wsId]: role,
      });
    },
    [roles]
  );

  const shouldDisableInvite = useMemo(() => {
    return (
      isGettingWorkspaces ||
      isSendingInvitations ||
      userEmails.length === 0 ||
      hasInvalidEmails ||
      isSendingInvitations
    );
  }, [
    isGettingWorkspaces,
    isSendingInvitations,
    isSendingInvitations,
    hasInvalidEmails,
    userEmails,
  ]);

  const handleSendInvitationsClick = useCallback(async () => {
    setInvited(roles);
    const wsIds = value === "AssignWorkspace" ? selection : [];

    await inviteMembers({
      emails: userEmails,
      workspaceIds: (wsIds as string[]) || [],
      roles,
    });

    if (value === "AssignAdmin") {
      await executeChangeToAdmin({
        teamId: currentTeamId || "",
        emails: userEmails,
      });
    }

    onClose();
  }, [userEmails, selection, userEmails, roles]);

  const handleInvitationModalClose = useCallback(() => {
    onClose();
    setEmails([]);
    setHasInvalidEmail(false);
    setSelection(undefined);
  }, []);

  useEffect(() => {
    if (isSendingInvitations === true || isChangeToAdminLoading === true)
      return;
    if (invitationError) {
      showError({
        message: t("TeamSettings.TeamUsers.InviteTeamMember.InviteFailed"),
      });
    } else if (invitationResponse) {
      let message = "";

      if (selection?.length === 1) {
        const wsId = selection[0];
        const wsName = data?.data.find((ws) => ws.id === wsId)?.name;
        let role = invited[wsId] === Role.ReportViewer ? "viewer" : "editor";
        const msgKey =
          userEmails.length === 1
            ? "Dashboards.InviteMemberModal.InviteSuccess"
            : "Dashboards.InviteMemberModal.InviteMultipleSuccess";

        if (userEmails.length === 1) {
          role = `${invited[wsId] === Role.ReportViewer ? "a" : "an"} ${role}`;
        }

        message = t(msgKey, {
          user: userEmails[0],
          workspace: wsName,
          userCount: userEmails.length,
          role,
        });
      } else if (selection?.length === 0 || !selection) {
        const msgKey =
          userEmails.length === 1
            ? "TeamSettings.TeamUsers.InviteTeamMember.InviteToTeamSuccess"
            : "TeamSettings.TeamUsers.InviteTeamMember.InviteToTeamMultipleSuccess";

        message = t(msgKey, {
          user: userEmails[0],
          userCount: userEmails.length,
          teamName,
        });
      } else {
        const msgKey =
          userEmails.length === 1
            ? "TeamSettings.TeamUsers.InviteTeamMember.InviteSuccess"
            : "TeamSettings.TeamUsers.InviteTeamMember.InviteSuccessMultiple";

        message = t(msgKey, {
          user: userEmails[0],
          userCount: userEmails.length,
          teamName,
          wsCount: selection?.length,
        });
      }
      showSuccess({
        message,
      });
    }
    setEmails([]);
    setHasInvalidEmail(false);
    setSelection(undefined);
    setRole({});
  }, [isSendingInvitations, isChangeToAdminLoading]);

  return (
    <Modal open={open} onClose={handleInvitationModalClose}>
      <DialogTitle sx={{ padding: 2 }}>
        <Typography variant="h5">
          {t("TeamSettings.TeamUsers.InviteTeamMember.ModalTitle", {
            team: teamName,
          })}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: 2 }}>
        <MultipleEmailInput
          values={userEmails}
          onValueChanges={setEmails}
          hasInvalidEmails={hasInvalidEmails}
          setHasInvalidEmail={setHasInvalidEmail}
          helperText={t("TeamSettings.TeamUsers.InviteTeamMember.HelperText")}
          sx={{ marginY: 2 }}
        />
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            defaultValue="AssignWorkspace"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="AssignWorkspace"
              control={<Radio />}
              label={t(
                "TeamSettings.TeamUsers.InviteTeamMember.WorkspaceAssignHeader"
              )}
            />
            <DataGrid
              columns={getColumns(
                roles,
                handleSetRole,
                !(value === "AssignWorkspace")
              )}
              data={data}
              isLoading={isGettingWorkspaces}
              payload={payload}
              hasSearchBox={false}
              hasFilterButton={false}
              checkboxSelection
              isRowSelectable={value === "AssignWorkspace"}
              rowHeight={54}
              onRequestData={handleRequestData}
              rowSelectionModel={selection}
              onRowSelectionModelChange={setSelection}
            />
            <FormControlLabel
              value="AssignAdmin"
              control={<Radio />}
              label={t(
                "TeamSettings.TeamUsers.InviteTeamMember.AssignAsTeamAdmin"
              )}
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          paddingX: 2,
          paddingY: 1,
        }}
      >
        <LoadingButton
          data-testid="InviteTeamMemberModal__inviteBtn"
          variant="contained"
          disabled={shouldDisableInvite}
          onClick={handleSendInvitationsClick}
          loading={isSendingInvitations}
        >
          {t("Invite")}
        </LoadingButton>
        <Button
          data-testid="InviteTeamMemberModal__cancelBtn"
          variant="text"
          onClick={handleInvitationModalClose}
          color="secondary"
          disabled={isSendingInvitations}
        >
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Modal>
  );
}
