import { SVGProps } from "react";

export default function CustomYAxisTick(
  props: SVGProps<SVGTextElement> & {
    value: string;
    isRoot: boolean;
    textAnchor: string;
  }
) {
  const { x, y, width, height, value, isRoot, textAnchor = "end" } = props;

  return (
    <text
      orientation="left"
      width={width}
      height={height}
      stroke="none"
      fontSize="11"
      x={x}
      y={y}
      textAnchor={textAnchor}
      fill="var(--neutral-06)"
      fontWeight={isRoot ? "700" : undefined}
    >
      {value}
    </text>
  );
}
