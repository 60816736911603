import { Box, Typography } from "@mui/material";
import { ReportView } from "common/constants";
import { useChartSettings, useDispatch, useReportSelector } from "common/store";
import AreaChart from "components/Charts/AreaChart";
import VerticalAxisTick from "components/Charts/VerticalAxisTick";
import { ChartDatum } from "components/Charts/model";
import lodash from "lodash";
import { RevenueByMonth, RevenueByMonthChartModel } from "models/report";
import { useMemo } from "react";
import { getRevenueByPeriodChart } from "services/reportService";
import { setChartShouldFetch } from "slices/reportSlice";
import { computeReportData } from "utils/chartUtils/revenueByPeriod";
import { formatCurrencyValue, getPeriodAxisLabel } from "utils/format";
import useDidUpdateEffect from "utils/hook/useDidUpdateEffect";
import { generateBarLegends } from "utils/report";

import {
  WrapperContainer,
  chartMinHeight,
} from "../components/CommonComponents";

export default function RevenueByPeriodChart() {
  const dispatch = useDispatch();

  const { reportData, reportSettings } = useReportSelector();
  const chartSettings = useChartSettings("growth-rate");
  const chartData = useReportSelector().chartData["revenue-by-period"];

  useDidUpdateEffect(() => {
    if (reportData.isLoading) return;

    if (chartSettings.segKey !== undefined) {
      dispatch(
        getRevenueByPeriodChart({
          view: chartSettings.segView,
          segmentKey: chartSettings.segKey,
        })
      );
    }
  }, [reportData.isLoading]);

  useDidUpdateEffect(() => {
    if (
      !reportData.isLoading &&
      chartData.shouldFetch &&
      chartSettings.segKey !== undefined
    ) {
      dispatch(
        getRevenueByPeriodChart({
          view: chartSettings.segView,
          segmentKey: chartSettings.segKey,
        })
      );
    }
  }, [chartData.shouldFetch]);

  useDidUpdateEffect(() => {
    dispatch(setChartShouldFetch(["revenue-by-period"]));
  }, [
    chartSettings.segKey,
    reportSettings.filters.customers,
    reportSettings.filters.segmentCustomers,
    reportSettings.filters.segmentProducts,
  ]);

  const displayableReportData = useMemo(() => {
    if (
      chartData.data === undefined ||
      !reportSettings.filters.minDate ||
      !reportSettings.filters.maxDate
    )
      return [];

    const computedData = chartData.data as RevenueByMonth[];

    const startIndex = lodash.findIndex(
      computedData,
      (x) => x.Month >= reportSettings.filters.minDate!.slice(0, -3)
    );
    const endIndex = lodash.findLastIndex(
      computedData,
      (x) => x.Month <= reportSettings.filters.maxDate!.slice(0, -3)
    );

    if (startIndex === -1 || endIndex === -1) return [];

    const filtered = computedData.slice(startIndex, endIndex + 1);

    return computeReportData(filtered, reportSettings, chartSettings.segView);
  }, [
    chartData.isLoading,
    reportSettings.measurement,
    reportSettings.filters.minDate,
    reportSettings.filters.maxDate,
  ]);

  // Report legends bars, showing all dataset values for the legends
  const areas: ChartDatum<RevenueByMonthChartModel>[] = useMemo(() => {
    let segViewName: "customer" | "product" | null = null;
    if (chartSettings.segView === ReportView.CustomerType)
      segViewName = "customer";
    if (chartSettings.segView === ReportView.ProductType)
      segViewName = "product";
    return generateBarLegends(
      segViewName,
      chartSettings.segKey,
      reportData,
      reportSettings.filters
    );
  }, [
    chartSettings.segView,
    chartSettings.segKey,
    reportData,
    reportSettings.filters,
  ]);

  return (
    <WrapperContainer
      isLoading={reportData.isLoading || chartData.isLoading}
      error={reportData.error || chartData.error}
      isNoData={displayableReportData.length === 0}
    >
      <AreaChart
        data={displayableReportData}
        xAxisKey="xValue"
        areas={areas}
        width="100%"
        height={chartMinHeight}
        margin={{ top: 0, bottom: 16, left: 16, right: 5 }}
        xAxisProps={{
          interval: 0,
          tick: ({ payload, ...remains }) => (
            <VerticalAxisTick
              value={getPeriodAxisLabel(payload.value)}
              {...remains}
            />
          ),
        }}
        yAxisProps={{
          tickFormatter: (value) => formatCurrencyValue(value),
        }}
        renderTooltip={(payload) => (
          <Box>
            <Typography>{payload.name}</Typography>
            <Typography>{payload.payload.xValue}</Typography>
            <Typography>
              {formatCurrencyValue((payload.value as number) || 0, true)}
            </Typography>
          </Box>
        )}
        hideLegend={chartSettings.segView == null}
      />
    </WrapperContainer>
  );
}
