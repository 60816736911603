import { RowType } from "components/MappingTable";
import { FileMappingObject } from "models/fileModel";
import { rowDictionary } from "containers/Workspace/config";

export interface ErrorsType {
  requiredFields: string[];
  duplicatedColumns: { [key: string]: string[] };
  emptyColumns: string[];
  nullColumns: string[];
  incorrectColumns: string[];
}

export function getErrorType(
  row: RowType,
  errors: ErrorsType,
  mappingResult: { [key: string]: string }
) {
  if (Object.keys(errors).length === 0) {
    return {
      errorCode: 0,
      data: [],
    };
  }
  if (
    errors.requiredFields?.length > 0 &&
    errors.requiredFields.includes(row.attribute)
  ) {
    return {
      errorCode: 1,
      data: [],
    };
  }
  const duplicated = Object.values(errors.duplicatedColumns || {}).find(
    (value) => {
      return value.length > 1 && value.includes(row.attribute);
    }
  );
  if (duplicated) {
    return {
      errorCode: 2,
      data: duplicated,
    };
  }
  const empty = Object.values(errors.emptyColumns || {}).find((value) => {
    return value.length > 0 && value === mappingResult[row.attribute];
  });
  if (empty) {
    return {
      errorCode: 3,
      data: [],
    };
  }

  const isNull = Object.values(errors.nullColumns || {}).find((value) => {
    return value.length > 0 && value === mappingResult[row.attribute];
  });

  if (isNull) {
    return {
      errorCode: 4,
      data: [],
    };
  }

  const isIncorrect = Object.values(errors.incorrectColumns || {}).find(
    (value) => {
      return value.length > 0 && value === mappingResult[row.attribute];
    }
  );

  if (isIncorrect) {
    return {
      errorCode: 5,
      data: [],
    };
  }

  return {
    errorCode: 0,
    data: [],
  };
}

export function buildPayload(
  mappingResult: { [key: string]: string },
  csvColumns: string[]
): FileMappingObject[] {
  return rowDictionary.map((row) => {
    if (mappingResult[row.attribute]) {
      return {
        mdtName: row.attribute,
        sourceName: mappingResult[row.attribute],
        colPosition: csvColumns.indexOf(mappingResult[row.attribute]) + 1,
      };
    }
    return {
      mdtName: row.attribute,
      sourceName: null,
      colPosition: null,
    };
  });
}

export function validateMapping(payload: FileMappingObject[]) {
  const validateResult = {
    requiredFields: [] as string[],
    duplicatedColumns: {} as { [key: string]: string[] },
    emptyColumns: [],
    nullColumns: [],
    incorrectColumns: [],
  };
  const rows = rowDictionary.map((item) => {
    return [item.attribute, { isMappedTo: [], isRequired: item.isRequired }];
  });
  const rowsMap = Object.fromEntries(rows);
  for (const col of payload) {
    // check duplicated
    if (col.sourceName) {
      if (validateResult.duplicatedColumns[col.sourceName as string]) {
        validateResult.duplicatedColumns[col.sourceName as string].push(
          col.mdtName
        );
      } else {
        validateResult.duplicatedColumns[col.sourceName as string] = [
          col.mdtName,
        ];
      }
    }
    // check required is mapped
    if (rowsMap[col.mdtName].isRequired && !col.sourceName) {
      validateResult.requiredFields.push(col.mdtName);
    }
  }
  const duplicated = Object.values(validateResult.duplicatedColumns).some(
    (value) => value.length > 1
  );
  if (!duplicated) {
    validateResult.duplicatedColumns = {};
  }
  return validateResult;
}
