import Import from "./components/Import";
import { ImportDataContextContextProvider } from "./ImportDataContext";
import ImportLayout from "./components/ImportLayout";

export default function ImportData() {
  return (
    <ImportDataContextContextProvider>
      <ImportLayout>
        <Import />
      </ImportLayout>
    </ImportDataContextContextProvider>
  );
}
