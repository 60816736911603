import React from "react";
import { CheckCircleOutline } from "@mui/icons-material";
import {
  Container,
  CardMedia,
  CardHeader,
  CardContent,
  CardActions,
  Card,
  Typography,
  Button,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { WORKSPACE_ROUTES } from "containers/Workspace/config";
import { ROUTE_PATHS } from "common/constants";

import ImportLayout from "./ImportLayout";

export function ImportSuccess() {
  const { t } = useTranslation();
  const params = useParams();
  const { dashboardId } = useParams();
  const navigate = useNavigate();
  return (
    <Container
      sx={{
        margin: "auto",
        height: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "24px",
          width: "732px",
          boxShadow: "none",
        }}
      >
        <CardMedia>
          <CheckCircleOutline
            sx={{ width: "120px", height: "120px" }}
            color="success"
          />
        </CardMedia>
        <CardHeader
          title={
            <Typography variant="h3">
              {t("workspace.import.success")}
            </Typography>
          }
        />
        <CardContent sx={{ padding: "16px" }}>
          <Typography variant="h4" align="center">
            {t("workspace.import.successSubtitle")}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            sx={{ borderRadius: "120px" }}
            onClick={() =>
              dashboardId && navigate(`../${WORKSPACE_ROUTES.CUSTOMER_DATA}`)
            }
          >
            {t("workspace.import.successButton")}
          </Button>
          <Button
            variant="text"
            sx={{ borderRadius: "120px" }}
            onClick={() => {
              navigate(
                `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}`
              );
            }}
          >
            {t("workspace.import.successSkip")}
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
}

export default function ImportDataSuccess() {
  return (
    <ImportLayout>
      <ImportSuccess />
    </ImportLayout>
  );
}
