import {
  Card,
  CardHeader,
  Avatar,
  Divider,
  CardContent,
  ListItemButton,
  ListItemIcon,
  List,
  ListSubheader,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Person4Outlined,
  PeopleOutlined,
  FilterCenterFocusOutlined,
  FilterAltOutlined,
} from "@mui/icons-material";

export default function SidePanel() {
  const { t } = useTranslation();

  return (
    <Card sx={{ maxWidth: 354, marginRight: 3 }}>
      <CardHeader
        title={t("SuperAdminTitle")}
        subheader={t("SuperAdminSubheader")}
        avatar={<Avatar variant="rounded" />}
      />
      <Divider />
      <CardContent>
        <ListItemButton>
          <ListItemIcon>
            <Person4Outlined />
          </ListItemIcon>
          <ListItemText primary={t("ProfileSettings")} />
        </ListItemButton>
        <List subheader={<ListSubheader>{t("UserManagement")}</ListSubheader>}>
          <ListItemButton selected>
            <ListItemIcon>
              <PeopleOutlined />
            </ListItemIcon>
            <ListItemText primary={t("SuperAdminUsers")} />
          </ListItemButton>
        </List>
        <List
          subheader={<ListSubheader>{t("PlatformManagement")}</ListSubheader>}
        >
          <ListItemButton>
            <ListItemIcon>
              <FilterCenterFocusOutlined />
            </ListItemIcon>
            <ListItemText primary={t("GlobalParameters")} />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <FilterAltOutlined />
            </ListItemIcon>
            <ListItemText primary={t("GlobalFilters")} />
          </ListItemButton>
        </List>
      </CardContent>
    </Card>
  );
}
