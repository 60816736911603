import {
  FormControl,
  MenuItem,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { ReportMeasurement, RevenueType } from "common/constants";
import { useChartSettings, useDispatch, useReportSelector } from "common/store";
import GradientCard from "components/Card/GradientCard";
import CohortHeatMap from "components/CohortHeatMap";
import lodashSortBy from "lodash/sortBy";
import {
  ARRCohortReport,
  Axis,
  ByValue,
  CARRCohortReport,
  CohortReport,
  Measurement,
  Show,
} from "models/report";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCustomerCohortChart } from "services/reportService";
import { setChartSettings, setChartShouldFetch } from "slices/reportSlice";
import useDidUpdateEffect from "utils/hook/useDidUpdateEffect";
import { shouldRefetchRevenueData } from "utils/report";

import { WrapperContainer } from "../components/CommonComponents";

const measurementMapping = {
  [ReportMeasurement.ARR]: "arr",
  [ReportMeasurement.MRR]: "mrr",
  [ReportMeasurement.CARR]: "carr",
  [ReportMeasurement.CMRR]: "cmrr",
};
const measurementLabelMapping = {
  [ReportMeasurement.ARR]: "Common.ARR",
  [ReportMeasurement.MRR]: "Common.MRR",
  [ReportMeasurement.CARR]: "Common.CARR",
  [ReportMeasurement.CMRR]: "Common.CMRR",
};

export default function SectionCohorts() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { reportData, reportSettings } = useReportSelector();
  const chartSettings = useChartSettings("customer-cohorts");
  const chartData = useReportSelector().chartData["customer-cohorts"];

  const currentMeasurement: Measurement = useMemo(() => {
    return measurementMapping[reportSettings.measurement] as Measurement;
  }, [reportSettings.measurement]);

  const [prevMeasurement, setPrevMeasurement] = useState<ReportMeasurement>(
    ReportMeasurement.ARR
  );

  const titleKey = useMemo(() => {
    if (chartSettings.axis === "cohort-tenure")
      return "Dashboard.CohortsCard.ByTenureTitle";
    if (chartSettings.axis === "calendar-period")
      return "Dashboard.CohortsCard.ByCalendarTitle";
    return "";
  }, [chartSettings.axis]);

  const currentShowValue = useMemo(() => {
    return chartSettings.show !== "count" && chartSettings.show !== "volume"
      ? "measure"
      : chartSettings.show;
  }, [chartSettings.show]);

  const filteredData = useMemo(() => {
    if (
      chartData.data === undefined ||
      !reportSettings.filters.minDate ||
      !reportSettings.filters.maxDate
    )
      return [];

    const startMonth = reportSettings.filters.minDate!.slice(0, -3);
    const endMonth = reportSettings.filters.maxDate!.slice(0, -3);

    const filtered = chartData.data.filter((item: CohortReport) => {
      if (item.Month < startMonth || item.Month > endMonth) return false;

      let arrStartDate =
        chartSettings.show === "arr" || chartSettings.show === "mrr"
          ? (item as ARRCohortReport).ARRSTARTDATE
          : (item as CARRCohortReport).CARRSTARTDATE;
      arrStartDate = arrStartDate && arrStartDate.slice(0, 7); // Get month part of the date: YYYY-MM

      if (arrStartDate < startMonth || arrStartDate > endMonth) {
        return false;
      }
      return true;
    });

    return lodashSortBy(filtered, (item) => item.Month);
  }, [
    chartData.isLoading,
    reportSettings.measurement,
    reportSettings.filters.minDate,
    reportSettings.filters.maxDate,
  ]);

  function handleOnPeriodViewChanged(value: RevenueType) {
    if (value !== chartSettings.period) {
      dispatch(setChartSettings({ "customer-cohorts": { period: value } }));
    }
  }

  function handleOnAxisChanged(value: Axis) {
    if (value !== chartSettings.axis) {
      dispatch(setChartSettings({ "customer-cohorts": { axis: value } }));
    }
  }

  function handleOnShowChanged(value: Show) {
    if (value !== chartSettings.show) {
      dispatch(setChartSettings({ "customer-cohorts": { show: value } }));
      dispatch(setChartShouldFetch(["customer-cohorts"]));
    }
  }

  function handleOnByValueChanged(value: ByValue) {
    if (value !== chartSettings.byValue) {
      dispatch(setChartSettings({ "customer-cohorts": { byValue: value } }));
    }
  }

  useDidUpdateEffect(() => {
    if (
      !reportData.isLoading &&
      chartData.shouldFetch &&
      chartSettings.show !== undefined
    ) {
      dispatch(getCustomerCohortChart());
    }
  }, [reportData.isLoading, chartData.shouldFetch]);

  useDidUpdateEffect(() => {
    if (
      !reportData.file?.id ||
      !shouldRefetchRevenueData(reportSettings.measurement, prevMeasurement)
    ) {
      setPrevMeasurement(reportSettings.measurement);
      return;
    }
    dispatch(setChartShouldFetch(["customer-cohorts"]));
    setPrevMeasurement(reportSettings.measurement);
  }, [
    chartSettings.show,
    reportSettings.filters.customers,
    reportSettings.filters.segmentCustomers,
    reportSettings.filters.segmentProducts,
  ]);

  useDidUpdateEffect(() => {
    if (chartSettings.show !== "count" && chartSettings.show !== "volume") {
      handleOnShowChanged(currentMeasurement);
    }
  }, [currentMeasurement]);

  return (
    <>
      <Typography variant="h6" marginBottom={2} color="var(--text-secondary)">
        {t("Dashboard.Charts.SectionCohorts")}
      </Typography>
      <GradientCard
        title={
          <Stack direction="row" alignItems="center">
            <Typography variant="h5" sx={{ marginLeft: 1, marginRight: 2 }}>
              {t(titleKey)}:
            </Typography>
            <ToggleButtonGroup
              exclusive
              value={chartSettings.period}
              onChange={(_, value) => handleOnPeriodViewChanged(value)}
            >
              <ToggleButton value={RevenueType.Monthly}>
                {t("Common.Month")}
              </ToggleButton>
              <ToggleButton value={RevenueType.Quarterly}>
                {t("Common.Quarter")}
              </ToggleButton>
              <ToggleButton value={RevenueType.Yearly}>
                {t("Common.Year")}
              </ToggleButton>
            </ToggleButtonGroup>
            <Typography variant="h5" sx={{ marginX: 2 }}>
              {t("Common.Axis")}:
            </Typography>
            <ToggleButtonGroup
              exclusive
              value={chartSettings.axis}
              onChange={(_, value) => handleOnAxisChanged(value)}
            >
              <ToggleButton value="cohort-tenure">
                {t("Dashboard.CohortsCard.AxisTenure")}
              </ToggleButton>
              <ToggleButton value="calendar-period">
                {t("Dashboard.CohortsCard.AxisCalendar")}
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        }
      >
        <Stack direction="column">
          <Stack direction="row" alignItems="center" marginBottom={4}>
            <Typography variant="h6" sx={{ marginLeft: 1, marginRight: 2 }}>
              {t("Dashboard.CohortsCard.LabelShow")}:
            </Typography>
            <ToggleButtonGroup
              exclusive
              value={chartSettings.show}
              onChange={(_, value) => handleOnShowChanged(value)}
            >
              <ToggleButton value={currentMeasurement}>
                {t(measurementLabelMapping[reportSettings.measurement])}
              </ToggleButton>
              <ToggleButton value="count">
                {t("Dashboard.CohortsCard.LabelCustomerCount")}
              </ToggleButton>
              <ToggleButton value="volume" disabled>
                {t("Common.Volume")}
              </ToggleButton>
            </ToggleButtonGroup>
            <Typography variant="h6" sx={{ marginX: 2 }}>
              {t("Dashboard.CohortsCard.LabelBy")}:
            </Typography>
            <FormControl>
              <Select
                size="small"
                sx={{ width: "220px" }}
                value={chartSettings.byValue}
                onChange={(e) => handleOnByValueChanged(e.target.value)}
              >
                <MenuItem value="base">
                  {t("Dashboard.CohortsCard.BySelect.Base")}
                </MenuItem>
                <MenuItem value="churn-only" disabled>
                  {t("Dashboard.CohortsCard.BySelect.ChurnOnly")}
                </MenuItem>
                <MenuItem value="gross" disabled>
                  {t("Dashboard.CohortsCard.BySelect.GrossRetention")}
                </MenuItem>
                <MenuItem value="net" disabled>
                  {t("Dashboard.CohortsCard.BySelect.NetRetention")}
                </MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <WrapperContainer
            isLoading={reportData.isLoading || chartData.isLoading}
            error={reportData.error || chartData.error}
            isNoData={filteredData.length === 0}
            minHeight={0}
          >
            <CohortHeatMap
              data={filteredData}
              axis={chartSettings.axis}
              revenueType={chartSettings.period}
              measurement={reportSettings.measurement}
              show={currentShowValue}
            />
          </WrapperContainer>
        </Stack>
      </GradientCard>
    </>
  );
}
