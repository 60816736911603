export const MAX_HEADER_LENGTH = 10 * 1024;
export function parseCSVHeader(file: File) {
  const reader = file.stream().getReader();
  const decoder = new TextDecoder("utf8");
  let csvContent = "";

  return reader
    .read()
    .then(function processText({ done, value }): Promise<string> {
      if (
        done ||
        csvContent.includes("\n") ||
        csvContent.length > MAX_HEADER_LENGTH
      ) {
        return reader
          .read()
          .then(() => csvContent.split("\n")[0]?.replaceAll("\r", ""));
      }

      // Decode the chunk and append to csvContent
      csvContent += decoder.decode(value, { stream: true });
      return reader.read().then(processText);
    });
}
