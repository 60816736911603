import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UploadState {
  isUploading: boolean;
  uploadProgress: number;
  fileId: string;
}

export const initialState: UploadState = {
  isUploading: false,
  uploadProgress: 0,
  fileId: "",
};

const slice = createSlice({
  name: "fileUpload",
  initialState,
  reducers: {
    setIsUploading: (state, action: PayloadAction<boolean>) => {
      state.isUploading = action.payload;
    },
    setUploadProgress: (state, action: PayloadAction<number>) => {
      state.uploadProgress = action.payload;
    },
    setFileId: (state, action: PayloadAction<string>) => {
      state.fileId = action.payload;
    },
  },
});

export const { setIsUploading, setUploadProgress, setFileId } = slice.actions;
export default slice.reducer;
