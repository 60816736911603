import Table from "components/Table";
import {
  getCustomersByChangeCategoryTableColumns,
  getCustomersByChangeCategoryTableData,
} from "utils/chartUtils/customersByChangeCategory";
import { CustomerByChangeCategoryChartModel } from "models/report";
import { useMemo } from "react";

interface Props {
  data: CustomerByChangeCategoryChartModel[];
}

export default function CountChangesTable(props: Props) {
  const columns = useMemo(() => {
    return getCustomersByChangeCategoryTableColumns(props.data);
  }, [props.data]);

  const tableData = useMemo(() => {
    return getCustomersByChangeCategoryTableData(props.data);
  }, [props.data]);

  return (
    <Table
      data={tableData}
      columns={columns}
      rowKey="category"
      bodySx={{ zIndex: 0, position: "relative" }}
      footerSx={{
        bottom: 0,
        position: "sticky",
        backgroundColor: "white",
      }}
      stickyHeader
    />
  );
}
