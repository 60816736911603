import {
  AddOutlined,
  ArchiveOutlined,
  DeleteOutline,
  EditOutlined,
  LockOutlined,
  MoreHorizOutlined,
  OpenInNewOutlined,
} from "@mui/icons-material";
import i18n from "common/i18n";
import { useSelector } from "common/store";
import IconMenu, { IconMenuItemProps } from "components/Menu/IconMenu";
import { Role } from "models/team";
import { selectRoleByTeam } from "slices/teamSlice";

const options: IconMenuItemProps[] = [
  {
    id: "add-member",
    icon: <AddOutlined />,
    title: i18n.t("Workspace.Actions.AddMember.Action"),
  },
  { id: "divider-1" },
  {
    id: "rename",
    icon: <EditOutlined />,
    title: i18n.t("Workspace.Actions.Rename.Action"),
  },
  {
    id: "open-in-new",
    icon: <OpenInNewOutlined />,
    title: i18n.t("Workspace.Actions.OpenInNewTab.Action"),
    disabled: true,
  },
  { id: "divider-2" },
  {
    id: "lock-edit",
    icon: <LockOutlined />,
    title: i18n.t("Workspace.Actions.LockEditing.Action"),
    disabled: true,
  },
  {
    id: "archive",
    icon: <ArchiveOutlined />,
    title: i18n.t("Workspace.Actions.Archive.Action"),
  },
  {
    id: "delete",
    icon: <DeleteOutline />,
    title: i18n.t("Workspace.Actions.Delete.Action"),
    color: "error.main",
  },
];

export default function ContextMenu({
  onOptionSelect,
}: {
  onOptionSelect?: (optionId: string) => void;
}) {
  const role = useSelector(selectRoleByTeam);
  const { isSystemAdmin } = useSelector((state) => state.team);
  const optionsByRole =
    isSystemAdmin || role === Role.Admin
      ? options
      : options.filter(
          (option) =>
            ![
              "delete",
              "archive",
              "rename",
              "add-member",
              "divider-1",
            ].includes(option.id)
        );
  return (
    <IconMenu
      icon={<MoreHorizOutlined />}
      options={optionsByRole}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      onOptionSelect={onOptionSelect}
    />
  );
}
