import {
  ArchiveOutlined,
  DeleteOutline,
  EditOffOutlined,
  EditOutlined,
  LinkOutlined,
  LockOutlined,
  MoreHorizOutlined,
  OpenInNewOutlined,
} from "@mui/icons-material";
import i18n from "common/i18n";
import { useSelector } from "common/store";
import IconMenu, { IconMenuItemProps } from "components/Menu/IconMenu";

const options: IconMenuItemProps[] = [
  {
    id: "copy-link",
    icon: <LinkOutlined />,
    title: i18n.t("Teams.DataGrid.ActionMenu.CopyTeamLink"),
  },
  { id: "divider-1" },
  {
    id: "rename",
    icon: <EditOutlined />,
    title: i18n.t("Teams.DataGrid.ActionMenu.Rename"),
  },
  {
    id: "open-in-new",
    icon: <OpenInNewOutlined />,
    title: i18n.t("Teams.DataGrid.ActionMenu.OpenInNewTab"),
  },
  { id: "divider-2" },
  {
    id: "suspend-edit",
    icon: <EditOffOutlined />,
    title: i18n.t("Teams.DataGrid.ActionMenu.SuspendEdit"),
  },
  {
    id: "suspend-access",
    icon: <LockOutlined />,
    title: i18n.t("Teams.DataGrid.ActionMenu.SuspendAccess"),
  },
  {
    id: "archive",
    icon: <ArchiveOutlined />,
    title: i18n.t("Teams.DataGrid.ActionMenu.Archive"),
  },
  {
    id: "delete",
    icon: <DeleteOutline />,
    title: i18n.t("Teams.DataGrid.ActionMenu.Delete"),
    color: "error.main",
  },
];

export default function ContextMenu({
  onOptionSelect,
}: {
  onOptionSelect?: (optionId: string) => void;
}) {
  const isSystemAdmin = useSelector((state) => state.team.isSystemAdmin);
  const optionsByRole = isSystemAdmin
    ? options
    : options.filter(
        (option) => !["delete", "archive", "rename"].includes(option.id)
      );
  return (
    <IconMenu
      icon={<MoreHorizOutlined />}
      options={optionsByRole}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      onOptionSelect={onOptionSelect}
    />
  );
}
