import { Box, Typography } from "@mui/material";
import { useReportSelector } from "common/store";
import LineBarComposedChart from "components/Charts/LineBarComposedChart";
import VerticalTick from "components/Charts/VerticalAxisTick";
import { RevenueByChangeCategoryChartModel } from "models/report";
import { formatCurrencyValue, getPeriodAxisLabel } from "utils/format";

import CustomYAxisTick from "../CustomYAxisTick";

import { getBars } from "./utils";

interface Props {
  height: string | number;
  data: RevenueByChangeCategoryChartModel[];
  selectedMonth?: string;
  selectedCategory?: string;
  onSelectMonth: (value?: string) => void;
  onSelectCategory: (value?: string) => void;
  onDrilldownExit: () => void;
}

export default function RollForwardChart({
  data,
  height,
  selectedMonth,
  selectedCategory,
  onSelectMonth,
  onSelectCategory,
}: Props) {
  const { reportSettings } = useReportSelector();

  return (
    <LineBarComposedChart
      xAxisKey="xValue"
      bars={getBars(reportSettings.params.customerLevel)}
      line={{ key: "Ending Balance" }}
      data={data}
      height={height}
      width="100%"
      xAxisProps={{
        interval: 0,
        tick: ({ payload, ...remains }) => (
          <VerticalTick
            value={getPeriodAxisLabel(payload.value)}
            {...remains}
          />
        ),
      }}
      yAxisProps={{
        tick: ({ payload, ...remains }) => (
          <CustomYAxisTick
            value={formatCurrencyValue(payload.value)}
            isRoot={payload.value === 0}
            textAnchor="start"
            {...remains}
          />
        ),
      }}
      margin={{ top: 0, left: 24, right: 24, bottom: 16 }}
      renderTooltip={(payload) => (
        <Box>
          <Typography>{payload.name}</Typography>
          <Typography>{payload.payload.xValue}</Typography>
          <Typography>
            {formatCurrencyValue((payload.value as number) || 0, true)}
          </Typography>
        </Box>
      )}
      selectedXValue={selectedMonth}
      onSelectXValue={onSelectMonth}
      selectedCategory={selectedCategory}
      onSelectCategory={onSelectCategory}
    />
  );
}
