import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  FormGroup,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";
import { teamManagementClient } from "common/client/teamManagementClient";
import { ROUTE_PATHS } from "common/constants";
import { useSelector } from "common/store";
import { Role } from "models/team";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { redirect, useFetcher, useNavigate, useParams } from "react-router-dom";
import { selectRoleByTeam } from "slices/teamSlice";
import extractIdFromPathname from "utils/extractIdFromPathname";
import LoadingButton from "components/Button/LoadingButton";

import ImportLayout from "../Import/components/ImportLayout";
import { WORKSPACE_ROUTES } from "../config";

export default function NewWorkspace() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const { t } = useTranslation();
  const role = useSelector(selectRoleByTeam);
  const params = useParams();

  useEffect(() => {
    if (fetcher.data) {
      enqueueSnackbar(fetcher.data.error.errorMessage, {
        variant: "error",
      });
    }
  }, [fetcher.data?.error]);
  const [name, setName] = useState("");
  return (
    <ImportLayout>
      <Container>
        <fetcher.Form
          action={`/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/:wsId/new`}
          method="post"
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <Card
            sx={{
              border: "none",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            elevation={0}
          >
            <CardHeader
              title={
                <Typography variant="h3">
                  {t("CreateWorkspace.Header")}
                </Typography>
              }
            />
            <CardContent>
              <FormGroup>
                <FormLabel
                  htmlFor="workspace-name"
                  sx={{
                    marginBottom: "24px",
                  }}
                >
                  {t("CreateWorkspace.Body")}
                </FormLabel>
                <TextField
                  onChange={(e) => setName(e.target.value)}
                  id="workspace-name"
                  name="workspaceName"
                  required
                  variant="outlined"
                  label={t("CreateWorkspace.InputLabel")}
                  placeholder={t("CreateWorkspace.Placeholder")}
                  disabled={fetcher.state === "submitting"}
                  sx={{ minWidth: "400px" }}
                />
              </FormGroup>
            </CardContent>
            <CardActions>
              <LoadingButton
                variant="contained"
                type="submit"
                disabled={!name}
                loading={fetcher.state !== "idle"}
              >
                {fetcher.state === "submitting"
                  ? t("Common.Submitting")
                  : t("Common.Continue")}
              </LoadingButton>
              <Button
                disabled={fetcher.state === "submitting"}
                onClick={() => {
                  navigate(
                    `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}`
                  );
                }}
              >
                {t("Cancel")}
              </Button>
            </CardActions>
          </Card>
          <input type="hidden" name="role" value={role} />
        </fetcher.Form>
      </Container>
    </ImportLayout>
  );
}

export async function actionHandlers({ request }: { request: Request }) {
  switch (request.method) {
    case "POST": {
      const formData = await request.formData();
      const role = formData.get("role") as string;
      try {
        const data = await teamManagementClient.post("/workspaces", {
          name: formData.get("workspaceName") as string,
        });
        if (!role || +role !== Role.Admin) {
          return redirect(
            `/${ROUTE_PATHS.TEAMS}/${extractIdFromPathname(
              ROUTE_PATHS.TEAMS
            )}/${ROUTE_PATHS.WORKSPACES}`
          );
        }
        return redirect(
          `/${ROUTE_PATHS.TEAMS}/${extractIdFromPathname(ROUTE_PATHS.TEAMS)}/${
            ROUTE_PATHS.WORKSPACES
          }/${data.data.data.id}/${WORKSPACE_ROUTES.NEW_CONFIRM}`
        );
      } catch (error) {
        return {
          error,
        };
      }
    }
    default: {
      return {
        status: 405,
      };
    }
  }
}
