import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { RowType } from "components/MappingTable";
import { useMatches } from "react-router-dom";
import { dashboardManagerClient } from "common/client/dashboardManagerClient";
import { FileModel } from "models/fileModel";
import useSocket from "utils/hook/useSocket";
import { FileStatus } from "common/constants";

import { RemapModal } from "./components/RemapModal";

export function loader({
  params,
}: {
  params: { wsId: string | undefined; dashboardId: string | undefined };
}) {
  return dashboardManagerClient.get(
    `dashboard/workspaces/${params.wsId}/dashboards/${params.dashboardId}`
  );
}

export type RemappingInfo = {
  row: RowType;
  fieldFromFile: string;
  currentMappedTo: string;
};
export type ContextType = {
  fileStatus: FileStatus;
  fields: string[];
  errorSummary: ErrorSummary | null;
  addableFields: { [key: string]: RowType[] };
  setAddableFields: React.Dispatch<
    React.SetStateAction<{
      [key: string]: RowType[];
    }>
  >;
  doMapField: (attr: string, val: string) => void;
  mappingResult: { [key: string]: string };
  confirms: { [key: string]: string };
  remappingInfo: RemappingInfo;
  setRemappingInfo: React.Dispatch<React.SetStateAction<RemappingInfo>>;
  setConfirmQuestion: (path: string, value: string) => void;
  setOpenModal: (
    open: boolean,
    payload: RemappingInfo,
    closeSelect: () => void
  ) => void;
  closeSelectFunc: () => void;
  setFileStatus: React.Dispatch<React.SetStateAction<FileStatus>>;
};

type ErrorSummary = {
  emptyColumns: string[];
  nullColumns: string[];
  incorrectColumns: string[];
};

const MappingContext = createContext<ContextType>({
  fileStatus: FileStatus.Init,
  fields: [],
  errorSummary: null,
  addableFields: {},
  setAddableFields: () => {},
  doMapField: () => {},
  mappingResult: {},
  remappingInfo: {
    row: { attribute: "attr", example: "example", dataType: "dataType" },
    currentMappedTo: "to",
    fieldFromFile: "file",
  },
  confirms: {},
  setConfirmQuestion: () => {},
  setOpenModal: () => {},
  setRemappingInfo: () => {
    return {
      row: { attribute: "attr", example: "example", dataType: "dataType" },
      currentMappedTo: "to",
      fieldFromFile: "file",
    };
  },
  closeSelectFunc: () => {},
  setFileStatus: () => {},
});

export function DataMappingContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [addableFields, setAddableFields] = useState<{
    [key: string]: RowType[];
  }>({});
  const [mappingResult, setMappingResult] = useState<{ [key: string]: string }>(
    {}
  );
  const [remappingInfo, setRemappingInfo] = useState<RemappingInfo>({
    row: {
      isRequiredIndex: true,
      isRequired: true,
      attribute: "Customer",
      attributeLabel: "workspace.dataMapping.fields.customer",
      example: "12345678, McDonald’s",
      dataType: "workspace.dataMapping.dataType.string",
    },
    currentMappedTo: "Customer",
    fieldFromFile: "Customer",
  });
  const [confirms, setConfirms] = useState<{ [key: string]: string }>({});
  const [fields, setFields] = useState<string[]>(["Loading..."]);
  const [errorSummary, setErrorSummary] = useState<ErrorSummary | null>(null);
  const [fileStatus, setFileStatus] = useState<FileStatus>(FileStatus.Init);
  const [openModal, setOpenModalState] = useState(false);
  const closeSelectFunc = useRef(() => {});
  const context: ContextType = {
    fileStatus,
    fields,
    errorSummary,
    addableFields,
    setAddableFields,
    mappingResult,
    confirms,
    remappingInfo,
    setConfirmQuestion: (path: string, value: string) => {
      setConfirms((prev) => ({ ...prev, [path]: value }));
    },
    doMapField: (attr: string, val: string) => {
      setMappingResult((prev) => ({ ...prev, [attr]: val }));
    },
    setOpenModal: (
      open: boolean,
      payload: RemappingInfo,
      closeSelect: () => void
    ) => {
      setOpenModalState(open);
      setRemappingInfo(payload);
      closeSelectFunc.current = closeSelect;
    },
    setRemappingInfo,
    closeSelectFunc: closeSelectFunc.current,
    setFileStatus,
  };
  const match = useMatches();

  async function loadFileMetas() {
    const response = await loader({
      params: {
        wsId: match[1].params.wsId,
        dashboardId: match[1].params.dashboardId,
      },
    });
    setFields(response.data.data.metadata.columnHeaders);
    setErrorSummary(response.data.data.errorSummary);
    if (response.data.data.mapping) {
      const mappingObj = Object.fromEntries(
        (
          response.data.data.mapping as {
            mdtName: string;
            sourceName: string;
          }[]
        )
          .filter((item) => item.sourceName)
          .map((item) => [item.mdtName, item.sourceName])
      );
      setMappingResult(mappingObj);
    }
    setFileStatus(response.data.data.status);
  }

  useEffect(() => {
    if (match[1].params.dashboardId) {
      loadFileMetas();
    }
  }, [match[1].params.dashboardId]);

  useSocket<FileModel>((payload) => {
    if (match[1].params.dashboardId === payload.data.dashboardId) {
      setFileStatus(payload.data.status);
      setErrorSummary(payload.data.errorSummary);
    }
  });

  return (
    <MappingContext.Provider
      value={{
        ...context,
      }}
    >
      <RemapModal setOpenModal={setOpenModalState} open={openModal} />
      {children}
    </MappingContext.Provider>
  );
}

export function useDataMappingCtx() {
  return useContext(MappingContext);
}
