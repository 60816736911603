import { DependencyList, EffectCallback, useEffect, useRef } from "react";

export default function useDidUpdateEffect(
  effect: EffectCallback,
  deps?: DependencyList
) {
  const isMountingRef = useRef(false);

  useEffect(() => {
    isMountingRef.current = true;
  }, []);

  useEffect(() => {
    if (isMountingRef.current) {
      isMountingRef.current = false;
    } else {
      return effect();
    }
  }, deps);
}
