import moment from "moment";

export enum Role {
  Admin,
  User,
  SystemAdmin,
  ReportViewer,
  ReportEditor,
}

export interface TeamModel {
  id?: string;
  name?: string;
  orgName: string;
  admins: string[];
  createdAt: string;
  updatedAt: string;
  role?: Role;
}

export const mockData: TeamModel[] = [
  {
    id: "f184f4d2-f581-41cb-85e8-55796e1032ac",
    name: "Trunyx",
    orgName: "Hibbett Sports, Inc.",
    admins: ["Daisy Stainbridge"],
    createdAt: "9/2/2024",
    updatedAt: "9/2/2024",
    role: Role.SystemAdmin,
  },
  {
    id: "1371a763-d067-4fad-899a-74f8ce53b447",
    name: "Devify",
    orgName: "MyoKardia, Inc.",
    admins: ["Nell Lambourne", "Myrna Hanster", "Templeton Pundy"],
    createdAt: "1/25/2024",
    updatedAt: "3/11/2024",
    role: Role.SystemAdmin,
  },
  {
    id: "d8ede522-457f-4179-b9e7-f884eeb697c1",
    name: "Vitz",
    orgName: "Bank of America Corporation",
    admins: ["Casey Kalinovich", "Suzie Yearsley"],
    createdAt: moment(new Date()).subtract(3, "d").toDate().toString(),
    updatedAt: "9/1/2024",
    role: Role.SystemAdmin,
  },
  {
    id: "9963438b-015c-4a97-a67e-8bb08c30619b",
    name: "Mudo",
    orgName: "Spirit Aerosystems Holdings, Inc.",
    admins: ["Louisa Mangham"],
    createdAt: moment(new Date()).subtract(3, "h").toDate().toString(),
    updatedAt: "8/5/2024",
    role: Role.SystemAdmin,
  },
  {
    id: "cab480ff-91c5-4a49-8be0-2ded1a9ac483",
    name: "Mita",
    orgName: "Neenah Paper, Inc.",
    admins: ["Skippy Augur"],
    createdAt: moment(new Date()).subtract(30, "m").toDate().toString(),
    updatedAt: "8/9/2024",
    role: Role.SystemAdmin,
  },
  {
    id: "5d87ee50-3bce-4de3-a792-f41331108cb7",
    name: "Photofeed",
    orgName: "Lantronix, Inc.",
    admins: ["Lavinia Maleby"],
    createdAt: new Date().toString(),
    updatedAt: "8/17/2024",
    role: Role.SystemAdmin,
  },
  {
    id: "673fd97f-cd0c-4a42-a030-bbd0282cb2be",
    name: "Quinu",
    orgName: "Orion Energy Systems, Inc.",
    admins: ["Clayborn Witz"],
    createdAt: "9/3/2024",
    updatedAt: "8/28/2024",
    role: Role.SystemAdmin,
  },
];
