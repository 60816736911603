import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LoadingProvider } from "components/Loading/LoadingContext";
import { AbortControllerProvider } from "common/AbortControllerContext";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { theme } from "theme";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

import App from "./App";
import "./assets/styles/main.css";
import "./common/i18n";
import store from "./common/store";
import * as serviceWorker from "./serviceWorker";

TimeAgo.addDefaultLocale(en);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <LoadingProvider>
          <AbortControllerProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline>
                <App />
              </CssBaseline>
            </ThemeProvider>
          </AbortControllerProvider>
        </LoadingProvider>
      </LocalizationProvider>
    </Provider>
  </React.StrictMode>,
  document.querySelector("#root")
);
serviceWorker.unregister();
