import LineBarComposedChart from "components/Charts/LineBarComposedChart";
import { ChartDatum } from "components/Charts/model";
import { CustomerByChangeCategoryChartModel } from "models/report";
import { getCustomerCountValue, getPeriodAxisLabel } from "utils/format";
import { Typography, Box } from "@mui/material";
import VerticalAxisTick from "components/Charts/VerticalAxisTick";

import CustomYAxisTick from "./CustomYAxisTick";

const bars: ChartDatum<CustomerByChangeCategoryChartModel>[] = [
  {
    key: "Beginning Count",
    color: "var(--color-positive-02)",
  },
  {
    key: "New Customer",
    color: "var(--color-positive-09)",
  },
  {
    key: "Customer Reactivation",
    color: "var(--indigo-02)",
  },
  {
    key: "Lost Customer",
    color: "var(--color-negative-05)",
  },
];

interface Props {
  height: string | number;
  data: CustomerByChangeCategoryChartModel[];
}

export default function CountChangesChart(props: Props) {
  return (
    <LineBarComposedChart
      xAxisKey="xValue"
      bars={bars}
      line={{ key: "Ending Count" }}
      data={props.data}
      height={props.height}
      width="100%"
      xAxisProps={{
        interval: 0,
        tick: ({ payload, ...remains }) => (
          <VerticalAxisTick
            value={getPeriodAxisLabel(payload.value)}
            {...remains}
          />
        ),
      }}
      yAxisProps={{
        tick: ({ payload, ...remains }) => (
          <CustomYAxisTick
            value={getCustomerCountValue(payload.value)}
            isRoot={payload.value === 0}
            {...remains}
          />
        ),
      }}
      margin={{ top: 0, left: 24, right: 24, bottom: 16 }}
      renderTooltip={(payload) => (
        <Box>
          <Typography>{payload.name}</Typography>
          <Typography>{payload.payload.xValue}</Typography>
          <Typography>
            {getCustomerCountValue((payload.value as number) || 0, true)}{" "}
            customer(s)
          </Typography>
        </Box>
      )}
    />
  );
}
