import {
  Paper,
  Card,
  CardHeader,
  Link,
  Stack,
  Typography,
  CardContent,
  Button,
} from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import { WorkspaceModel } from "models/workspace";
import { Link as RouterLink } from "react-router-dom";
import { ROUTE_PATHS } from "common/constants";
import { getRelativeTimeWithPreposition } from "utils/getRelativeTime";
import { Role } from "models/team";
import Logo from "components/Logo";
import { useSelector } from "common/store";
import { useTranslation } from "react-i18next";
import { selectRoleByTeam } from "slices/teamSlice";

import ContextMenu from "./ContextMenu";
import { ReportStatus } from "./ReportStatus";

interface WorkspaceCardProps {
  ws: WorkspaceModel;
  onOptionSelect: (optionId: string, ws: WorkspaceModel) => void;
}

export default function WorkspaceCard({
  ws,
  onOptionSelect,
}: WorkspaceCardProps) {
  const { t } = useTranslation();

  const role = useSelector(selectRoleByTeam);
  const { isSystemAdmin } = useSelector((state) => state.team);

  return (
    <Paper
      key={ws.id}
      sx={{
        marginTop: "24px",
        borderRadius: "10px",
      }}
      elevation={0}
      variant="outlined"
      square={false}
    >
      <Card elevation={0} square={false} sx={{ borderRadius: "10px" }}>
        <CardHeader
          title={
            <Link
              variant="h6"
              underline="none"
              color="primary"
              fontWeight={700}
              component={RouterLink}
              to={`${ws.id}/${ROUTE_PATHS.WORKSPACES_HOME}`}
            >
              {ws.name}
            </Link>
          }
          subheader={
            <Stack>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ display: "none" }}
              >
                {ws.description ||
                  t("WorkspaceManage.CardView.ExampleDescription")}
              </Typography>
              <Typography variant="caption" color="info.dark">
                {t("WorkspaceManage.CardView.CreatedBy", {
                  by: ws.createdBy,
                })}{" "}
                {getRelativeTimeWithPreposition(ws.createdAt)}
              </Typography>
            </Stack>
          }
          action={
            <Stack direction={"row"} alignItems={"center"} gap="4px">
              {/* TODO: Uncomment when ready */}
              {/* <IconButton
                aria-label="starred"
                sx={{
                  width: "35px",
                  height: "35px",
                }}
              >
                <StarOutline />
              </IconButton> */}
              <ContextMenu onOptionSelect={(id) => onOptionSelect(id, ws)} />
            </Stack>
          }
        />
        <CardContent sx={{ paddingBottom: "16px !important" }}>
          <Stack
            direction="row"
            alignItems="center"
            gap="4px"
            sx={{ marginBottom: "24px" }}
          >
            <Logo avatarText="RRI" description="GrowthEdge RRI™" />
            <ReportStatus status={ws.lastProcessedFileStatus} />
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Stack direction={"row"} gap="4px">
              <Typography variant="subtitle2" fontWeight="bold">
                {t("WorkspaceManage.CardView.LastActivity")}:
              </Typography>
              <Typography variant="subtitle2">
                {t("Common.NotAvailable")}
              </Typography>
            </Stack>
            {(isSystemAdmin || role === Role.Admin) && (
              <Stack
                sx={{
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  startIcon={<AddOutlined />}
                  onClick={() => onOptionSelect("add-member", ws)}
                >
                  {t("WorkspaceManage.CardView.AddTeamMemberBtn")}
                </Button>
              </Stack>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Paper>
  );
}
