import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useAuthUser } from "utils/hook/useAuthUser";

import SplashScreen from "./SplashScreen";
interface ProtectedRouteProps {
  roleRequired?: "admin" | "user";
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = () => {
  const { fetchAuthUser } = useAuthUser();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchAuthUser()
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  }, []);

  return isLoading ? <SplashScreen /> : <Outlet />;
};

export default ProtectedRoute;
