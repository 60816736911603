import {
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
} from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import { KeyValue } from "models/common";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FixedSizeList, ListChildComponentProps } from "react-window";

const itemSize = 38;
const maxItemShowing = 5;

interface Props {
  title: string;
  value?: KeyValue[];
  options: KeyValue[];
  onSelect: (selected: KeyValue[]) => void;
}

export default function CollapsibleSelect(props: Props) {
  const { t } = useTranslation();
  const { title, options: dataList } = props;
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = useState(
    props.options.map((x) => ({ ...x, checked: false }))
  );

  const selectedAll = useMemo(() => {
    if (selected.every((x) => x.checked)) return "checked";
    if (selected.every((x) => !x.checked)) return "unchecked";
    return "indeterminate";
  }, [selected]);

  function handleOnClick(index: number): void {
    const newSelected = selected.map((x, i) =>
      i === index ? { ...x, checked: !x.checked } : x
    );
    setSelected(newSelected);
    props.onSelect(newSelected.filter((x) => x.checked));
  }

  function handleSelectAllClick() {
    if (selectedAll === "checked") {
      const newSelected = selected.map((x) => ({ ...x, checked: false }));
      setSelected(newSelected);
      props.onSelect(newSelected.filter((x) => x.checked));
    } else {
      const newSelected = selected.map((x) => ({ ...x, checked: true }));
      setSelected(newSelected);
      props.onSelect(newSelected.filter((x) => x.checked));
    }
  }

  useEffect(() => {
    const newSelected = props.options.map((x) => ({
      ...x,
      checked: props.value?.some((y) => x.key === y.key) ?? false,
    }));
    setSelected(newSelected);
  }, [props.value]);

  const renderRow = React.useCallback(
    (xprops: ListChildComponentProps) => {
      const { index, style } = xprops;
      const item = selected[index - 1];

      return item ? (
        <ListItemButton
          style={style}
          sx={{ paddingX: 1, paddingY: 0 }}
          onClick={() => handleOnClick(index - 1)}
        >
          <ListItemIcon>
            <Checkbox size="small" disableRipple checked={item.checked} />
          </ListItemIcon>
          <ListItemText
            primary={item.value}
            primaryTypographyProps={{ variant: "caption" }}
          />
        </ListItemButton>
      ) : (
        <ListItemButton
          style={style}
          sx={{ paddingX: 1, paddingY: 0 }}
          onClick={handleSelectAllClick}
        >
          <ListItemIcon>
            <Checkbox
              size="small"
              disableRipple
              checked={selectedAll === "checked"}
              indeterminate={selectedAll === "indeterminate"}
            />
          </ListItemIcon>
          <ListItemText
            primary={t("Common.All")}
            primaryTypographyProps={{ variant: "caption" }}
          />
        </ListItemButton>
      );
    },
    [selected]
  );

  return (
    <>
      <Paper
        sx={{
          marginY: 0.5,
          backgroundColor: open ? "#29B6F614" : "transparent",
        }}
        elevation={open ? 1 : 0}
        square
      >
        <ListItemButton
          sx={{ paddingX: 1, paddingY: 0.5 }}
          onClick={() => setOpen(!open)}
        >
          <ListItemText
            primary={`${title}${
              selectedAll === "indeterminate" ? "" : " (All)"
            }`}
            primaryTypographyProps={{ variant: "body2" }}
          />
          {open ? <ArrowDropUpOutlined /> : <ArrowDropDownOutlined />}
        </ListItemButton>
      </Paper>
      <Collapse
        sx={{ backgroundColor: open ? "#29B6F60A" : "transparent" }}
        in={open}
        timeout="auto"
        unmountOnExit
      >
        <FixedSizeList
          itemSize={itemSize}
          itemCount={dataList.length + 1}
          height={
            props.options.length < maxItemShowing
              ? itemSize * (props.options.length + 1)
              : itemSize * maxItemShowing
          }
          width="100%"
          overscanCount={10}
        >
          {renderRow}
        </FixedSizeList>
      </Collapse>
    </>
  );
}
