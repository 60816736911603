export function isFileUTF8(file: File): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const maxBytesToRead = 10 * 1024 * 1024; // 10 MB

    reader.addEventListener(
      "load",
      function (event: ProgressEvent<FileReader>) {
        try {
          const content = event.target?.result as ArrayBuffer;
          // eslint-disable-next-line unicorn/text-encoding-identifier-case
          const decoder = new TextDecoder("utf-8");
          const decodedContent = decoder.decode(content);

          // Re-encode the decoded content to compare with the original ArrayBuffer
          const encoder = new TextEncoder();
          const reEncodedContent = encoder.encode(decodedContent);

          // Compare the original ArrayBuffer with the re-encoded content
          const isUTF8 =
            content.byteLength === reEncodedContent.byteLength &&
            new Uint8Array(content).every(
              (value, index) => value === reEncodedContent[index]
            );

          resolve(isUTF8);
        } catch (error) {
          reject(error);
        }
      }
    );

    reader.addEventListener("error", function () {
      reject(new Error("Failed to read the file"));
    });

    // eslint-disable-next-line unicorn/prefer-blob-reading-methods
    reader.readAsArrayBuffer(file.slice(0, maxBytesToRead));
  });
}
