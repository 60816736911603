import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useLocation, useNavigationType } from "react-router-dom";

export default function PageSlide({
  children,
  key,
  back,
}: {
  children: React.ReactNode;
  key?: string;
  back?: boolean;
}) {
  const defaultStyle: React.CSSProperties = {
    position: "absolute" as const,
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
  };
  const duration = 300;
  const location = useLocation();
  const navType = useNavigationType();
  return (
    <div
      className={back ?? navType === "POP" ? "back" : ""}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflowX: "hidden",
        flexGrow: 1,
      }}
    >
      <TransitionGroup component={null}>
        <CSSTransition
          key={key ?? location.key}
          timeout={duration}
          classNames="slide"
        >
          <div
            style={{
              ...defaultStyle,
            }}
          >
            {children}
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}
