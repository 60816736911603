import {
  DatasetOutlined,
  ExpandLess,
  ExpandMore,
  HomeOutlined,
  ListOutlined,
  MiscellaneousServicesOutlined,
  PeopleOutlineOutlined,
  QuestionMarkRounded,
  RefreshOutlined,
  TableViewOutlined,
} from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const navBarItems = [
  {
    type: "item",
    key: "Dashboard_Overview",
    disabled: true,
    icon: <HomeOutlined />,
    children: undefined,
  },
  {
    type: "item",
    key: "Dashboard_Revenue",
    disabled: false,
    icon: <RefreshOutlined />,
    children: [
      {
        refKey: "SectionRollForward",
        title: "Dashboard.NavBar.SectionRollForward",
      },
      {
        refKey: "SectionGrowthRate",
        title: "Dashboard.NavBar.SectionGrowthRate",
      },
    ],
  },
  {
    type: "item",
    key: "Dashboard_Products",
    disabled: true,
    icon: <DatasetOutlined />,
    children: undefined,
  },
  {
    type: "item",
    key: "Dashboard_Customers",
    disabled: false,
    icon: <PeopleOutlineOutlined />,
    children: [
      {
        refKey: "SectionCountChanges",
        title: "Dashboard.NavBar.SectionCountChanges",
      },
      {
        refKey: "SectionTopCustomers",
        title: "Dashboard.NavBar.SectionTopCustomers",
      },
      {
        refKey: "SectionCohorts",
        title: "Dashboard.NavBar.SectionCohorts",
      },
    ],
  },
  {
    type: "item",
    key: "Dashboard_Additional",
    disabled: true,
    icon: <MiscellaneousServicesOutlined />,
    children: undefined,
  },
  { type: "divider" },
  {
    type: "item",
    key: "Dashboard_Glossary",
    disabled: true,
    icon: <ListOutlined />,
    children: undefined,
  },
  {
    type: "item",
    key: "Dashboard_FAQ",
    disabled: true,
    icon: <QuestionMarkRounded />,
    children: undefined,
  },
  { type: "divider" },
  {
    type: "item",
    key: "Dashboard_ExcelWorkbook",
    disabled: true,
    icon: <TableViewOutlined />,
    children: undefined,
  },
];

interface NavBarState {
  [key: string]: "collapsed" | "expanded" | "selected";
}

const defaultState: NavBarState = {};
for (const x of navBarItems) {
  if (x.key) defaultState[x.key] = "collapsed";
}
defaultState.Dashboard_Revenue = "expanded";

interface Props {
  width: number;
  onItemClick: (key: string) => void;
}

export default function DashboardNavBar(props: Props) {
  const { t } = useTranslation();

  const [navBar, setNavBar] = useState(defaultState);

  function getIcon(key: string): React.ReactElement {
    switch (navBar[key]) {
      case "selected":
      case "collapsed": {
        return <ExpandMore sx={{ color: "var(--icon-color)" }} />;
      }
      case "expanded": {
        return <ExpandLess sx={{ color: "var(--icon-color)" }} />;
      }
      default: {
        return <></>;
      }
    }
  }

  function handleOnItemClick(key: string): void {
    const status = navBar[key];
    for (const x of Object.keys(navBar)) {
      navBar[x] = "collapsed";
    }
    switch (status) {
      case "selected":
      case "collapsed": {
        setNavBar({ ...navBar, [key]: "expanded" });
        break;
      }
      case "expanded": {
        setNavBar({ ...navBar, [key]: "selected" });
        break;
      }
    }
  }

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: props.width,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { boxSizing: "border-box", width: props.width },
      }}
    >
      <Toolbar />
      <List component="nav" sx={{ paddingX: 1, paddingY: 2 }}>
        {navBarItems.map((x, i) => {
          return x.type === "divider" ? (
            <Divider key={i} sx={{ marginY: 3 }} />
          ) : (
            <div key={i}>
              <ListItemButton
                key={x.key}
                sx={{
                  "&.Mui-selected": {
                    bgcolor: "#29B6F614",
                  },
                }}
                disabled={x.disabled}
                selected={navBar[x.key!] && navBar[x.key!] !== "collapsed"}
                onClick={() => handleOnItemClick(x.key!)}
              >
                <ListItemIcon>{x.icon}</ListItemIcon>
                <ListItemText primary={t(x.key!)} />
                {x.children && getIcon(x.key)}
              </ListItemButton>
              {x.children && (
                <Collapse
                  in={navBar[x.key] === "expanded"}
                  timeout="auto"
                  unmountOnExit
                >
                  <List
                    component="div"
                    sx={{ paddingTop: 0, paddingBottom: 0 }}
                  >
                    {x.children.map((y, j) => (
                      <ListItemButton
                        onClick={() => props.onItemClick(y.refKey)}
                        key={j}
                        dense
                        selected={navBar[x.key] === "expanded"}
                        sx={{
                          "&.Mui-selected": {
                            bgcolor: "#29B6F60A",
                          },
                        }}
                      >
                        <ListItemText
                          sx={{ paddingLeft: 7 }}
                          primary={t(y.title)}
                        />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
          );
        })}
      </List>
    </Drawer>
  );
}
