import React from "react";
import { Close } from "@mui/icons-material";
import { Stack, IconButton, Box, Collapse, Alert } from "@mui/material";

import { useImportDataContext } from "../ImportDataContext";

export default function ImportLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { showAlert, alertText, closeAlert } = useImportDataContext();
  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        backgroundImage: "var(--bg-url)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      direction="column"
      flex="1 0 0"
    >
      <Collapse
        in={showAlert}
        sx={{ margin: "10px 0", alignSelf: "center", zIndex: 20 }}
      >
        <Alert
          severity="info"
          variant="standard"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={closeAlert}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
        >
          {alertText}
        </Alert>
      </Collapse>
      <Box
        alignSelf="stretch"
        height="100%"
        borderRadius="16px"
        bgcolor="#fff"
        margin="10px 24px 24px"
        padding="25px"
      >
        {children}
      </Box>
    </Stack>
  );
}
