import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { ReactNode, createContext, useCallback, useState } from "react";

interface AlertDialogOptions {
  title: string;
  message: string;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

interface AlertContextType {
  showAlert: (options: AlertDialogOptions) => void;
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);

const AlertProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<AlertDialogOptions | null>(null);

  const showAlert = useCallback((newOptions: AlertDialogOptions) => {
    setOptions(newOptions);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleConfirm = useCallback(() => {
    if (options?.onConfirm) {
      options.onConfirm();
    }
    handleClose();
  }, [options, handleClose]);

  const handleCancel = useCallback(() => {
    if (options?.onCancel) {
      options.onCancel();
    }
    handleClose();
  }, [options, handleClose]);

  return (
    <AlertContext.Provider value={{ showAlert }}>
      {children}
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { padding: 3, width: "600px" } }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton aria-label="close" onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <DialogTitle
          id="alert-dialog-title"
          fontWeight={400}
          typography="h5"
          color="error.main"
          sx={{ padding: 2 }}
        >
          {options?.title}
        </DialogTitle>
        <DialogContent sx={{ padding: 2 }}>
          <Typography
            id="alert-dialog-description"
            variant="subtitle1"
            fontFamily="Roboto"
          >
            {options?.message}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "start", paddingX: 2 }}>
          <Button
            onClick={handleConfirm}
            variant="outlined"
            color="error"
            size="large"
            sx={{ borderRadius: "120px" }}
          >
            {options?.confirmLabel || "Confirm"}
          </Button>
          <Button
            onClick={handleCancel}
            variant="text"
            color="secondary"
            size="large"
          >
            {options?.cancelLabel || "Cancel"}
          </Button>
        </DialogActions>
      </Dialog>
    </AlertContext.Provider>
  );
};

export {
  AlertContext as AlertDialogContext,
  AlertProvider as AlertDialogProvider,
};
