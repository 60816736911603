import { ChevronRight } from "@mui/icons-material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import { useSelector } from "common/store";
import { ROUTE_PATHS } from "common/constants";
import { useMemo } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";

interface Props {
  workspace?: string;
}

export default function DashboardsAppBar(props: Props) {
  const { teams } = useSelector((state) => state.team);
  const params = useParams();

  const teamName = useMemo(() => {
    return teams?.find((x) => x.id === params.id)?.name || "";
  }, [teams, params.id]);

  const teamAvatar = useMemo(() => {
    return teamName
      ?.split(" ")
      .map((x: string) => x[0])
      .slice(0, 3)
      .join("");
  }, [teamName]);

  return (
    <AppBar position="static" elevation={0}>
      <Paper variant="elevation" elevation={0}>
        <Toolbar>
          <Stack
            direction="row"
            spacing={2}
            sx={{ flexGrow: 1 }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={2}>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={
                  <ChevronRight style={{ width: "20px", height: "20px" }} />
                }
              >
                <Stack direction="row" spacing={1} alignItems={"center"}>
                  <Avatar sx={{ borderRadius: 1 }} variant="square">
                    {teamAvatar}
                  </Avatar>
                  <Link
                    underline="none"
                    color="primary"
                    component={RouterLink}
                    to={`/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}`}
                  >
                    {teamName}
                  </Link>
                </Stack>
                <Link underline="none" color="primary">
                  {props.workspace}
                </Link>
              </Breadcrumbs>
            </Stack>
          </Stack>
        </Toolbar>
      </Paper>
    </AppBar>
  );
}
