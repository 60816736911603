import { Stack, Typography, Button } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import GradientCard from "components/Card/GradientCard";
import { useAuthUser } from "utils/hook/useAuthUser";

import AuthenticationLayout from "../AuthenticationLayout";

export default function Login() {
  const { t } = useTranslation();
  const { loginMsal: login } = useAuthUser();

  return (
    <AuthenticationLayout>
      <Stack
        direction="column"
        justifyContent="center"
        height="100%"
        sx={{ paddingRight: "56px" }}
      >
        <GradientCard
          sx={{ height: "unset" }}
          contentSx={{
            padding: "48px 115px",
            "&:last-child": { paddingBottom: 6 },
          }}
        >
          <Typography variant="body1" sx={{ marginBottom: 3 }}>
            {t("Login.LoginModalLabel")}
          </Typography>
          <Button
            variant="contained"
            endIcon={<ArrowForward />}
            sx={{ width: "100%" }}
            onClick={login}
          >
            {t("Login.LoginWithMicrosoftBtn")}
          </Button>
        </GradientCard>
      </Stack>
    </AuthenticationLayout>
  );
}
