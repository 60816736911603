import React, { forwardRef } from "react";
import { WarningOutlined } from "@mui/icons-material";
import { Alert } from "@mui/material";

const WarningSnackbar = forwardRef<HTMLDivElement, { message: string }>(
  (props, ref) => (
    <Alert
      ref={ref}
      severity="warning"
      variant="filled"
      sx={{
        width: "fit-content",
        maxWidth: "600px",
        borderRadius: "4px",
        backgroundColor: "warning.main",
      }}
      icon={<WarningOutlined />}
    >
      {props.message}
    </Alert>
  )
);
WarningSnackbar.displayName = "WarningSnackbar";

export default WarningSnackbar;
