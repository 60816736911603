import FormContainer from "components/FormContainer";
import { Link } from "react-router-dom";

const PermissionDenied = () => {
  return (
    <FormContainer>
      <h2>Permission denied!</h2>
      <Link to="/login"> Back to Login</Link>
    </FormContainer>
  );
};

export default PermissionDenied;
