import {
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState, useMemo, useCallback, useEffect } from "react";
import MultipleEmailInput from "components/MultipleEmailInput";
import Modal from "components/Modal";
import useFetch from "utils/hook/useFetch";
import { useToast } from "utils/hook/useNotification";
import { Role } from "models/team";
import RoleSelect from "components/RoleSelect";
import { inviteUserToWorkspaceBatch } from "services/workspaceService";

interface InviteWorkSpaceMemberModalProps {
  open: boolean;
  workspaceName: string;
  workspaceId: string;
  onClose: () => void;
}

export default function InviteWorkSpaceMemberModal({
  open,
  workspaceName,
  workspaceId,
  onClose,
}: InviteWorkSpaceMemberModalProps) {
  const { t } = useTranslation();
  const { showSuccess, showError } = useToast();

  const [emails, setEmails] = useState<string[]>([]);
  const [hasInvalidEmails, setHasInvalidEmail] = useState(false);
  const [role, setRole] = useState(Role.ReportViewer);
  const [invitedRole, setInvitedRole] = useState<Role | null>(null);

  const {
    execute: inviteMembers,
    isLoading: isSendingInvitations,
    data: invitationResponse,
    error: invitationError,
  } = useFetch<{ userEmails: string[]; selectedRole: Role }, unknown>(
    {
      fn: ({ userEmails, selectedRole }) =>
        inviteUserToWorkspaceBatch(userEmails, [
          { workspaceId, role: selectedRole },
        ]),
    },
    [workspaceId]
  );

  const shouldDisableInvite = useMemo(() => {
    return isSendingInvitations || emails.length === 0 || hasInvalidEmails;
  }, [isSendingInvitations, hasInvalidEmails, emails]);

  const handleSendInvitationsClick = useCallback(async () => {
    setInvitedRole(role);
    await inviteMembers({ userEmails: emails, selectedRole: role });
    onClose();
    setEmails([]);
    setHasInvalidEmail(false);
  }, [emails, role]);

  const handleInvitationModalClose = useCallback(() => {
    onClose();
    setEmails([]);
    setRole(Role.ReportViewer);
    setHasInvalidEmail(false);
  }, []);

  useEffect(() => {
    if (invitationError) {
      showError({
        message: t("Dashboards.InviteMemberModal.InviteFailed"),
      });
    } else if (invitationResponse) {
      if (emails.length === 1) {
        showSuccess({
          message: t("Dashboards.InviteMemberModal.InviteSuccess", {
            user: emails[0],
            workspace: workspaceName,
            role: invitedRole === Role.ReportEditor ? "an editor" : "a viewer",
          }),
        });
      } else {
        showSuccess({
          message: t("Dashboards.InviteMemberModal.InviteMultipleSuccess", {
            userCount: emails.length,
            workspace: workspaceName,
            role: invitedRole === Role.ReportEditor ? "editor" : "viewer",
          }),
        });
      }
    }
    setRole(Role.ReportViewer);
  }, [invitationError, invitationResponse]);

  return (
    <Modal open={open} onClose={handleInvitationModalClose}>
      <DialogTitle sx={{ padding: 2 }}>
        <Typography variant="h5">
          {t("Dashboards.InviteMemberModal.Title", {
            workspace: workspaceName,
          })}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: 2 }}>
        <MultipleEmailInput
          values={emails}
          onValueChanges={setEmails}
          hasInvalidEmails={hasInvalidEmails}
          setHasInvalidEmail={setHasInvalidEmail}
          helperText={t("TeamSettings.TeamUsers.InviteTeamMember.HelperText")}
          sx={{ marginTop: 2 }}
        />
        <Typography variant="body1" sx={{ marginY: 4 }}>
          {t("Dashboards.InviteMemberModal.AssignRoleLabel")}
        </Typography>
        <RoleSelect
          value={role}
          onRoleChange={(value) => setRole(value)}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          paddingX: 2,
          paddingY: 1,
        }}
      >
        <Button
          data-testid="InviteWorkSpaceMemberModal__submitBtn"
          variant="contained"
          disabled={shouldDisableInvite}
          onClick={handleSendInvitationsClick}
        >
          {t("SendInvitations")}
        </Button>
        <Button
          data-testid="InviteWorkSpaceMemberModal__cancelBtn"
          variant="text"
          onClick={handleInvitationModalClose}
          color="secondary"
          disabled={isSendingInvitations}
        >
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Modal>
  );
}
