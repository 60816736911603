import { ChevronRight } from "@mui/icons-material";
import { AppBar as MuiAppBar } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import { ROUTES } from "common/constants";
import { useReportSelector } from "common/store";
import ProfileMenu from "components/Menu/ProfileMenu";
import { WorkspaceModel } from "models/workspace";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setOriginalSettings } from "slices/reportSlice";

interface Props {
  workspace?: WorkspaceModel;
}

export default function DashboardAppBar(props: Props) {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { reportSettings } = useReportSelector();

  function handleOnWorkspaceHomeClick(): void {
    dispatch(setOriginalSettings(reportSettings));
    navigate(ROUTES.WORKSPACE(params.id!, params.wsId!));
  }

  return (
    <MuiAppBar
      position="fixed"
      elevation={0}
      sx={{ boxShadow: "none", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Paper
        variant="elevation"
        elevation={0}
        sx={{ borderRadius: 0, boxShadow: "none" }}
      >
        <Toolbar>
          <Stack
            direction="row"
            spacing={2}
            sx={{ flexGrow: 1 }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={2}>
              <Breadcrumbs aria-label="breadcrumb" separator={<ChevronRight />}>
                <Stack direction="row" spacing={2} alignItems={"center"}>
                  <Link
                    underline="hover"
                    color="primary"
                    component="button"
                    onClick={handleOnWorkspaceHomeClick}
                  >
                    {props.workspace?.name}
                  </Link>
                </Stack>
                <Link underline="hover" color="primary">
                  {t("Dashboard_Report")}
                </Link>
              </Breadcrumbs>
            </Stack>
            <ProfileMenu />
          </Stack>
        </Toolbar>
      </Paper>
    </MuiAppBar>
  );
}
