import { useState, useMemo, useCallback, useEffect, useRef } from "react";
import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Stack,
  Avatar,
  CardHeader,
  CardActions,
  Card,
} from "@mui/material";
import { Close, DeleteOutlined, MoreHorizOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useFetch from "utils/hook/useFetch";
import { sendInvitations } from "services/superAdminService";
import PatternCard from "components/Card/PatternCard";
import MultipleEmailInput from "components/MultipleEmailInput";
import { administrationClient } from "common/client/administrationClient";
import {
  useFetcher,
  useLoaderData,
  useNavigation,
  useSearchParams,
} from "react-router-dom";
import { DataGrid, GridColDef, GridSortDirection } from "@mui/x-data-grid";
import { Search, SearchIconWrapper, StyledInputBase } from "components/Search";
import SearchIcon from "@mui/icons-material/Search";
import IconMenu from "components/Menu/IconMenu";
import { PAGE_SIZE_OPTIONS, ROUTE_PATHS } from "common/constants";
import { capitalize } from "utils/format";
import Modal from "components/Modal";
import { useSnackbar } from "notistack";
import { jwtDecode } from "jwt-decode";

function getShortName(firstName: string, lastName: string) {
  if (!firstName) return "";
  return `${firstName[0]}${lastName[0]}`;
}

type AdminUser = {
  id: string;
  createdAt: string;
  email: string;
  firstName: string;
  lastName: string;
  updatedAt: string;
  workspaces: number;
};
export interface DataType {
  error?: boolean;
  errorMessage?: string;
  data: AdminUser[];
  status?: number;
  pagination: {
    page: number;
    pageSize: number;
    total: number;
  };
}
export default function SuperAdminList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [isAddSuperAdmminDialogOpen, setIsAddSuperAdmminDialogOpen] =
    useState(false);
  const { t } = useTranslation();
  const [emails, setEmails] = useState<string[]>([]);
  const [hasInvalidEmails, setHasInvalidEmail] = useState(false);
  const {
    data: inviteData,
    isLoading,
    error,
    execute,
  } = useFetch({
    fn: (recipients: string[]) => sendInvitations(recipients),
  });

  const { enqueueSnackbar } = useSnackbar();
  const disableSendEmails = useMemo(() => {
    return emails.length === 0 || hasInvalidEmails || isLoading;
  }, [emails, hasInvalidEmails, isLoading]);

  const handleSendInvitationsClick = useCallback(async () => {
    await execute(emails);
    setIsAddSuperAdmminDialogOpen(false);
    setSearchParams((prev) => {
      const prevClone = Object.fromEntries(prev);
      return {
        ...prevClone,
        page: "0",
        orderBy: "updatedAt",
        order: "desc",
      };
    });
    setEmails([]);
  }, [emails]);

  const handleInvitationModalClose = useCallback(() => {
    setIsAddSuperAdmminDialogOpen(false);
    setEmails([]);
  }, []);

  useEffect(() => {
    if (isLoading) return;
    if (error) {
      enqueueSnackbar(t("InvitationError"), { variant: "error" });
    } else if (inviteData) {
      const message =
        emails.length > 1
          ? t("InvitedSuccessMessageForMultipleUsers", {
              numUsers: emails.length,
            })
          : t("InvitedSuccessMessageForSingleUser", { email: emails[0] });
      enqueueSnackbar(message, { variant: "success" });
    }
  }, [error, inviteData, isLoading]);
  const userEmail = useRef<string | null>(null);
  useEffect(() => {
    try {
      const decoded = jwtDecode<{ "user.username": string }>(
        localStorage.getItem("userToken") || ""
      );
      const email = decoded["user.username"];
      userEmail.current = email;
    } catch {}
  }, []);

  const { state } = useNavigation();
  const fetcher = useFetcher();
  const data = useLoaderData() as DataType;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const rowCountRef = useRef(data.pagination.total || 0);
  const rowCount = useMemo(() => {
    if (data.pagination.total !== undefined) {
      rowCountRef.current = data.pagination.total;
    }
    return rowCountRef.current;
  }, [data.pagination.total]);
  useEffect(() => {
    if (fetcher.data?.ok) {
      enqueueSnackbar(t("SuperAdmin.RemoveSuccess"), {
        variant: "success",
      });
    } else if (fetcher.data?.ok === false) {
      enqueueSnackbar(t("SuperAdmin.RemoveError"), {
        variant: "error",
      });
    }
    setDeleteModalOpen(false);
    setSelectedUserId(null);
  }, [fetcher.data]);
  const rows = data.data.map((x) => {
    return {
      id: x.id,
      name: `${x.firstName} ${x.lastName}`,
      email: x.email,
      role: "Super Admin",
      lastActivity: "N/A",
      teamAccess: "All",
      firstName: x.firstName,
      lastName: x.lastName,
      updatedAt: x.updatedAt,
    };
  });
  const columns: GridColDef[] = useMemo(() => {
    return [
      { field: "updatedAt" },
      {
        field: "firstName",
        headerName: t("SuperAdmin.Columns.Name"),
        renderCell: (params) => (
          <Stack direction="row" alignItems="center">
            <Avatar>
              {getShortName(params.row.firstName, params.row.lastName)}
            </Avatar>
            <Box
              component="section"
              title={params.row.firstName ? params.row.name : params.row.email}
              sx={{
                marginLeft: 2,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {params.row.firstName
                ? params.row.name
                : `${params.row.email} ${t("SuperAdmin.Invited")}`}
            </Box>
          </Stack>
        ),
      },
      {
        field: "role",
        headerName: t("SuperAdmin.Columns.Role"),
        valueGetter: () => "Super Admin",
      },
      {
        field: "lastActivity",
        headerName: t("SuperAdmin.Columns.LastActivity"),
        valueGetter: () => {
          return "N/A";
        },
      },
      {
        field: "teamAccess",
        headerName: t("SuperAdmin.Columns.TeamAccess"),
        valueGetter: () => "All",
      },
      {
        field: "actions",
        type: "actions",
        resizable: false,
        renderCell: (params) => (
          <IconMenu
            icon={<MoreHorizOutlined />}
            options={[
              {
                id: "delete",
                title: t("SuperAdmin.Delete"),
                icon: <DeleteOutlined />,
                color: "error.main",
                disabled: params.row.email === userEmail.current,
                onClick: () => {
                  setSelectedUserId(params.row.id);
                  setDeleteModalOpen(true);
                },
              },
            ]}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          />
        ),
      },
    ];
  }, []);

  return (
    <>
      <PatternCard
        size="medium"
        actionTitle={t("AddSuperAdmins")}
        title={t("ListSuperAdminHeader")}
        onActionClick={() => setIsAddSuperAdmminDialogOpen(true)}
        sx={{ flex: 1 }}
      >
        <CardContent>
          <Stack direction="row" justifyContent={"flex-end"} sx={{ mb: 2 }}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onChange={(e) => {
                e.preventDefault();
                const formData = new FormData(
                  e.currentTarget as HTMLFormElement
                );
                if (
                  formData.get("search") === "" &&
                  !searchParams.has("search")
                )
                  return;
                setSearchParams((prev) => {
                  const prevClone = Object.fromEntries(prev);
                  delete prevClone.page;
                  if (formData.get("search") === "" && prevClone.search) {
                    delete prevClone.search;
                    return prevClone;
                  }
                  return {
                    ...prevClone,
                    search: formData.get("search") as string,
                  };
                });
              }}
            >
              <Search>
                <SearchIconWrapper>
                  <SearchIcon
                    sx={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </SearchIconWrapper>
                <StyledInputBase
                  autoComplete="off"
                  ref={searchInputRef}
                  name="search"
                  placeholder="Search…"
                  defaultValue={searchParams.get("search")}
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            </form>
          </Stack>
          <DataGrid
            columns={columns}
            rows={rows}
            sx={{ border: "none", width: "100%" }}
            rowCount={rowCount}
            loading={state === "loading"}
            disableColumnMenu
            disableRowSelectionOnClick
            slotProps={{
              loadingOverlay: {
                variant: "linear-progress",
                noRowsVariant: "skeleton",
              },
            }}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            paginationMode="server"
            initialState={{
              columns: {
                columnVisibilityModel: {
                  updatedAt: false,
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: searchParams.has("pageSize")
                    ? Number.parseInt(searchParams.get("pageSize")!)
                    : PAGE_SIZE_OPTIONS[0],
                  page: searchParams.has("page")
                    ? Number.parseInt(searchParams.get("page")!)
                    : 0,
                },
              },
            }}
            onPaginationModelChange={({ pageSize, page }) => {
              setSearchParams((prev) => {
                return {
                  ...Object.fromEntries(prev),
                  page: page.toString(),
                  pageSize: pageSize.toString(),
                };
              });
            }}
            sortingMode="server"
            sortModel={[
              {
                field: searchParams.get("orderBy") || "updatedAt",
                sort:
                  (searchParams.get("order") as GridSortDirection) || "desc",
              },
            ]}
            onSortModelChange={(model) => {
              setSearchParams((prev) => {
                const prevClone = Object.fromEntries(prev);
                return {
                  ...prevClone,
                  page: "0",
                  orderBy: model[0]?.field || "updatedAt",
                  order: model[0]?.sort || "desc",
                };
              });
            }}
            autosizeOnMount
            autoHeight={true}
            autosizeOptions={{
              columns: ["firstName", "role", "lastActivity", "teamAccess"],
              includeHeaders: true,
              expand: true,
            }}
          />
        </CardContent>
      </PatternCard>
      <Dialog
        open={isAddSuperAdmminDialogOpen}
        onClose={handleInvitationModalClose}
        PaperProps={{
          sx: {
            padding: 3,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          <IconButton aria-label="close" onClick={handleInvitationModalClose}>
            <Close />
          </IconButton>
        </Box>
        <DialogTitle sx={{ padding: 2 }}>
          <Typography variant="h5">{t("AddSuperAdminDialogTitle")}</Typography>
        </DialogTitle>
        <DialogContent sx={{ padding: 2 }}>
          <Typography sx={{ paddingTop: 2, paddingBottom: 2 }}>
            {t("AddSuperAdminDialogDescription")}
          </Typography>
          <MultipleEmailInput
            values={emails}
            onValueChanges={setEmails}
            hasInvalidEmails={hasInvalidEmails}
            setHasInvalidEmail={setHasInvalidEmail}
            helperText={t("EmailInputHelper")}
          />
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "flex-start", padding: 2 }}
        >
          <Button
            variant="contained"
            disabled={disableSendEmails}
            onClick={handleSendInvitationsClick}
          >
            {t("SendInvitations")}
          </Button>
          <Button
            variant="text"
            onClick={handleInvitationModalClose}
            color="secondary"
            disabled={isLoading}
          >
            {t("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
      <Modal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <fetcher.Form
          action={`/${ROUTE_PATHS.SUPER_ADMIN}/${ROUTE_PATHS.SUPER_ADMIN_SETTINGS}`}
          method="delete"
        >
          <input type="hidden" name="userId" value={selectedUserId || ""} />
          <Card elevation={0}>
            <CardHeader
              title={
                <Typography variant="h5" color="error.main">
                  {t("SuperAdmin.RemoveTitle")}
                </Typography>
              }
            />
            <CardContent>
              <Typography variant="body1">
                {t("SuperAdmin.RemoveSubtitle")}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="outlined"
                type="submit"
                color="error"
                sx={{
                  borderRadius: "120px",
                }}
                disabled={fetcher.state === "submitting"}
              >
                {fetcher.state === "submitting"
                  ? t("SuperAdmin.Removing")
                  : t("SuperAdmin.RemoveButton")}
              </Button>
              <Button
                onClick={() => setDeleteModalOpen(false)}
                disabled={fetcher.state === "submitting"}
                sx={{
                  color: "secondary.main",
                }}
              >
                {t("SuperAdmin.CancelButton")}
              </Button>
            </CardActions>
          </Card>
        </fetcher.Form>
      </Modal>
    </>
  );
}

export async function loader({ request }: { request: Request }) {
  const url = new URL(request.url);
  const queryParams = new URLSearchParams(url.search);
  const data = await administrationClient.post("systemadmin/system-admins", {
    filters: {
      text: queryParams.get("search") || "",
    },
    orderBy: capitalize(queryParams.get("orderBy") || "updatedAt"),
    order: queryParams.get("order") || "desc",
    page: Number.parseInt(queryParams.get("page")!) || 0,
    pageSize:
      Number.parseInt(queryParams.get("pageSize")!) || PAGE_SIZE_OPTIONS[0],
  });
  return data.data.data;
}

export async function action({ request }: { request: Request }) {
  const formData = await request.formData();
  switch (request.method) {
    case "DELETE": {
      try {
        const response = await administrationClient.post(
          `systemadmin/remove/${formData.get("userId")}`
        );
        return {
          ok: true,
          data: response.data,
        };
      } catch (error) {
        return {
          ok: false,
          data: error,
        };
      }
    }
    default: {
      return { ok: false };
    }
  }
}
