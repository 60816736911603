import {
  Box,
  Paper,
  Stack,
  Toolbar,
  AppBar,
  Card,
  CardHeader,
  CardContent,
  TextField,
  CardActions,
  Button,
  Typography,
  Avatar,
} from "@mui/material";
import { administrationClient } from "common/client/administrationClient";
import { ROUTE_PATHS } from "common/constants";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  redirect,
  useFetcher,
  useLoaderData,
  useNavigate,
} from "react-router-dom";

export default function Welcome() {
  const { t } = useTranslation();
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const data = useLoaderData() as {
    id: string;
    isFirstLogin: boolean;
    firstName: string;
    lastName: string;
  };

  useEffect(() => {
    if (!data.isFirstLogin) {
      navigate(`/${ROUTE_PATHS.LOGIN_SUCCESS}`);
    }
  }, [data?.isFirstLogin]);

  return (
    <Stack direction="column" justifyContent="center" height="100%">
      <AppBar sx={{ boxShadow: "none", height: 64, position: "relative" }}>
        <Paper variant="outlined" sx={{ borderRadius: 0 }}>
          <Toolbar
            sx={{
              paddingX: 3,
              paddingY: 2,
            }}
          >
            <Box
              sx={{
                background: "var(--logo-light)",
                backgroundRepeat: "no-repeat",
                height: "30px",
                width: "192px",
              }}
            />
          </Toolbar>
        </Paper>
      </AppBar>
      <Stack
        direction="row"
        sx={{
          background: "var(--bg-url)",
          height: "calc(100vh - 64px)",
          backgroundSize: "100% 100%",
          width: "100%",
        }}
      >
        <Stack sx={{ m: "40px", width: "100%" }}>
          <Stack
            sx={{
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <fetcher.Form method="post" action={`/${ROUTE_PATHS.WELCOME}`}>
              <Card elevation={0} sx={{ mt: "20px", mb: "10px" }}>
                <CardHeader
                  title={
                    <Typography variant="h3" textAlign={"center"}>
                      {t("Welcome.WelcomeMsg", {
                        user: data.firstName || "User",
                      })}
                    </Typography>
                  }
                  subheader={
                    <Typography variant="h6" textAlign={"center"}>
                      {t("Welcome.Complete")}
                    </Typography>
                  }
                />
                <CardContent
                  sx={{
                    display: "flex",
                    gap: "40px",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack gap={"32px"} sx={{ width: "350px" }}>
                    <Typography variant="body1">
                      {t("Welcome.PersonalInfo")}
                    </Typography>
                    <TextField
                      label={t("Welcome.FirstName")}
                      name="firstName"
                      variant="outlined"
                      fullWidth
                      defaultValue={data.firstName}
                    />
                    <TextField
                      label={t("Welcome.LastName")}
                      name="lastName"
                      variant="outlined"
                      fullWidth
                      defaultValue={data.lastName}
                    />
                    <input type="hidden" name="userId" value={data.id} />
                  </Stack>
                  <Stack alignItems="center">
                    <Typography variant="body1" sx={{ marginBottom: 3 }}>
                      {t("Welcome.AddPhoto")}
                    </Typography>
                    <Avatar variant="square" sx={{ width: 200, height: 200 }} />
                    <CardActions>
                      <Button variant="text" disabled>
                        {t("Welcome.BtnUpload")}
                      </Button>
                      <Button variant="text" disabled>
                        {t("Welcome.BtnRemove")}
                      </Button>
                    </CardActions>
                  </Stack>
                </CardContent>

                <CardActions>
                  <Button variant="contained" type="submit">
                    {t("Welcome.BtnSave")}
                  </Button>
                  <Button
                    variant="text"
                    sx={{ borderRadius: "24px" }}
                    onClick={() => {
                      fetcher.submit(
                        {
                          userId: data.id,
                        },
                        { method: "patch" }
                      );
                    }}
                  >
                    {t("Welcome.BtnSkip")}
                  </Button>
                </CardActions>
              </Card>
            </fetcher.Form>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export async function actions({ request }: { request: Request }) {
  const formData = await request.formData();
  switch (request.method) {
    case "POST": {
      try {
        await administrationClient.patch("/profile", {
          userId: formData.get("userId"),
          firstName: formData.get("firstName") as string,
          lastName: formData.get("lastName") as string,
        });
        return redirect(`/${ROUTE_PATHS.LOGIN_SUCCESS}`);
      } catch {
        return {
          error: {
            message: "Something wrong!",
          },
        };
      }
    }
    case "PATCH": {
      try {
        await administrationClient.patch("/profile", {
          userId: formData.get("userId"),
        });
        return redirect(`/${ROUTE_PATHS.LOGIN_SUCCESS}`);
      } catch {
        return {
          error: {
            message: "Something wrong!",
          },
        };
      }
    }
    default: {
      return null;
    }
  }
}

export async function loader() {
  const data = await administrationClient.get("/profile/teams-access");
  return data.data.data;
}
