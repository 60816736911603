import { Role } from "models/team";
import { Select, FormControl, MenuItem, InputLabel } from "@mui/material";
import { Check } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

interface RoleSelectProps {
  value: Role | null;
  onRoleChange: (role: Role) => void;
  disabled?: boolean;
  variant?: "standard" | "outlined";
}

const RoleMapping = {
  [Role.ReportViewer]: "Common.Viewer",
  [Role.ReportEditor]: "Common.Editor",
};

export default function RoleSelect({
  value,
  onRoleChange,
  disabled,
  variant = "standard",
}: RoleSelectProps) {
  const { t } = useTranslation();

  const selectedValue = value || Role.ReportViewer;

  return (
    <FormControl
      variant={variant}
      sx={{
        width: "100%",
        verticalAlign: "middle",
      }}
    >
      {variant === "outlined" && (
        <InputLabel id="RoleSelect__Label">{t("Common.Role")}</InputLabel>
      )}
      <Select
        value={selectedValue}
        labelId={variant === "outlined" ? "RoleSelect__Label" : undefined}
        label={variant === "outlined" ? t("Common.Role") : undefined}
        onChange={(event) => onRoleChange(event.target.value as Role)}
        sx={{ width: "100%" }}
        renderValue={(selected) =>
          t(RoleMapping[selected as Role.ReportViewer | Role.ReportViewer])
        }
        disabled={disabled}
      >
        <MenuItem value={Role.ReportViewer}>
          {selectedValue === Role.ReportViewer && <Check />}
          {t("Common.Viewer")}
        </MenuItem>
        <MenuItem value={Role.ReportEditor}>
          {selectedValue === Role.ReportEditor && <Check />}
          {t("Common.Editor")}
        </MenuItem>
      </Select>
    </FormControl>
  );
}
