import moment from "moment";

export function getRelativeTime(utcDatetime: string): string {
  const utcMoment = moment.utc(utcDatetime);
  const localMoment = utcMoment.local();

  const duration = moment.duration(moment().diff(localMoment));
  if (duration.asSeconds() < 60) return "just now";
  if (duration.asHours() < 1)
    return `${Math.trunc(duration.asMinutes())} minute(s) ago`;
  if (duration.asDays() < 1)
    return `${Math.trunc(duration.asHours())} hour(s) ago`;
  if (duration.asDays() < 7)
    return `${Math.trunc(duration.asDays())} day(s) ago`;
  return localMoment.format("MM/DD/YYYY");
}

export function getRelativeTimeWithPreposition(utcDatetime: string): string {
  const relativeString = getRelativeTime(utcDatetime);
  return relativeString.length === 10 ? `on ${relativeString}` : relativeString;
}
