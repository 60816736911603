/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ActionReducerMapBuilder,
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import {
  AnalysisType,
  CustomerLevel,
  ReportMeasurement,
  ReportView,
  RevenueType,
} from "common/constants";
import { KeyValue } from "models/common";
import { FileModel } from "models/fileModel";
import { Axis, ByValue, FiscalMonth, Show } from "models/report";
import moment from "moment";
import {
  getCountChangeChart,
  getCustomerCohortChart,
  getReportData,
  getRevenueByPeriodChart,
  getRollForwardChart,
  getTopCustomerChart,
} from "services/reportService";
import error from "utils/error";

export interface GroupedSegmentData {
  segment: string;
  data: KeyValue[];
}

export interface ReportData {
  isLoading: boolean;
  error?: string;
  file?: FileModel;
  mapping?: Record<string, string | null>;
  months?: FiscalMonth[];
  customers?: KeyValue[];
  segmentCustomers?: GroupedSegmentData[];
  segmentProducts?: GroupedSegmentData[];
  minDate?: string;
  maxDate?: string;
}

export interface ReportFilters {
  minDate: string;
  maxDate: string;
  customers: KeyValue[];
  segmentCustomers: {
    [segment: string]: KeyValue[];
  };
  segmentProducts: {
    [segment: string]: KeyValue[];
  };
}

export interface ReportParams {
  customerLevel: CustomerLevel;
  reactivationThreshold: number;
  analysisType: AnalysisType;
  revenueType: RevenueType;
}

export interface ChartSettings {
  "growth-rate": {
    segView?: ReportView | null;
    segKey?: string | null;
  };
  "top-customers": {
    segView?: ReportView | null;
    segKey?: string | null;
    month?: string;
    topCustomers?: number;
  };
  "customer-cohorts": {
    period?: RevenueType;
    axis?: Axis;
    show?: Show;
    byValue?: ByValue;
  };
}

export interface ReportSettings {
  measurement: ReportMeasurement;
  chartSettings: ChartSettings;
  filters: ReportFilters;
  params: ReportParams;
}

export interface ChartData {
  shouldFetch: boolean;
  isLoading: boolean;
  error?: string;
  data?: any;
}

export interface ChartCacheData {
  "roll-forward": ChartData;
  "revenue-by-period": ChartData;
  "count-change": ChartData;
  "top-customers": ChartData;
  "customer-cohorts": ChartData;
}

export interface ReportState {
  /**
   * Current dashboard id is viewing
   */
  dashboardId: string;
  /**
   * The metadata of current viewing report
   */
  reportData: ReportData;
  /**
   * Cached computed data to show on chart
   */
  chartData: ChartCacheData;
  /**
   * Original report global settings per dashboard
   */
  originalSettings: ReportSettings | undefined;
  /**
   * Current report global settings per dashboard
   */
  currentSettings: {
    [dashboardId: string]: ReportSettings;
  };
}

export const defaultReportData: ReportData = {
  isLoading: true,
};

export const defaultReportFilters: ReportFilters = {
  customers: [],
  segmentCustomers: {},
  segmentProducts: {},
  minDate: moment(new Date()).format("YYYY-MM-DD"),
  maxDate: moment(new Date()).format("YYYY-MM-DD"),
};

export const defaultReportParams: ReportParams = {
  analysisType: AnalysisType.MoM,
  customerLevel: CustomerLevel.Customer,
  revenueType: RevenueType.Monthly,
  reactivationThreshold: 2,
};

export const defaultReportSettings: ReportSettings = {
  measurement: ReportMeasurement.ARR,
  chartSettings: {
    "growth-rate": {},
    "top-customers": { topCustomers: 25 },
    "customer-cohorts": {
      period: RevenueType.Monthly,
      axis: "cohort-tenure",
      show: "arr",
      byValue: "base",
    },
  },
  filters: defaultReportFilters,
  params: defaultReportParams,
};

export const initialState: ReportState = {
  dashboardId: "",
  reportData: defaultReportData,
  chartData: {
    "roll-forward": { isLoading: true, shouldFetch: true },
    "revenue-by-period": { isLoading: true, shouldFetch: false },
    "count-change": { isLoading: true, shouldFetch: true },
    "top-customers": { isLoading: true, shouldFetch: false },
    "customer-cohorts": { isLoading: true, shouldFetch: true },
  },
  originalSettings: undefined,
  currentSettings: {},
};

function handleGetReportData(builder: ActionReducerMapBuilder<ReportState>) {
  builder
    .addCase(getReportData.pending, (state, action) => {
      state.reportData.isLoading = true;
      state.dashboardId = action.meta.arg.dashboardId;
    })
    .addCase(getReportData.fulfilled, (state, action) => {
      const dashboardId = action.payload.reportData.file!.dashboardId;
      state.dashboardId = dashboardId;
      state.currentSettings[dashboardId] ??= action.payload.reportSettings;
      state.originalSettings = state.currentSettings[dashboardId];
      state.reportData = { ...action.payload.reportData, isLoading: false };
      state.chartData = initialState.chartData;
    })
    .addCase(getReportData.rejected, (state, action) => {
      state.reportData = {
        ...defaultReportData,
        isLoading: false,
        error: error(action.error),
      };
    });
}

function handleGetChartData(
  string: keyof ChartCacheData,
  thunk: any,
  builder: ActionReducerMapBuilder<ReportState>
) {
  builder
    .addCase(thunk.pending, (state) => {
      state.chartData[string].isLoading = true;
    })
    .addCase(thunk.fulfilled, (state, action) => {
      state.originalSettings = state.currentSettings[state.dashboardId];
      state.chartData[string] = {
        isLoading: false,
        data: action.payload,
        shouldFetch: false,
      };
    })
    .addCase(thunk.rejected, (state, action) => {
      state.chartData[string] = {
        isLoading: false,
        error: error(action.error),
        shouldFetch: true,
      };
    });
}

const slice = createSlice({
  name: "reportFilter",
  initialState,
  reducers: {
    setReportSettings(state, action: PayloadAction<Partial<ReportSettings>>) {
      const currentSettings = state.currentSettings[state.dashboardId];
      state.currentSettings[state.dashboardId] = {
        ...currentSettings,
        ...action.payload,
      };
    },
    setReportParams(state, action: PayloadAction<Partial<ReportParams>>) {
      const currentSettings = state.currentSettings[state.dashboardId];
      const currentParams = currentSettings.params;
      currentSettings.params = { ...currentParams, ...action.payload };
    },
    setReportFilters(state, action: PayloadAction<Partial<ReportFilters>>) {
      const currentSettings = state.currentSettings[state.dashboardId];
      const currentFilters = currentSettings.filters;
      currentSettings.filters = { ...currentFilters, ...action.payload };
    },
    setOriginalSettings(state, action: PayloadAction<ReportSettings>) {
      state.originalSettings = { ...action.payload };
    },
    setChartShouldFetch(
      state,
      action: PayloadAction<(keyof ChartCacheData)[]>
    ) {
      for (const chartKey of action.payload) {
        state.chartData[chartKey].shouldFetch = true;
      }
    },
    setChartSettings(state, action: PayloadAction<Partial<ChartSettings>>) {
      const chartSettings: any =
        state.currentSettings[state.dashboardId].chartSettings;
      for (const property in action.payload) {
        chartSettings[property] = {
          ...chartSettings[property],
          ...(action.payload as any)[property],
        };
      }
    },
  },
  extraReducers: (builder) => {
    handleGetReportData(builder);
    handleGetChartData("roll-forward", getRollForwardChart, builder);
    handleGetChartData("revenue-by-period", getRevenueByPeriodChart, builder);
    handleGetChartData("count-change", getCountChangeChart, builder);
    handleGetChartData("top-customers", getTopCustomerChart, builder);
    handleGetChartData("customer-cohorts", getCustomerCohortChart, builder);
  },
});

export const {
  setReportSettings,
  setReportParams,
  setReportFilters,
  setOriginalSettings,
  setChartShouldFetch,
  setChartSettings,
} = slice.actions;

export default slice.reducer;
