import { Add } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { SxProps, Theme } from "@mui/material/styles";
import React, { MouseEventHandler } from "react";

const cardActionButtonStyle: SxProps<Theme> = {
  color: "white",
  borderColor: "white",
  borderRadius: "120px",
};

function ActionButton({
  title,
  size,
  onClick,
}: {
  title?: string;
  size: "small" | "medium" | "large";
  onClick?: MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <Button
      variant="outlined"
      size={size}
      startIcon={<Add />}
      sx={cardActionButtonStyle}
      onClick={onClick}
    >
      {title}
    </Button>
  );
}

interface Props {
  title: string;
  actionTitle?: string;
  onActionClick?: MouseEventHandler;
  size: "small" | "medium" | "large";
  sx?: SxProps;
}

const PatternCard: React.FC<Props> = (props) => {
  return (
    <Card sx={props.sx}>
      <CardHeader
        title={props.title}
        sx={{
          backgroundImage: `var(--pattern-card-background-image)`,
          backgroundSize: "auto 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
          color: "var(--white)",
        }}
        action={
          props.actionTitle && (
            <ActionButton
              size={props.size}
              title={props.actionTitle}
              onClick={props.onActionClick}
            />
          )
        }
      />
      <CardContent sx={{ padding: 3 }}>{props.children}</CardContent>
    </Card>
  );
};

export default PatternCard;
