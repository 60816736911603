import {
  MEASUREMENT_PROPERTIES,
  ReportView,
  RevenueType,
} from "common/constants";
import lodashGroupBy from "lodash/groupBy";
import lodashSortBy from "lodash/sortBy";
import { RevenueByMonth, RevenueByMonthChartModel } from "models/report";
import { ReportSettings } from "slices/reportSlice";
import { getLastItemOfEachGroup } from "utils/report";

function getPreviousMonth(period: string) {
  const [year, month] = period.split("-");
  if (month === "1") {
    return `${Number.parseInt(year) - 1}-12`;
  }
  return `${year}-${(Number.parseInt(month) - 1).toString().padStart(2, "0")}`;
}

function getNextMonth(period: string) {
  const [year, month] = period.split("-");
  if (month === "12") {
    return `${Number.parseInt(year) + 1}-1`;
  }
  return `${year}-${(Number.parseInt(month) + 1).toString().padStart(2, "0")}`;
}

export function computeReportData(
  data: RevenueByMonth[],
  reportSettings: ReportSettings,
  segView?: ReportView | null
): RevenueByMonthChartModel[] {
  const months = lodashGroupBy(data, (item) => item.Month);

  if (Object.keys(months).length === 1) {
    const month = Object.keys(months)[0];
    const previousMonth = getPreviousMonth(month);
    const nextMonth = getNextMonth(month);

    if (!months[previousMonth]) {
      months[previousMonth] = [];
    }
    if (!months[nextMonth]) {
      months[nextMonth] = [];
    }

    for (const segment of Object.values(months[month])) {
      months[previousMonth].push({
        Month: previousMonth,
        CustomerValue: segment.CustomerValue,
        ProductValue: segment.ProductValue,
        SumArrAmount: 0,
        SumMrrAmount: 0,
        SumCArrAmount: 0,
        SumCMrrAmount: 0,
        FISCALYR: "",
        DISPLAYQTR: "",
      });
      months[nextMonth].push({
        Month: nextMonth,
        CustomerValue: segment.CustomerValue,
        ProductValue: segment.ProductValue,
        SumArrAmount: 0,
        SumMrrAmount: 0,
        SumCArrAmount: 0,
        SumCMrrAmount: 0,
        FISCALYR: "",
        DISPLAYQTR: "",
      });
    }
  }

  const models = Object.entries(months).map(([key, values]) => {
    let xValue = "";
    if (reportSettings.params.revenueType === RevenueType.Monthly)
      xValue = values[0]?.Month;
    if (reportSettings.params.revenueType === RevenueType.Quarterly)
      xValue = values[0]?.DISPLAYQTR;
    if (reportSettings.params.revenueType === RevenueType.Yearly)
      xValue = values[0]?.FISCALYR;

    const model: RevenueByMonthChartModel = {
      month: key,
      xValue,
    } as RevenueByMonthChartModel;

    let nameKey: "CustomerValue" | "ProductValue" | null = null;
    if (segView === ReportView.CustomerType) nameKey = "CustomerValue";
    if (segView === ReportView.ProductType) nameKey = "ProductValue";

    const valueKey = MEASUREMENT_PROPERTIES[reportSettings.measurement];

    for (const value of values) {
      const name = nameKey === null ? "Total" : value[nameKey!]!;
      model[name] = (value[valueKey] as number) || 0;
    }
    return model;
  });

  return getLastItemOfEachGroup(
    lodashSortBy(models, (model) => model.month),
    "xValue"
  );
}
