import { ArrowBack } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useSelector } from "common/store";
import AppBar from "components/AppBar";
import TeamMenu from "components/Menu/TeamMenu";
import TeamSettingNavBar from "components/NavBar/TeamSettingNavBar";
import { ROUTE_PATHS } from "common/constants";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router-dom";

export default function TeamSettings() {
  const backToPage = "Workspaces";

  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const { teams } = useSelector((state) => state.team);

  function handleClick(): void {
    navigate(`/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}`);
  }

  const teamName = useMemo(() => {
    return teams?.find((x) => x.id === params.id)?.name || "";
  }, [teams, params.id]);

  return (
    <Stack sx={{ minHeight: "100vh", backgroundColor: "var(--bg-color)" }}>
      <AppBar>
        <IconButton onClick={handleClick}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h6">
          {t("Common.BackTo", { page: backToPage })}
        </Typography>
        <TeamMenu />
      </AppBar>
      <Grid container spacing={3} sx={{ padding: 3 }}>
        <Grid item xs={3}>
          <TeamSettingNavBar
            team={teamName}
            section={t("TeamSettings.NavBar.Title")}
          />
        </Grid>
        <Grid item xs={9}>
          <Outlet />
        </Grid>
      </Grid>
    </Stack>
  );
}
