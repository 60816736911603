import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "common/config/msalConfig";
import { AlertDialogProvider } from "common/providers/AlertDialogContext";
import { AuthUserProvider } from "common/providers/AuthUserProvider";
import { ToastProvider } from "common/providers/NotificationContext";
import ErrorSnackbar from "components/Alerts/ErrorSnackbar";
import SuccessSnackbar from "components/Alerts/SuccessSnackbar";
import WarningSnackbar from "components/Alerts/WarningSnackbar";
import { router } from "components/Router";
import { SnackbarProvider } from "notistack";
import React from "react";
import { RouterProvider } from "react-router-dom";

const App: React.FC = () => {
  return (
    <AlertDialogProvider>
      <ToastProvider>
        <SnackbarProvider
          Components={{
            success: SuccessSnackbar,
            error: ErrorSnackbar,
            warning: WarningSnackbar,
          }}
          maxSnack={3}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MsalProvider instance={new PublicClientApplication(msalConfig)}>
            <AuthUserProvider>
              <RouterProvider router={router} />
            </AuthUserProvider>
          </MsalProvider>
        </SnackbarProvider>
      </ToastProvider>
    </AlertDialogProvider>
  );
};

export default App;
