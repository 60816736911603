import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ROUTE_PATHS } from "common/constants";
import { Role, TeamModel } from "models/team";
import extractIdFromPathname from "utils/extractIdFromPathname";

export interface TeamState {
  isLoading: boolean;
  isSystemAdmin: boolean;
  isFirstLogin: boolean;
  teams?: TeamModel[] | null;
  error?: string;
}

const initialState: TeamState = {
  isLoading: false,
  isSystemAdmin: false,
  isFirstLogin: false,
};

const slice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setState(state, action: PayloadAction<Partial<TeamState>>) {
      return { ...state, ...action.payload };
    },
  },
});

export const selectRoleByTeam = (state: {
  team: TeamState;
}): Role | undefined => {
  return state.team.teams?.find(
    (team) => team.id === extractIdFromPathname(ROUTE_PATHS.TEAMS)
  )?.role;
};

export const { setState } = slice.actions;
export default slice.reducer;
