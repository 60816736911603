import { Stepper, Step, StepLabel, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import { WORKSPACE_ROUTES, workspaceConfigs } from "../../config";
export default function MappingSteps() {
  const { t } = useTranslation();
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  useEffect(
    function () {
      const path =
        location.pathname.split("/").at(-1) || WORKSPACE_ROUTES.CUSTOMER_DATA;
      setActiveStep(
        (workspaceConfigs as unknown as { [key: string]: { step: number } })[
          path
        ]?.step || 0
      );
    },
    [location.pathname]
  );
  return (
    <Stepper
      sx={{
        display: "flex",
        width: "984px",
        padding: "0px 16px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        "& .MuiStepIcon-text": {
          fill: "#FFF",
        },
        "& .MuiStepLabel-root": {
          maxWidth: "100px",
        },
        "& .MuiStepLabel-root .Mui-completed": {
          color: "secondary.main",
        },
        "& .MuiStepLabel-root .Mui-active": {
          color: "secondary.dark",
        },
      }}
      activeStep={activeStep}
    >
      <Step key={1}>
        <StepLabel>
          <Typography variant="subtitle2">
            {t("workspace.dataMapping.step1")}
          </Typography>
        </StepLabel>
      </Step>
      <Step key={2}>
        <StepLabel>
          <Typography variant="subtitle2">
            <Trans
              i18nKey="workspace.dataMapping.step2"
              components={{ p: <p /> }}
            >
              Values <p>& Dates</p>
            </Trans>
          </Typography>
        </StepLabel>
      </Step>
      <Step key={3}>
        <StepLabel>
          <Typography variant="subtitle2">
            {t("workspace.dataMapping.step3")}
          </Typography>
        </StepLabel>
      </Step>
      <Step key={4}>
        <StepLabel>
          <Typography variant="subtitle2">
            {t("workspace.dataMapping.step4")}
          </Typography>
        </StepLabel>
      </Step>
    </Stepper>
  );
}
