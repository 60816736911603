import { Container, Box, Stack, IconButton, Typography } from "@mui/material";
import { ArrowBackOutlined } from "@mui/icons-material";
import AppBar from "components/AppBar";
import { useSelector } from "common/store";
import PageNotFound from "components/Router/PageNotFound";
import { Outlet, useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "common/constants";
import { useTranslation } from "react-i18next";

import SidePanel from "./SidePanel";

export default function SuperAdminSettings() {
  const { isSystemAdmin } = useSelector((state) => state.team);
  const { isLoading: isLoadingTeam } = useSelector((state) => state.team);
  const navigate = useNavigate();
  const { t } = useTranslation();
  if (isLoadingTeam) return null;
  if (!isSystemAdmin) return <PageNotFound />;
  return (
    <>
      <AppBar>
        <Stack direction="row" alignItems={"center"} justifyContent={"center"}>
          <IconButton
            onClick={() => {
              navigate(`/${ROUTE_PATHS.TEAMS}`);
            }}
          >
            <ArrowBackOutlined />
          </IconButton>
          <Typography variant="h6" fontWeight={500}>
            {t("SuperAdmin.BackToTeams")}
          </Typography>
        </Stack>
      </AppBar>
      <Container
        sx={{
          padding: 3,
          maxWidth: "none !important",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <SidePanel />
          <Outlet />
        </Box>
      </Container>
    </>
  );
}
