import {
  AreaChart as ReAreaChart,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import ChartTooltip from "components/ChartTooltip";

import { ChartDatum, ChartCommonProps } from "./model";
import useChartAreaHover from "./useChartAreaHover";
import { formatLegendLabel } from "./chartUtils";

interface Props<DataModel> extends ChartCommonProps<DataModel> {
  areas: ChartDatum<DataModel>[];
}

function AreaChart<DataModel>({
  data,
  width,
  height,
  xAxisKey,
  areas,
  xAxisProps,
  yAxisProps,
  margin,
  renderTooltip,
  hideLegend,
}: Props<DataModel>) {
  const { hoveringArea, handleMouseOut, handleMouseOver } =
    useChartAreaHover<DataModel>();

  return (
    <ResponsiveContainer width={width} height={height}>
      <ReAreaChart margin={margin} data={data} stackOffset="sign">
        {!hideLegend && (
          <Legend
            verticalAlign="top"
            align="left"
            iconType="circle"
            iconSize={12}
            wrapperStyle={{
              fontSize: "12px",
              paddingBottom: 14,
            }}
            formatter={(value) => formatLegendLabel(value)}
          />
        )}
        <XAxis
          dataKey={xAxisKey as string}
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 11 }}
          {...(xAxisProps as Object)}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 11 }}
          {...(yAxisProps as Object)}
        />
        <Tooltip
          isAnimationActive={false}
          content={
            renderTooltip ? (
              <ChartTooltip hoveringDataKey={hoveringArea as string}>
                {(payload) => renderTooltip(payload)}
              </ChartTooltip>
            ) : undefined
          }
        />
        {areas.map((area) => (
          <Area
            key={area.key as string}
            type="monotone"
            stackId="stack"
            dataKey={area.key as string}
            fill={area.color}
            fillOpacity={1}
            stroke="none"
            onMouseOver={() => handleMouseOver(area.key)}
            onMouseOut={handleMouseOut}
            activeDot={false}
          />
        ))}
      </ReAreaChart>
    </ResponsiveContainer>
  );
}

export default AreaChart;
