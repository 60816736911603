import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid2";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import { ReportMeasurement, ReportView } from "common/constants";
import { useChartSettings, useReportSelector } from "common/store";
import GradientCard from "components/Card/GradientCard";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setChartSettings } from "slices/reportSlice";
import useDidUpdateEffect from "utils/hook/useDidUpdateEffect";
import {
  getSourceColumnName,
  hasCustSegMapped,
  hasProdSegMapped,
} from "utils/report";

import RevenueByPeriodChart from "../charts/RevenueByPeriodChart";

export default function SectionGrowthRate() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { reportData, reportSettings } = useReportSelector();
  const chartSettings = useChartSettings("growth-rate");

  const isMappedCustSeg = useMemo(
    () => hasCustSegMapped(reportData.mapping),
    [reportData.mapping]
  );
  const isMappedProdSeg = useMemo(
    () => hasProdSegMapped(reportData.mapping),
    [reportData.mapping]
  );
  const segmentSelects = useMemo(() => {
    if (!reportData.mapping) return [];

    let segmentFields: string[] = [];
    if (chartSettings.segView === ReportView.CustomerType) {
      segmentFields = Object.keys(reportData.mapping).filter((x) =>
        x.startsWith("customerseg")
      );
    }
    if (chartSettings.segView === ReportView.ProductType) {
      segmentFields = Object.keys(reportData.mapping).filter((x) =>
        x.startsWith("product")
      );
    }
    const result = segmentFields
      .map((x) => ({
        key: x,
        value: reportData.mapping![x],
      }))
      .filter((x) => x.value);

    return result;
  }, [chartSettings.segView, reportData.mapping]);

  function handleOnViewToggleChanged(value: ReportView | null): void {
    if (value !== chartSettings.segView) {
      dispatch(setChartSettings({ "growth-rate": { segView: value } }));
    }
  }

  function handleOnSegmentKeyChanged(value: string | null): void {
    if (value !== chartSettings.segKey) {
      dispatch(setChartSettings({ "growth-rate": { segKey: value } }));
    }
  }

  useDidUpdateEffect(() => {
    if (reportData.isLoading) return;

    if (reportData.mapping && chartSettings.segView === undefined) {
      let newSegView = null;
      if (isMappedProdSeg) newSegView = ReportView.ProductType;
      if (isMappedCustSeg) newSegView = ReportView.CustomerType;
      handleOnViewToggleChanged(newSegView);
    }
  }, [reportData.isLoading]);

  useDidUpdateEffect(() => {
    if (chartSettings.segView === undefined) return;

    const value = segmentSelects.find((x) => x.key === chartSettings.segKey);
    if (value?.key) {
      handleOnSegmentKeyChanged(value.key);
      return;
    }

    handleOnSegmentKeyChanged(
      segmentSelects.length > 0 ? segmentSelects[0].key : null
    );
  }, [segmentSelects]);

  return (
    <>
      <Stack
        direction="row"
        alignItems="flex-start"
        spacing={2}
        marginBottom={2}
      >
        <Typography variant="h6" marginBottom={2} color="var(--text-secondary)">
          {t("Dashboard.Charts.SectionGrowthRate")}
        </Typography>
        <ToggleButtonGroup
          exclusive
          size="small"
          value={chartSettings.segView}
          onChange={(_, v) => handleOnViewToggleChanged(v)}
        >
          <ToggleButton
            size="small"
            value={ReportView.CustomerType}
            disabled={!hasCustSegMapped(reportData.mapping)}
          >
            {t("Common.CustSeg")}
          </ToggleButton>
          <ToggleButton
            size="small"
            value={ReportView.ProductType}
            disabled={!hasProdSegMapped(reportData.mapping)}
          >
            {t("Common.ProdSeg")}
          </ToggleButton>
          <ToggleButton size="small" value={ReportView.Extras} disabled>
            {t("Common.Extras")}
          </ToggleButton>
        </ToggleButtonGroup>
        {(isMappedCustSeg || isMappedProdSeg) && (
          <FormControl size="small" sx={{ width: "220px" }}>
            <InputLabel>
              {chartSettings.segView === ReportView.CustomerType
                ? t("Common.CustSeg")
                : t("Common.ProdSeg")}
            </InputLabel>
            <Select
              MenuProps={{ disableScrollLock: true }}
              label={
                chartSettings.segView === ReportView.CustomerType
                  ? t("Common.CustSeg")
                  : t("Common.ProdSeg")
              }
              value={chartSettings.segKey ?? ""}
              onChange={(e) => handleOnSegmentKeyChanged(e.target.value)}
            >
              {segmentSelects.map((x) => (
                <MenuItem key={x.key} value={x.key}>
                  {x.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Stack>
      <Grid container spacing={3} alignItems="stretch">
        <Grid size={6}>
          <GradientCard
            title={t("Dashboard.Charts.RevenueByTitle", {
              view: ReportMeasurement[reportSettings.measurement],
              type: chartSettings.segKey
                ? "by " +
                  getSourceColumnName(
                    reportData.mapping,
                    chartSettings.segKey ?? ""
                  )
                : "",
            })}
          >
            <RevenueByPeriodChart />
          </GradientCard>
        </Grid>
        <Grid size={6}>
          <GradientCard
            title={t("Dashboard.Charts.GrowthRateTitle")}
            onZoomClick={(x) => console.log(x)}
            onMoreClick={() => console.log("more")}
          >
            {t("Common.ComingSoon")}
          </GradientCard>
        </Grid>
        <Grid size={6}>
          <GradientCard
            title={t("Dashboard.Charts.GrossRetentionTitle")}
            onMoreClick={() => console.log("more")}
          >
            {t("Common.ComingSoon")}
          </GradientCard>
        </Grid>
        <Grid size={6}>
          <GradientCard
            title={t("Dashboard.Charts.NetRetentionTitle")}
            onMoreClick={() => console.log("more")}
          >
            {t("Common.ComingSoon")}
          </GradientCard>
        </Grid>
      </Grid>
    </>
  );
}
