import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardHeader,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTE_PATHS } from "common/constants";

import ImportLayout from "../Import/components/ImportLayout";
import { WORKSPACE_ROUTES } from "../config";

export default function NewWorkspaceConfirm() {
  const { t } = useTranslation();
  const params = useParams();

  return (
    <ImportLayout>
      <Container sx={{ height: "100%" }}>
        <Card
          elevation={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            border: "none",
          }}
        >
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              border: "none",
            }}
          >
            <CardHeader
              sx={{
                textAlign: "center",
              }}
              title={
                <Typography variant="h3" sx={{ whiteSpace: "pre-line" }}>
                  {t("WorkspaceCreated.Header")}
                  <Typography color="primary" variant="h3">
                    {t("Reports.RRI")}
                  </Typography>
                </Typography>
              }
              subheader={
                <Typography
                  textAlign="center"
                  variant="h6"
                  color="text.secondary"
                >
                  {t("WorkspaceCreated.SubHeader")}
                </Typography>
              }
            />
            <CardActions sx={{ marginTop: 3 }}>
              <NavLink to={`../${WORKSPACE_ROUTES.IMPORT}`}>
                <Button variant="contained">{t("Common.Continue")}</Button>
              </NavLink>
              <NavLink
                to={`/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}`}
              >
                <Button>{t("WorkspaceCreated.SkipBtn")}</Button>
              </NavLink>
            </CardActions>
            <Stack
              spacing={2}
              marginTop={10}
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h6">
                {t("WorkspaceCreated.ComingSoon")}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Avatar sx={{ bgcolor: "secondary.dark" }}>CPI</Avatar>
                <Typography variant="h5" color="secondary.dark">
                  {t("Reports.CPI")}
                </Typography>
              </Stack>
              <Button size="small">{t("Common.LearnMore")}</Button>
            </Stack>
          </div>
        </Card>
      </Container>
    </ImportLayout>
  );
}
