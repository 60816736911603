import { CohortReport } from "models/report";
import type { Dictionary } from "lodash";
import { ReportMeasurement, RevenueType } from "common/constants";
import { formatPeriodText } from "utils/format";
import { getLastItemOfEachGroup } from "utils/report";
import lodashOrderBy from "lodash/orderBy";

export function getTenureTotal(
  groupedData: Dictionary<CohortReport[]>,
  columnKey: string,
  columnValue: string | number,
  valueKey: string
) {
  let total = 0;

  for (const group of Object.values(groupedData)) {
    const filteredValues = getLastItemOfEachGroup(
      lodashOrderBy(group, (item) => item.Month),
      columnKey as keyof CohortReport
    );
    const period = filteredValues.find(
      (p) => p[columnKey as keyof CohortReport] === columnValue
    );
    total += period
      ? (period[valueKey as keyof CohortReport] as number) || 0
      : 0;
  }
  return total;
}

function hsbToHsl(h: number, s: number, b: number): string {
  const l = (b / 100) * (100 - s / 2);
  const newS = l === 0 || l === 1 ? 0 : ((b - l) / Math.min(l, 100 - l)) * 100;

  return `hsl(${h}, ${newS}%, ${l}%)`;
}

export function getBgColor(
  value?: number | null,
  prevValue?: number | null
): string {
  if (
    value === undefined ||
    value === null ||
    prevValue === undefined ||
    prevValue === null
  ) {
    return hsbToHsl(151, 0, 100);
  }

  if (prevValue === undefined && prevValue === value) {
    return hsbToHsl(151, 0, 100);
  }
  let percentage = Math.abs(((value - prevValue) * 100) / prevValue);

  if (percentage > 200) {
    percentage = 200;
  }

  if (value > prevValue) {
    if (percentage <= 100) {
      return hsbToHsl(151, percentage, 100);
    }
    return hsbToHsl(151, 100, 100 - (percentage - 100) / 2);
  }
  if (value < prevValue) {
    if (percentage <= 100) {
      return hsbToHsl(14, percentage, 100);
    }
    return hsbToHsl(14, 100, 100 - (percentage - 100) / 2);
  }
  return hsbToHsl(151, 0, 100);
}

export function getTextColor(
  value?: number | null,
  prevValue?: number | null
): string {
  if (
    value === undefined ||
    value === null ||
    prevValue === undefined ||
    prevValue === null
  ) {
    return "var(--black)";
  }
  const percentage = Math.abs(((value - prevValue) * 100) / prevValue);

  if (value > prevValue && percentage > 160) {
    return "var(--white)";
  }
  if (value < prevValue && percentage > 130) {
    return "var(--white)";
  }
  return "var(--black)";
}

export function getColumns(
  groupedData: Dictionary<CohortReport[]>,
  key: string
) {
  const columnSet = new Set(
    Object.values(groupedData).flatMap((g) => {
      return g.flatMap((p) => p[key as keyof CohortReport]);
    })
  ) as Set<number | string>;

  return [...columnSet]
    .filter((col) => col !== undefined)
    .sort((a, b) => {
      if (typeof a === "string") {
        if (key === "DISPLAYQTR") {
          const [a1, a2] = a.split("-"); // e.g. Q1-24
          const [b1, b2] = (a as string).split("-");

          return `${a2}-${a1}`.localeCompare(`${b2}-${b1}`);
        }
        return (a as string).localeCompare(b as string);
      }
      return a - (b as number);
    });
}

const revenueLabelMapping = {
  [RevenueType.Monthly]: "",
  [RevenueType.Quarterly]: "QTR",
  [RevenueType.Yearly]: "YR",
};

const measurementMapping = {
  [ReportMeasurement.ARR]: "ARR",
  [ReportMeasurement.MRR]: "MRR",
  [ReportMeasurement.CARR]: "CARR",
  [ReportMeasurement.CMRR]: "CMRR",
};

export function getTenureKey(
  measurement: ReportMeasurement,
  revenueType: RevenueType
): string {
  const measureString =
    measurement === ReportMeasurement.ARR ||
    measurement === ReportMeasurement.MRR
      ? measurementMapping[ReportMeasurement.ARR]
      : measurementMapping[ReportMeasurement.CARR];

  return `${measureString}TENURE${revenueLabelMapping[revenueType]}`;
}

export function getTenureHeader(
  value: number | string,
  tenureCount: number
): string {
  if (value === undefined) {
    return "";
  }
  if (typeof value === "string") {
    return formatPeriodText(value);
  }
  const digits = tenureCount.toString().length;

  return value.toString().padStart(digits, "0");
}
