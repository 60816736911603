import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { administrationClient } from "common/client/administrationClient";
import LoadingButton from "components/Button/LoadingButton";
import MultipleEmailInput from "components/MultipleEmailInput";
import { ApiErrorModel } from "models/apiErrorModel";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createTeam } from "services/superAdminService";
import useFetch from "utils/hook/useFetch";
import { useToast } from "utils/hook/useNotification";
import { validate } from "uuid";

interface Props {
  onCompleted: () => void;
  onCancel: () => void;
}

export default function ModalContentCreateTeam(props: Props) {
  const { t } = useTranslation();
  const { showSuccess, showError } = useToast();
  const { enqueueSnackbar } = useSnackbar();

  const [step, setStep] = useState(1);
  const [teamName, setTeamName] = useState<string>("");
  const [orgName, setOrgName] = useState<string>("");
  const [powerBIWorkspaceId, setPowerBIWorkspaceId] = useState<string>("");
  const [emails, setEmails] = useState<string[]>([]);
  const [hasInvalidEmails, setHasInvalidEmail] = useState(false);
  const [validating, setValidating] = useState(false);

  const disableSubmitButton = useMemo(() => {
    return (
      (step === 1 && (!teamName || !validate(powerBIWorkspaceId.trim()))) ||
      (step === 2 && (emails.length === 0 || hasInvalidEmails))
    );
  }, [step, teamName, powerBIWorkspaceId, emails, hasInvalidEmails]);

  const fetching = useFetch({
    fn: (payload: {
      name: string;
      org: string;
      powerBIWorkspaceId: string;
      emails: string[];
    }) =>
      createTeam({
        name: payload.name,
        organization: payload.org,
        emails: payload.emails,
        powerBIWorkspaceId: payload.powerBIWorkspaceId,
      }),
  });

  function showCreationSuccessMessage() {
    const text =
      emails.length === 1 ? `${emails[0]} has` : `${emails.length} people have`;
    const message = t("Teams.Modals.CreateTeam.InvitedSuccessMessage", {
      users: text,
      team: teamName,
    });
    showSuccess({ message });
  }

  useEffect(() => {
    if (fetching.error) {
      showError({
        message: t("Teams.Modals.CreateTeam.InvitedErrorMessage"),
      });
    } else if (fetching.data) {
      showCreationSuccessMessage();
      props.onCompleted();
    }
  }, [fetching.error, fetching.data]);

  async function handleOnSubmitButtonClick(): Promise<void> {
    if (step === 1) {
      try {
        setValidating(true);
        await administrationClient.post("teams/validate", {
          powerBiGroupId: powerBIWorkspaceId.trim(),
        });
        setStep(2);
      } catch (error) {
        enqueueSnackbar(
          (error as ApiErrorModel).errorMessage || t("ErrorUnknown"),
          {
            variant: "error",
          }
        );
      } finally {
        setValidating(false);
      }
    } else if (step === 2) {
      fetching.execute({
        name: teamName,
        org: orgName,
        powerBIWorkspaceId: powerBIWorkspaceId.trim(),
        emails,
      });
    }
  }

  return (
    <>
      <DialogTitle sx={{ padding: 2 }} variant="h5">
        {step === 1 ? (
          t("Teams.Modals.CreateTeam.Title1")
        ) : (
          <>
            {t("Teams.Modals.CreateTeam.Title2")}
            <strong>{teamName}</strong>
          </>
        )}
      </DialogTitle>
      <DialogContent sx={{ padding: 2 }}>
        <Typography sx={{ paddingTop: 2, paddingBottom: 2 }}>
          {step === 1
            ? t("Teams.Modals.CreateTeam.Description1")
            : t("Teams.Modals.CreateTeam.Description2")}
        </Typography>
        {step === 1 && (
          <>
            <TextField
              variant="outlined"
              fullWidth
              label={t("Teams.Modals.CreateTeam.TeamName")}
              placeholder={t("Teams.Modals.CreateTeam.TeamNamePlaceholder")}
              value={teamName}
              onChange={(x) => setTeamName(x.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              variant="outlined"
              fullWidth
              sx={{ marginTop: 4 }}
              label={t("Teams.Modals.CreateTeam.OrgName")}
              placeholder={t("Teams.Modals.CreateTeam.OrgNamePlaceholder")}
              value={orgName}
              onChange={(x) => setOrgName(x.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              variant="outlined"
              fullWidth
              sx={{ marginTop: 4 }}
              label={t("Teams.Modals.CreateTeam.PBWorkspaceId")}
              placeholder={t(
                "Teams.Modals.CreateTeam.PBWorkspaceIdPlaceholder"
              )}
              value={powerBIWorkspaceId}
              onChange={(x) => setPowerBIWorkspaceId(x.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={t("Teams.Modals.CreateTeam.PBWorkspaceIdHelperText")}
            />
          </>
        )}
        {step === 2 && (
          <MultipleEmailInput
            values={emails}
            onValueChanges={setEmails}
            hasInvalidEmails={hasInvalidEmails}
            setHasInvalidEmail={setHasInvalidEmail}
            helperText={t("Teams.Modals.CreateTeam.HelperText")}
          />
        )}
      </DialogContent>
      <DialogActions
        sx={{ display: "flex", justifyContent: "flex-start", padding: 2 }}
      >
        <LoadingButton
          loading={fetching.isLoading || validating}
          variant="contained"
          onClick={handleOnSubmitButtonClick}
          disabled={disableSubmitButton}
        >
          {step === 1 ? t("Common.Continue") : t("SendInvitations")}
        </LoadingButton>
        <Button
          variant="text"
          onClick={props.onCancel}
          color="secondary"
          disabled={fetching.isLoading}
        >
          {t("Cancel")}
        </Button>
      </DialogActions>
    </>
  );
}
