import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Typography,
} from "@mui/material";
import MappingTable, { MappingSelect, RowType } from "components/MappingTable";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ROUTE_PATHS } from "common/constants";

import { useDataMappingCtx } from "../DataMappingContext";

import MappingLayout from "./MappingLayout";

export type DataMappingContentProps = {
  rows: RowType[];
  title: string;
  subTitle: string;
  description: string;
  children?: React.ReactNode;
  continueRoute?: string;
  backRoute?: string;
  continueRouteCondition?: (mappingResult: { [key: string]: string }) => string;
  onDelete?: (index: number) => void;
};
export default function DataMappingContent({
  children,
  rows,
  title,
  subTitle,
  description,
  continueRoute,
  backRoute,
  continueRouteCondition,
  onDelete,
}: DataMappingContentProps) {
  const { id, wsId } = useParams();
  const { fields, mappingResult, doMapField } = useDataMappingCtx();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <MappingLayout>
      <Container>
        <Card elevation={0}>
          <CardHeader
            title={
              <Typography
                variant="h6"
                textTransform="uppercase"
                color="text.primary"
              >
                {t(title)}
              </Typography>
            }
          />
          <CardHeader
            title={
              <Typography variant="h4" color="text.primary">
                {t(subTitle)}
              </Typography>
            }
          />
          <CardHeader
            title={
              <Typography variant="h5" color="text.primary">
                <Trans
                  i18nKey={description}
                  components={{ strong: <strong /> }}
                >
                  {description}
                </Trans>
              </Typography>
            }
          />
          <CardContent>
            <MappingTable
              rows={rows}
              sourceFields={fields}
              mappingValues={mappingResult}
              onChange={doMapField}
              onDelete={onDelete}
              selectComponent={MappingSelect}
            />
            {children}
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              sx={{ borderRadius: "120px" }}
              onClick={() => {
                if (continueRouteCondition) {
                  navigate(continueRouteCondition(mappingResult), {
                    state: { from: "nextButton" },
                  });
                } else {
                  navigate(continueRoute || "/", {
                    state: { from: "nextButton" },
                  });
                }
              }}
            >
              {t("workspace.dataMapping.continueBtn")}
            </Button>
            <Button
              variant="outlined"
              sx={{ borderRadius: "120px" }}
              onClick={() => {
                if (location.state?.from === "nextButton") {
                  navigate(-1);
                } else {
                  backRoute && navigate(backRoute);
                }
              }}
            >
              {t("workspace.dataMapping.backBtn")}
            </Button>
            <Button
              sx={{ borderRadius: "120px" }}
              onClick={() =>
                navigate(
                  `/${ROUTE_PATHS.TEAMS}/${id}/${ROUTE_PATHS.WORKSPACES}/${wsId}/${ROUTE_PATHS.WORKSPACES_HOME}`
                )
              }
            >
              {t("workspace.dataMapping.cancelBtn")}
            </Button>
          </CardActions>
        </Card>
      </Container>
    </MappingLayout>
  );
}
