import {
  DataObjectOutlined,
  DownloadOutlined,
  MoreHorizOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import i18n from "common/i18n";
import { useSelector } from "common/store";
import IconMenu, { IconMenuItemProps } from "components/Menu/IconMenu";
import { Role } from "models/team";
import { selectRoleByTeam } from "slices/teamSlice";

const options: IconMenuItemProps[] = [
  {
    id: "export",
    icon: <DownloadOutlined />,
    title: i18n.t("Common.ExportReportData"),
  },
  { id: "divider-1" },
  {
    id: "parameters",
    icon: <SettingsOutlined />,
    title: i18n.t("Common.ReportSettings"),
  },
  { id: "divider-2" },
  {
    id: "manage",
    icon: <DataObjectOutlined />,
    title: i18n.t("Common.ManageSourceData"),
  },
];

export default function ContextMenu({
  onOptionSelect,
  wsRole,
}: {
  onOptionSelect?: (optionId: string) => void;
  wsRole?: Role | null;
}) {
  const teamRole = useSelector(selectRoleByTeam);
  const optionsByRole = options.filter((option) =>
    teamRole !== Role.Admin && wsRole !== Role.ReportEditor
      ? !["manage", "divider-2"].includes(option.id)
      : true
  );
  return (
    <IconMenu
      icon={<MoreHorizOutlined />}
      options={optionsByRole}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      onOptionSelect={onOptionSelect}
    />
  );
}
