import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import {
  useSelector as useRootSelector,
  useDispatch as useRootDispatch,
  TypedUseSelectorHook,
} from "react-redux";
import { ChartSettings, defaultReportSettings } from "slices/reportSlice";

import { reducer } from "./reducer";

const store = configureStore({
  reducer,
});

export const useDispatch = () => useRootDispatch<ReduxDispatch>();
export const useSelector: TypedUseSelectorHook<ReduxState> = useRootSelector;

export type ReduxStore = typeof store;
export type ReduxDispatch = typeof store.dispatch;
export type ReduxState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  ReduxState,
  unknown,
  Action
>;

export const useReportSelector = () =>
  useSelector((state) => {
    return {
      ...state.report,
      reportSettings:
        state.report.currentSettings[state.report.dashboardId] ??
        defaultReportSettings,
    };
  });

export const useChartSettings = (chartKey: keyof ChartSettings) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useReportSelector().reportSettings.chartSettings[chartKey] as any;
};

/* Types */
export default store;
