import { AppBarProps, AppBar as MuiAppBar } from "@mui/material";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import ProfileMenu from "components/Menu/ProfileMenu";
import React from "react";

const AppBar: React.FC<AppBarProps> = ({ children }) => {
  return (
    <>
      <MuiAppBar position="fixed" elevation={1} sx={{ boxShadow: "none" }}>
        <Paper variant="outlined" sx={{ borderRadius: 0, boxShadow: "none" }}>
          <Toolbar variant="dense">
            <Stack
              direction="row"
              alignItems={"center"}
              spacing={1}
              sx={{ flexGrow: 1, minHeight: 64 }}
            >
              {children}
            </Stack>
            <ProfileMenu />
          </Toolbar>
        </Paper>
      </MuiAppBar>
      <Toolbar />
    </>
  );
};

export default AppBar;
