import {
  DataObjectOutlined,
  MoreHorizOutlined,
  OpenInNewOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import i18n from "common/i18n";
import { useSelector } from "common/store";
import IconMenu, { IconMenuItemProps } from "components/Menu/IconMenu";
import { Role } from "models/team";
import { selectRoleByTeam } from "slices/teamSlice";

const options: IconMenuItemProps[] = [
  {
    id: "view",
    icon: <OpenInNewOutlined />,
    title: i18n.t("Dashboards.Report.ViewReport"),
  },
  // TODO: Uncomment when ready
  // {
  //   id: "export",
  //   icon: <DownloadOutlined />,
  //   title: i18n.t("Common.ExportReportData"),
  // },
  // { id: "divider-1" },
  {
    id: "settings",
    icon: <SettingsOutlined />,
    title: i18n.t("Common.ReportSettings"),
  },
  { id: "divider-2" },
  {
    id: "manage",
    icon: <DataObjectOutlined />,
    title: i18n.t("Common.ManageSourceData"),
  },
];

export default function ContextMenuDashboard({
  disabledView,
  onOptionSelect,
  role,
}: {
  disabledView: boolean;
  onOptionSelect?: (optionId: string) => void;
  role?: Role | null;
}) {
  options[0].disabled = disabledView;
  const { isSystemAdmin } = useSelector((state) => state.team);
  const teamRole = useSelector(selectRoleByTeam);
  let optionsByRole = isSystemAdmin
    ? options.filter(
        (option) => option.id !== "settings" && option.id !== "divider-1"
      )
    : options;
  if (!role) {
    optionsByRole = optionsByRole.filter(
      (option) => !["view"].includes(option.id)
    );
  }
  if (teamRole !== Role.Admin && role !== Role.ReportEditor) {
    optionsByRole = optionsByRole.filter(
      (option) => !["manage", "divider-2"].includes(option.id)
    );
  }
  return (
    <IconMenu
      icon={<MoreHorizOutlined />}
      options={optionsByRole}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      onOptionSelect={onOptionSelect}
    />
  );
}
