import { Box, Skeleton } from "@mui/material";

export default function Loading() {
  return (
    <Box
      sx={{
        backgroundColor: "var(--primary-contrast)",
        opacity: 1,
      }}
    >
      {Array.from({ length: 10 }).map((_, index) => (
        <Skeleton
          key={index}
          animation="wave"
          sx={{
            borderRadius: "10px",
            height: "50px",
          }}
        />
      ))}
    </Box>
  );
}
