import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import TopCustomersCard from "../charts/TopCustomersCard";

export default function SectionTopCustomers() {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h6" marginBottom={2} color="var(--text-secondary)">
        {t("Dashboard.Charts.ChartTopCustomer")}
      </Typography>
      <TopCustomersCard />
    </>
  );
}
