import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useFetch from "utils/hook/useFetch";
import LoadingButton from "components/Button/LoadingButton";
import _ from "lodash";
import { WorkspaceModel } from "models/workspace";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { renameWorkspace } from "services/workspaceService";
import { useToast } from "utils/hook/useNotification";

interface Props {
  workspace?: WorkspaceModel;
  onCompleted: (team?: WorkspaceModel) => void;
  onCancel: () => void;
}

export default function ModalContentRenameWorkspace(props: Props) {
  const { t } = useTranslation();
  const { showSuccess, showError } = useToast();

  const [name, setName] = useState("");

  useEffect(() => setName(props.workspace?.name ?? ""), [props.workspace]);

  const disableSubmitButton = useMemo(() => {
    const value = name?.trim();
    return _.isEmpty(value) || value === props.workspace?.name;
  }, [name, props.workspace?.name]);

  const fetching = useFetch({
    fn: (payload: { id: string; name: string }) =>
      renameWorkspace(payload.id, {
        name: payload.name,
        description: "",
      }),
  });

  useEffect(() => {
    if (fetching.error) {
      showError({
        message: t("Workspace.Actions.Rename.FailedMessage"),
      });
    } else if (fetching.data) {
      showSuccess({
        message: (
          <>
            {t("Workspace.Actions.Rename.SuccesMessage")}
            <i>{name}</i>
          </>
        ),
      });
      props.onCompleted({ ...props.workspace!, name });
    }
  }, [fetching.error, fetching.data]);

  function handleOnSubmitButtonClick(): void {
    if (!props.workspace || !name) return;
    fetching.execute({ id: props.workspace.id, name });
  }

  return (
    <>
      <DialogTitle sx={{ padding: 2 }} variant="h5">
        {t("Workspace.Actions.Rename.Title")}
      </DialogTitle>
      <DialogContent sx={{ padding: 2 }}>
        <Typography sx={{ paddingTop: 2, paddingBottom: 2 }}>
          {t("Workspace.Actions.Rename.Description")}
        </Typography>
        <TextField
          sx={{ marginTop: 2 }}
          variant="outlined"
          fullWidth
          label={t("Workspace.Actions.Rename.Label")}
          placeholder={t("Workspace.Actions.Rename.Placeholder")}
          value={name}
          onChange={(x) => setName(x.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{ display: "flex", justifyContent: "flex-start", padding: 2 }}
      >
        <LoadingButton
          loading={fetching.isLoading}
          variant="contained"
          onClick={handleOnSubmitButtonClick}
          disabled={disableSubmitButton}
        >
          {t("Common.Save")}
        </LoadingButton>
        <Button
          variant="text"
          color="secondary"
          onClick={props.onCancel}
          disabled={fetching.isLoading}
        >
          {t("Cancel")}
        </Button>
      </DialogActions>
    </>
  );
}
