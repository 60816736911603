import { Check } from "@mui/icons-material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { useSelector } from "common/store";
import { useParams } from "react-router-dom";

export default function TeamMenu() {
  const { teams } = useSelector((state) => state.team);
  const { id: teamId } = useParams();

  return (
    <FormControl size="small" sx={{ minWidth: "240px" }}>
      <Select
        value={teamId}
        onChange={(e) => {
          const path = window.location.pathname;
          const newPath = path.replace(
            teamId || "",
            encodeURIComponent(e.target.value)
          );
          window.location.href = newPath;
        }}
        renderValue={(v) => (teams || []).find((x) => x.id === v)?.name}
        sx={(theme) => ({
          fontWeight: 700,
          lineHeight: "24px",
          color: theme.palette.text.secondary,
        })}
      >
        {(teams || []).map((x) => {
          return (
            <MenuItem
              value={x.id}
              title={x.name}
              key={x.id}
              sx={{ textAlign: "right" }}
            >
              <Stack direction="row-reverse" sx={{ width: "100%" }}>
                {x.name}
                {x.id === teamId && (
                  <Check sx={{ marginRight: 1, color: "var(--icon-color)" }} />
                )}
              </Stack>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
