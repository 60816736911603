import {
  Box,
  Button,
  Container,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTE_PATHS } from "common/constants";

export default function Loading({
  variant,
}: {
  variant: "validate" | "analyze";
}) {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();

  return (
    <Stack
      style={{
        borderRadius: "8px",
        padding: "32px",
        minHeight: "calc(100vh - 50px)",
        background: "var(--mapping-layout-bg)",
      }}
    >
      <div
        style={{
          display: "flex",
          paddingBottom: "32px",
          flexDirection: "column",
          alignItems: "center",
          gap: "32px",
          flex: "1 0 0",
          alignSelf: "stretch",
          borderRadius: "8px",
          background: "#FFF",
        }}
      >
        <div
          style={{
            background:
              "linear-gradient(270deg, var(--palette-primary-light, #0097A7) 0%, #293252 100%)",
            height: "16px",
            width: "100%",
            borderRadius: "8px 8px 0 0",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            padding: "0px 24px 16px 24px",
            flexDirection: "column",
            alignItems: "center",
            alignSelf: "stretch",
          }}
        >
          <Stack
            direction={"column"}
            width={"500px"}
            alignItems={"center"}
            gap={"37px"}
            sx={{
              marginTop: "100px",
            }}
          >
            <Container
              sx={{
                width: "150px",
                height: "150px",
                background: "var(--loading-url)",
              }}
            />
            <Box sx={{ width: "100%" }}>
              <LinearProgress
                variant="determinate"
                color="primary"
                value={50}
              />
            </Box>
            <Typography variant="h5" fontSize={"23px"}>
              {variant === "validate" ? (
                <>{t("MappingReview.Loading.ValidatingMsg")}</>
              ) : (
                <>{t("MappingReview.Loading.AnalyzingMsg")}</>
              )}
            </Typography>
            {variant === "analyze" && (
              <Button
                variant="outlined"
                sx={{ borderRadius: "24px" }}
                onClick={() => {
                  navigate(
                    `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${params.wsId}/${ROUTE_PATHS.WORKSPACES_HOME}`
                  );
                }}
              >
                {t("MappingReview.Loading.ReturnBtn")}
              </Button>
            )}
          </Stack>
        </div>
      </div>
    </Stack>
  );
}
