import { dashboardManagerClient } from "common/client/dashboardManagerClient";
import { FileStatus } from "common/constants";
import { createAppThunk } from "common/appThunk";
import { FileMappingObject, FileModel } from "models/fileModel";
import { ApiErrorModel } from "models/apiErrorModel";

export const upload = async (workspaceId: string, fileName: string) => {
  const { data: response } = await dashboardManagerClient.post(
    `filemanager/workspaces/${workspaceId}/sas-token`,
    {
      fileName,
    }
  );
  return response.data;
};

export const uploaded = async (
  workspaceId: string,
  fileName: string,
  blobFileName: string,
  status: FileStatus,
  header: string[] = [],
  fileSize: number = 0,
  dashboardId?: string
) => {
  let url = `dashboard/workspaces/${workspaceId}/uploaded`;
  if (dashboardId) {
    url = `dashboard/workspaces/${workspaceId}/dashboards/${dashboardId}/reuploaded`;
  }
  const { data: response } = await dashboardManagerClient.post(url, {
    status,
    fileName,
    blobFileName,
    metadata: {
      columnHeaders: header,
      totalBatch: 0,
      fileSize,
    },
  });
  return response.data;
};

export const getDashboards = createAppThunk(
  "dashboardmanager/getList",
  async (workspaceId: string) => {
    try {
      const { data: response } = await dashboardManagerClient.get(
        `dasshboard/workspaces/${workspaceId}/dashboards`
      );
      return response.data as FileModel[];
    } catch (error) {
      if ((error as ApiErrorModel).errorCode) {
        throw (error as ApiErrorModel).errorCode;
      }
      throw error;
    }
  }
);

export const startMapping = async (
  workspaceId: string,
  dashboardId: string,
  mapObjects: FileMappingObject[]
): Promise<string> => {
  const { data: response } = await dashboardManagerClient.post(
    `dashboard/workspaces/${workspaceId}/dashboards/${dashboardId}/mapping`,
    {
      mapObjects,
    }
  );
  return response.data;
};

export async function getDashboard(
  workspaceId: string,
  id: string
): Promise<FileModel> {
  const { data: response } = await dashboardManagerClient.get(
    `dashboard/workspaces/${workspaceId}/dashboards/${id}`
  );

  return response.data;
}
