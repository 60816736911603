import { Form, Input, message } from "antd";
import { jwtDecode } from "jwt-decode";
import { SubmitButton } from "components/Button";
import FormContainer from "components/FormContainer";
import Loader from "components/Loading/Loader";
import useFetch from "utils/hook/useFetch";
import { SignUpPayload } from "models/userModel";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signup, validateInviteCode } from "services/userService";
import { useQuery } from "utils/hook/useQuery";
import { ApiErrorModel } from "models/apiErrorModel";
import { superAdminSignUp } from "services/superAdminService";

import {
  companyRules,
  confirmPasswordRules,
  nameRules,
  organizationRules,
  passwordRules,
} from "./validation";

type JWTPayload = {
  exp: string;
  project?: string;
  iss: string;
  aud: string;
};

interface SingUpResponse {
  orgName: string;
  projectName: string;
}

interface SignUpFormProps {
  isSuperAdmin?: boolean;
}

const SignUpForm: React.FC<SignUpFormProps> = ({ isSuperAdmin }) => {
  const query = useQuery();
  const navigate = useNavigate();
  let isTenant = false;

  const token = query.get("code");
  if (token) {
    try {
      const decoded = jwtDecode<JWTPayload>(token);
      isTenant = Boolean(decoded.project);
    } catch (error) {
      console.error(error);
      navigate("/invalid-code");
    }
  }

  const {
    isLoading: validateInviteCodeIsLoading,
    data: validateInviteCodeData,
    error: validateInviteCodeError,
  } = useFetch<string, string, string>({
    fn: async (code: string) => {
      return validateInviteCode({ code });
    },
    payload: query.get("code") || "",
  });

  const {
    isLoading: signupIsLoading,
    data: signupData,
    error: signupError,
    execute: signupExecute,
  } = useFetch<SignUpPayload, SingUpResponse, ApiErrorModel>({
    fn: async (payload: SignUpPayload) => {
      if (isSuperAdmin) {
        return superAdminSignUp(payload);
      }
      return signup(isTenant ? "user" : "admin", payload);
    },
  });

  const [form] = Form.useForm();
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (!validateInviteCodeIsLoading && validateInviteCodeError !== undefined)
      navigate("/invalid-code");
  }, [validateInviteCodeIsLoading]);

  useEffect(() => {
    if (!signupIsLoading && signupError !== undefined)
      message.error(signupError.errorMessage);
    if (!signupIsLoading && signupData !== undefined) {
      isTenant &&
        message.success(
          `Your account is created. You have been invited to Accelerate, Team ${signupData.orgName}, Project ${signupData.projectName}`
        );
      navigate("/login");
    }
  }, [signupIsLoading]);

  const onFinish = (values: SignUpPayload) => {
    signupExecute({
      ...values,
      email: validateInviteCodeData || "",
      invitationCode: query.get("code") || "",
    });
  };

  if (validateInviteCodeIsLoading || !validateInviteCodeData) return <Loader />;

  return (
    <FormContainer>
      <div className="text-center pb-4 font-bold text-2xl">Sign Up</div>
      <Form form={form} name="signup" onFinish={onFinish} layout="vertical">
        <Form.Item name="firstName" label="First Name" rules={nameRules}>
          <Input />
        </Form.Item>
        <Form.Item name="lastName" label="Last Name" rules={nameRules}>
          <Input />
        </Form.Item>
        {!isTenant && !isSuperAdmin && (
          <Form.Item
            name="orgName"
            label="Organization Name"
            rules={organizationRules}
          >
            <Input />
          </Form.Item>
        )}
        {!isTenant && !isSuperAdmin && (
          <Form.Item
            name="companyName"
            label="Company Name"
            rules={companyRules}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item name="password" label="Password" rules={passwordRules}>
          <Input.Password onChange={(e) => setPassword(e.target.value)} />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="Confirm Password"
          dependencies={["password"]}
          rules={confirmPasswordRules(password)}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <SubmitButton
            form={form}
            loading={signupIsLoading}
            className="w-full"
          >
            Sign Up
          </SubmitButton>
        </Form.Item>
      </Form>
    </FormContainer>
  );
};

export default SignUpForm;
