import { StopCircleOutlined } from "@mui/icons-material";
import {
  Stack,
  Container,
  Box,
  LinearProgress,
  Typography,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Importing({
  cancelUpload,
  progress,
}: {
  cancelUpload: () => void;
  progress: number;
}) {
  const { t } = useTranslation();
  return (
    <Stack
      direction={"column"}
      width={"500px"}
      alignItems={"center"}
      gap={"37px"}
      sx={{
        marginTop: "100px",
      }}
    >
      <Container
        sx={{
          width: "150px",
          height: "150px",
          background: "var(--loading-url)",
        }}
      />
      <Box sx={{ width: "100%" }}>
        <LinearProgress
          variant="determinate"
          color="primary"
          value={progress}
        />
      </Box>
      <Typography variant="h5" fontSize={"23px"}>
        {t("workspace.import.importing")}
      </Typography>
      <Button
        variant="text"
        startIcon={<StopCircleOutlined />}
        color="error"
        onClick={cancelUpload}
      >
        {t("workspace.import.cancel")}
      </Button>
    </Stack>
  );
}
