import { Add } from "@mui/icons-material";
import { CardActions, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useDataMappingCtx } from "../DataMappingContext";

import DataMappingContent, {
  DataMappingContentProps,
} from "./DataMappingContent";

export function useAddableField(attrPrefix: string, dataExample: string) {
  const { t } = useTranslation();
  const key = attrPrefix.replaceAll(" ", "");
  const { doMapField, addableFields, setAddableFields, mappingResult } =
    useDataMappingCtx();
  if (!addableFields[key]) {
    setAddableFields((state) => ({ ...state, [key]: [] }));
  }
  const deleteField = function (index: number) {
    setAddableFields((state) => ({
      ...state,
      [key]: state[key].slice(0, -1),
    }));
    for (let i = index; i < addableFields[key].length; i++) {
      doMapField(`${key}${i + 1}`, mappingResult[`${key}${i + 2}`]);
    }
    doMapField(`${key}${addableFields[key].length + 1}`, "");
  };

  const addField = function () {
    const newRow = {
      attribute: `${key}${addableFields[key].length + 2}`,
      attributeLabel: `${t(attrPrefix)} ${addableFields[key].length + 2}`,
      example: dataExample,
      dataType: t("workspace.dataMapping.dataType.string"),
      sourceFileName: "--",
    };
    setAddableFields((state) => ({
      ...state,
      [key]: [...addableFields[key], newRow],
    }));
  };

  return {
    addField,
    addableFields: addableFields[key],
    deleteField,
    mappingResult,
  };
}

export default function AddableDataMapping(
  props: DataMappingContentProps & {
    attrPrefix: string;
    dataSample: string;
    addButtonLabel: string;
  }
) {
  const {
    addField,
    addableFields = [],
    deleteField,
  } = useAddableField(props.attrPrefix, props.dataSample);
  const { t } = useTranslation();
  return (
    <DataMappingContent
      {...props}
      rows={[...props.rows, ...addableFields]}
      onDelete={deleteField}
    >
      <CardActions
        sx={{
          alignItems: "flex-end",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Button onClick={addField} disabled={addableFields.length === 9}>
          <Add /> {t(props.addButtonLabel)}
        </Button>
        {addableFields.length === 9 && (
          <Typography
            variant="caption"
            color="warning.main"
            sx={{
              display: "inline-block",
              marginRight: "8px",
            }}
          >
            {t("workspace.dataMapping.maxAddable")}
          </Typography>
        )}
      </CardActions>
    </DataMappingContent>
  );
}
