import React from "react";
import { Layout } from "antd";

const { Content } = Layout;

const FormContainer: React.FC = ({ children }) => {
  return (
    <Content className="flex justify-center items-center h-[calc(100vh-100px)]">
      <div className="min-w-96 min-h-72 p-8 flex-col justify-center items-center gap-8 bg-white rounded-lg shadow-lg">
        {children}
      </div>
    </Content>
  );
};

export default FormContainer;
