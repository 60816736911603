import { SVGProps } from "react";

export default function VerticalAxisTick(
  props: SVGProps<SVGTextElement> & {
    value: string;
  }
) {
  const { x, y, width, height, value } = props;

  return (
    <text
      orientation="bottom"
      width={width}
      height={height}
      stroke="none"
      fontSize="11"
      transform={`rotate(90, ${x}, ${y})`}
      x={x}
      y={y}
      fill="var(--neutral-06)"
      dominantBaseline={"middle"}
    >
      {value}
    </text>
  );
}
