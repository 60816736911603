import {
  HttpTransportType,
  HubConnectionBuilder,
  JsonHubProtocol,
  LogLevel,
} from "@microsoft/signalr";
import { getAccessToken } from "common/providers/AuthUserProvider";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export interface SocketPayload<TData> {
  topic: string;
  data: TData;
}

export default function useSocket<TData>(
  callBack: (payload: SocketPayload<TData>) => void,
  resource: string = "hubs/file-status"
) {
  const params = useParams();
  useEffect(() => {
    const token = getAccessToken();
    const teamId = params.id;
    const connection = new HubConnectionBuilder()
      .withHubProtocol(new JsonHubProtocol())
      .configureLogging(LogLevel.Debug)
      .withUrl(
        `${process.env.REACT_APP_API_SOCKET_BASEURL}/${resource}?token=${token}&teamId=${teamId}`,
        {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
        }
      )
      .build();

    connection.on("FileStatusChange", (data) => {
      callBack(data);
    });
    connection.on("ClientJoin", (data) => {
      console.info("ClientJoin", data);
    });

    connection.start().catch((error) => console.error({ error }));

    return () => {
      connection.stop();
    };
  }, []);
}
