import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import React from "react";
import { useTranslation } from "react-i18next";

export const chartMinHeight = 512;

export function Container({
  children,
  minHeight,
}: {
  children: React.ReactNode;
  minHeight?: number | string;
}) {
  return (
    <Stack
      width="100%"
      minHeight={minHeight === undefined ? chartMinHeight : minHeight}
      alignItems="center"
      justifyContent="center"
    >
      {children}
    </Stack>
  );
}

interface WrapperContainerProps {
  isLoading: boolean;
  isNoData?: boolean | undefined;
  error?: string | undefined;
  minHeight?: number | string;
}

export const WrapperContainer: React.FC<WrapperContainerProps> = (props) => {
  const { t } = useTranslation();

  if (props.isLoading)
    return (
      <Container>
        <CircularProgress />
      </Container>
    );

  if (props.error) return <Container>{t("Common.Error")}</Container>;

  if (props.isNoData) return <Container>{t("Common.NoData")}</Container>;

  return <Container minHeight={props.minHeight}>{props.children}</Container>;
};
