import FormContainer from "components/FormContainer";

const InvalidInvitationCode = () => {
  return (
    <FormContainer>
      <h2>Invitation code is invalid</h2>
      <p>Please contact support</p>
    </FormContainer>
  );
};

export default InvalidInvitationCode;
