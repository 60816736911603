import {
  GridViewOutlined,
  LogoutOutlined,
  PeopleAltOutlined,
  Person3Outlined,
} from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ROUTE_PATHS } from "common/constants";
import { useSelector } from "common/store";
import { Role } from "models/team";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { selectRoleByTeam } from "slices/teamSlice";
import { useAuthUser } from "utils/hook/useAuthUser";

export default function ProfileMenu() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const { logout } = useAuthUser();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isSystemAdmin = useSelector((state) => state.team.isSystemAdmin);
  const role = useSelector(selectRoleByTeam);
  const teams = useSelector((state) => state.team.teams);

  return (
    <>
      <Avatar onClick={(e) => setAnchorEl(e.currentTarget)} />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        disableScrollLock
        autoFocus={false}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem>
          <Person3Outlined sx={{ color: "var(--icon-color)" }} />
          <Box sx={{ paddingLeft: 1 }}>{t("ProfileMenu_Profile")}</Box>
        </MenuItem>
        {isSystemAdmin && (
          <MenuItem>
            <GridViewOutlined sx={{ color: "var(--icon-color)" }} />
            <Box sx={{ paddingLeft: 1 }}>{t("ProfileMenu_Workspaces")}</Box>
          </MenuItem>
        )}
        {(isSystemAdmin || role === Role.Admin) && (
          <MenuItem
            onClick={() =>
              navigate(
                `/${ROUTE_PATHS.TEAMS}/${
                  params.id ||
                  localStorage.getItem("lastTeamId") ||
                  teams?.[0]?.id
                }/${ROUTE_PATHS.TEAMS_SETTINGS}/${
                  ROUTE_PATHS.TEAMS_SETTINGS_USERS
                }`
              )
            }
          >
            <PeopleAltOutlined sx={{ color: "var(--icon-color)" }} />
            <Box sx={{ paddingLeft: 1 }}>{t("ProfileMenu_TeamUsers")}</Box>
          </MenuItem>
        )}
        <Divider sx={{ width: 220 }} />
        <MenuItem onClick={logout}>
          <LogoutOutlined sx={{ color: "var(--icon-color)" }} />
          <Box sx={{ paddingLeft: 1 }}>{t("ProfileMenu_Logout")}</Box>
        </MenuItem>
      </Menu>
    </>
  );
}
