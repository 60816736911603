import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  PopoverOrigin,
} from "@mui/material";
import React from "react";

export interface IconMenuItemProps {
  id: string;
  title?: string;
  icon?: React.ReactNode;
  color?: string;
  disabled?: boolean;
  onClick?: () => void;
}

interface Props {
  icon: React.ReactNode;
  options: IconMenuItemProps[];
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  onOptionSelect?: (optionId: string) => void;
}

const IconMenu: React.FC<Props> = ({
  icon,
  options,
  anchorOrigin,
  transformOrigin,
  onOptionSelect,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>{icon}</IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        disableScrollLock={true}
        autoFocus={false}
      >
        {options.map((x) => {
          return x.title === undefined ? (
            <Divider key={x.id} />
          ) : (
            <MenuItem
              key={x.id}
              onClick={() => {
                handleClose();
                onOptionSelect?.(x.id);
                x.onClick?.();
              }}
              sx={{
                "& .MuiSvgIcon-root": {
                  color: x.color || "var(--icon-color)",
                },
              }}
              disabled={x.disabled}
            >
              {x.icon}
              <Box paddingLeft={1} color={x.color}>
                {x.title}
              </Box>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default IconMenu;
