import {
  Typography,
  Stack,
  Tooltip,
  ClickAwayListener,
  Divider,
  Box,
} from "@mui/material";
import {
  CheckCircleOutlineOutlined,
  CloseOutlined,
  ErrorOutline,
} from "@mui/icons-material";
import { useState } from "react";
import { RowType } from "components/MappingTable";
import { useTranslation } from "react-i18next";

function TooltipContent({
  row,
  errorCode,
  data,
}: {
  row: RowType;
  errorCode: number;
  data: string[];
}) {
  const { t } = useTranslation();
  const dataType = row.dataType.split(".").at(-1);
  if (
    errorCode === 5 &&
    dataType !== "normalDate" &&
    dataType !== "dateWithFormat"
  )
    errorCode += 1;
  const dataTypeDisplay =
    dataType === "string" || dataType === "stringOrNumber"
      ? "text string"
      : "decimal number";
  const header1Map = [
    "",
    <Typography key="1" variant="body2">
      {t("workspace.dataMapping.errorTooltip.required")}
    </Typography>,
    <Typography key="2" variant="body2">
      {t("workspace.dataMapping.errorTooltip.duplicate")}
    </Typography>,
    <Typography key="3" variant="body2">
      {t("workspace.dataMapping.errorTooltip.allNull")}
      <p>{t("workspace.dataMapping.errorTooltip.nullExample")}</p>
    </Typography>,
    <Typography key="4" variant="body2">
      {t("workspace.dataMapping.errorTooltip.hasNull")}
      <p>{t("workspace.dataMapping.errorTooltip.nullExample")}</p>
    </Typography>,
    <Typography key="5" variant="body2">
      {t("workspace.dataMapping.errorTooltip.wrongFormat", {
        attr: t(row.attributeLabel || ""),
      })}
      <p>
        <strong>MM/YYYY</strong>
      </p>
      <p>
        <i>{t("Common.Example")}: 03/31/2021</i>
      </p>
    </Typography>,
    <Typography key="6" variant="body2">
      {t("workspace.dataMapping.errorTooltip.wrongType", {
        attr: t(row.attributeLabel || ""),
        type: dataTypeDisplay,
      })}
      <p>
        <i>
          {t("Common.Example")}: {row.example}
        </i>
      </p>
    </Typography>,
  ];
  return (
    <Box
      sx={{
        maxWidth: "200px",
      }}
    >
      <Typography variant="body2">
        {header1Map[errorCode]}
        {data.map((item) => (
          <p key={item}>{item}</p>
        ))}
      </Typography>
      <Divider
        sx={{
          backgroundColor: "#FFF",
          my: "8px",
        }}
      />
      <Typography variant="h6">
        {t("workspace.dataMapping.errorTooltip.remedyHeader")}
      </Typography>
      <ul style={{ listStyleType: "disc" }}>
        {errorCode === 1 ? (
          <li>
            <Typography variant="body2">
              {t("workspace.dataMapping.errorTooltip.requiredRemedy")}
            </Typography>
          </li>
        ) : (
          <>
            <li>
              <Typography variant="body2">
                {t("workspace.dataMapping.errorTooltip.changeAttributeRemedy")}
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                {t("workspace.dataMapping.errorTooltip.reimportRemedy")}
              </Typography>
            </li>
          </>
        )}
      </ul>
    </Box>
  );
}

export function ErrorTooltip({
  row,
  errorType,
}: {
  row: RowType;
  errorType: { errorCode: number; data: string[] };
}) {
  const { t } = useTranslation();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          title={
            <Stack
              sx={{
                gap: "8px",
                padding: "16px",
                justifyContent: "center",
                alignItems: "flex-start",
                flex: "1 0 0",
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-end"
                sx={{ width: "100%" }}
              >
                <CloseOutlined
                  sx={{ cursor: "pointer" }}
                  onClick={handleTooltipClose}
                />
              </Stack>
              <Typography variant="h6">
                {t("workspace.dataMapping.errorTooltip.header")}
              </Typography>
              <TooltipContent
                row={row}
                errorCode={errorType.errorCode}
                data={errorType.data}
              />
            </Stack>
          }
          arrow
          placement="right"
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={tooltipOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          {errorType.errorCode > 0 ? (
            <ErrorOutline
              onClick={() => {
                setTooltipOpen(true);
              }}
              sx={{
                color: "error.main",
                width: "35px",
                height: "35px",
                zIndex: 10,
                cursor: "pointer",
              }}
            />
          ) : (
            <CheckCircleOutlineOutlined
              sx={{
                color: "success.main",
                width: "35px",
                height: "35px",
                zIndex: 10,
              }}
            />
          )}
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
}
