import { RowType } from "components/MappingTable";

import AddableDataMapping from "./DataMapping/components/AddableDataMapping";
import ConfirmDialog from "./DataMapping/components/DataMappingConfirm";
import DataMappingContent from "./DataMapping/components/DataMappingContent";
import ImportData from "./Import";
import ImportDataSuccess from "./Import/components/ImportDataSuccess";
import NewWorkspace, { actionHandlers } from "./New";
import NewWorkspaceConfirm from "./New/Confirm";
import Review, { actions } from "./DataMapping/components/Review";

/* eslint-disable no-unused-vars */
export enum WORKSPACE_ROUTES {
  HOME = "home",
  NEW = "new",
  NEW_CONFIRM = "new-confirm",
  CUSTOMER_DATA = "customer",
  CUSTOMER_DATA_CONFIRM = "customer-confirm",
  CUSTOMER_DATA_SEGMENT = "customer-segment",
  CUSTOMER_DATA_SEGMENT_CONFIRM = "customer-segment-confirm",
  CUSTOMER_DATA_LOCATION = "customer-location",
  CUSTOMER_DATA_VALUES_DATES = "customer-values-dates",
  CUSTOMER_DATA_VALUES_DATES_CONTRACT = "customer-values-dates-contract",
  CUSTOMER_DATA_VALUES_DATES_MISC = "customer-values-dates-misc",
  CUSTOMER_DATA_VALUES_DATES_MISC_CONFIRM = "customer-values-dates-misc-confirm",
  CUSTOMER_DATA_PRODUCTS = "customer-products",
  CUSTOMER_DATA_ADDITIONAL = "customer-additional",
  CUSTOMER_DATA_REVIEW = "customer-review",
  IMPORT = "import",
  IMPORT_SUCCESS = "import-success",
}
export const newWorkspaceConfig = {
  [WORKSPACE_ROUTES.NEW]: {
    title: "",
    component: NewWorkspace,
    action: actionHandlers,
  },
  [WORKSPACE_ROUTES.IMPORT]: {
    component: ImportData,
    title: "",
  },
  [WORKSPACE_ROUTES.NEW_CONFIRM]: {
    title: "",
    component: NewWorkspaceConfirm,
  },
};
export const workspaceConfigs = {
  [WORKSPACE_ROUTES.IMPORT_SUCCESS]: {
    component: ImportDataSuccess,
    title: "",
  },
  [WORKSPACE_ROUTES.CUSTOMER_DATA]: {
    step: 0,
    rows: [
      {
        isRequiredIndex: true,
        isRequired: true,
        attribute: "Customer",
        attributeLabel: "workspace.dataMapping.fields.customer",
        example: "12345678, McDonald’s",
        dataType: "workspace.dataMapping.dataType.string",
      },
      {
        isOptionalIndex: true,
        attribute: "ParentCustomer",
        attributeLabel: "workspace.dataMapping.fields.parentCustomer",
        example: "12345678, McDonald’s",
        dataType: "workspace.dataMapping.dataType.stringOrNumber",
      },
    ],
    title: "workspace.dataMapping.titles.sourceData",
    subTitle: "workspace.dataMapping.subTitles.customerData",
    description: "workspace.dataMapping.customerDesc",
    continueRoute: `../${WORKSPACE_ROUTES.CUSTOMER_DATA_CONFIRM}`,
    backRoute: `../${WORKSPACE_ROUTES.IMPORT_SUCCESS}`,
    component: DataMappingContent,
  },
  [WORKSPACE_ROUTES.CUSTOMER_DATA_CONFIRM]: {
    step: 0,
    title: "workspace.dataMapping.titles.questionSegments",
    questions: [
      "workspace.dataMapping.questions.segmentYes",
      "workspace.dataMapping.questions.segmentNo",
    ],
    yesRoute: `../${WORKSPACE_ROUTES.CUSTOMER_DATA_SEGMENT}`,
    noRoute: `../${WORKSPACE_ROUTES.CUSTOMER_DATA_SEGMENT_CONFIRM}`,
    backRoute: `../${WORKSPACE_ROUTES.CUSTOMER_DATA}`,
    component: ConfirmDialog,
  },
  [WORKSPACE_ROUTES.CUSTOMER_DATA_SEGMENT]: {
    step: 0,
    rows: [
      {
        isOptionalIndex: true,
        attribute: "CustomerSegment1",
        multiple: true,
        max: 10,
        attributeLabel: "workspace.dataMapping.fields.customerSegment1",
        example: "Enterprise, Corporate",
        dataType: "workspace.dataMapping.dataType.string",
      },
    ],
    title: "workspace.dataMapping.titles.sourceData",
    subTitle: "workspace.dataMapping.subTitles.customerDataSegment",
    description: "workspace.dataMapping.customerSegmentDesc",
    attrPrefix: "CustomerSegment",
    dataSample: "Enterprise, Corporate",
    addButtonLabel: "workspace.dataMapping.addButtonLabel",
    continueRoute: `../${WORKSPACE_ROUTES.CUSTOMER_DATA_SEGMENT_CONFIRM}`,
    backRoute: `../${WORKSPACE_ROUTES.CUSTOMER_DATA}`,
    component: AddableDataMapping,
  },
  [WORKSPACE_ROUTES.CUSTOMER_DATA_SEGMENT_CONFIRM]: {
    step: 0,
    title: "workspace.dataMapping.titles.questionLocation",
    questions: [
      "workspace.dataMapping.questions.locationYes",
      "workspace.dataMapping.questions.locationNo",
    ],
    yesRoute: `../${WORKSPACE_ROUTES.CUSTOMER_DATA_LOCATION}`,
    noRoute: `../${WORKSPACE_ROUTES.CUSTOMER_DATA_VALUES_DATES}`,
    backRoute: `../${WORKSPACE_ROUTES.CUSTOMER_DATA_SEGMENT}`,
    component: ConfirmDialog,
  },
  [WORKSPACE_ROUTES.CUSTOMER_DATA_LOCATION]: {
    step: 0,
    rows: [
      {
        isOptionalIndex: true,
        attribute: "Product12",
        attributeLabel: "workspace.dataMapping.fields.Region",
        example: "Weston US",
        dataType: "workspace.dataMapping.dataType.string",
      },
      {
        attribute: "Product11",
        attributeLabel: "workspace.dataMapping.fields.Geography",
        example: "Northh America",
        dataType: "Text",
      },
    ],
    title: "workspace.dataMapping.titles.sourceData",
    subTitle: "workspace.dataMapping.subTitles.customerDataLocation",
    description: "workspace.dataMapping.customerLocationDesc",
    continueRoute: `../${WORKSPACE_ROUTES.CUSTOMER_DATA_VALUES_DATES}`,
    backRoute: `../${WORKSPACE_ROUTES.CUSTOMER_DATA_SEGMENT}`,
    component: DataMappingContent,
  },
  [WORKSPACE_ROUTES.CUSTOMER_DATA_VALUES_DATES]: {
    step: 1,
    rows: [
      {
        isRequiredIndex: true,
        isRequired: true,
        attribute: "TotalValue",
        attributeLabel: "workspace.dataMapping.fields.TotalValue",
        example: "10,000.00",
        dataType: "workspace.dataMapping.dataType.decimal",
      },
      {
        attribute: "StartDate",
        isRequired: true,
        attributeLabel: "workspace.dataMapping.fields.Date",
        example: "01/01/2024",
        dataType: "workspace.dataMapping.dataType.dateWithFormat",
      },
      {
        isOptionalIndex: true,
        attribute: "EndDate",
        attributeLabel: "workspace.dataMapping.fields.EndDate",
        example: "03/01/2024",
        dataType: "workspace.dataMapping.dataType.dateWithFormat",
      },
      {
        attribute: "FiscalStartMonth",
        attributeLabel: "workspace.dataMapping.fields.FiscalStartMonth",
        example: "03/01/2024",
        dataType: "workspace.dataMapping.dataType.dateWithFormat",
      },
    ],
    title: "workspace.dataMapping.titles.sourceData",
    subTitle: "workspace.dataMapping.subTitles.customerDataValuesDates",
    description: "workspace.dataMapping.valsDatesDesc",
    continueRoute: `../${WORKSPACE_ROUTES.CUSTOMER_DATA_VALUES_DATES_CONTRACT}`,
    backRoute: `../${WORKSPACE_ROUTES.CUSTOMER_DATA_LOCATION}`,
    continueRouteCondition: function (mappingResult: { EndDate?: string }) {
      return mappingResult.EndDate && mappingResult.EndDate !== ""
        ? `../${WORKSPACE_ROUTES.CUSTOMER_DATA_VALUES_DATES_CONTRACT}`
        : `../${WORKSPACE_ROUTES.CUSTOMER_DATA_VALUES_DATES_MISC}`;
    },
    component: DataMappingContent,
  },
  [WORKSPACE_ROUTES.CUSTOMER_DATA_VALUES_DATES_CONTRACT]: {
    step: 1,
    rows: [
      {
        isOptionalIndex: true,
        attribute: "ContractType",
        attributeLabel: "workspace.dataMapping.fields.ContractType",
        example: "New Customers, Renewal",
        dataType: "workspace.dataMapping.dataType.string",
      },
      {
        attribute: "StartDateforCARR",
        attributeLabel: "workspace.dataMapping.fields.StartDateforCARR",
        example: "01/01/2023",
        dataType: "workspace.dataMapping.dataType.normalDate",
      },
      {
        attribute: "EndDateforCARR",
        attributeLabel: "workspace.dataMapping.fields.EndDateforCARR",
        example: "01/01/2024",
        dataType: "workspace.dataMapping.dataType.normalDate",
      },
      {
        attribute: "SignDate",
        attributeLabel: "workspace.dataMapping.fields.SignDate",
        example: "01/01/2024",
        dataType: "workspace.dataMapping.dataType.normalDate",
      },
    ],
    title: "Map source data",
    subTitle: "workspace.dataMapping.subTitles.customerDataValuesDatesContract",
    description: "workspace.dataMapping.valsDatesContractDesc",
    continueRoute: `../${WORKSPACE_ROUTES.CUSTOMER_DATA_VALUES_DATES_MISC}`,
    backRoute: `../${WORKSPACE_ROUTES.CUSTOMER_DATA_VALUES_DATES}`,
    component: DataMappingContent,
  },
  [WORKSPACE_ROUTES.CUSTOMER_DATA_VALUES_DATES_MISC]: {
    step: 1,
    rows: [
      {
        isOptionalIndex: true,
        attribute: "Recurrence",
        attributeLabel: "workspace.dataMapping.fields.Recurring",
        example: "Recurring, Non-recurring",
        dataType: "workspace.dataMapping.dataType.string",
      },
      {
        attribute: "Currency",
        attributeLabel: "workspace.dataMapping.fields.Currency",
        example: "USD",
        dataType: "workspace.dataMapping.dataType.string",
      },
      {
        attribute: "Quantity",
        attributeLabel: "workspace.dataMapping.fields.Quantity",
        example: "100.00",
        dataType: "workspace.dataMapping.dataType.decimal",
      },
    ],
    title: "workspace.dataMapping.titles.sourceData",
    subTitle: "workspace.dataMapping.subTitles.customerDataValuesDatesMisc",
    description: "workspace.dataMapping.valsDatesMiscDesc",
    continueRoute: `../${WORKSPACE_ROUTES.CUSTOMER_DATA_VALUES_DATES_MISC_CONFIRM}`,
    backRoute: `../${WORKSPACE_ROUTES.CUSTOMER_DATA_VALUES_DATES_CONTRACT}`,
    component: DataMappingContent,
  },
  [WORKSPACE_ROUTES.CUSTOMER_DATA_VALUES_DATES_MISC_CONFIRM]: {
    step: 1,
    title: "workspace.dataMapping.titles.questionProduct",
    questions: [
      "workspace.dataMapping.questions.productYes",
      "workspace.dataMapping.questions.productNo",
    ],
    yesRoute: `../${WORKSPACE_ROUTES.CUSTOMER_DATA_PRODUCTS}`,
    noRoute: `../${WORKSPACE_ROUTES.CUSTOMER_DATA_ADDITIONAL}`,
    backRoute: `../${WORKSPACE_ROUTES.CUSTOMER_DATA_VALUES_DATES_MISC}`,
    component: ConfirmDialog,
  },
  [WORKSPACE_ROUTES.CUSTOMER_DATA_PRODUCTS]: {
    step: 2,
    rows: [
      {
        isOptionalIndex: true,
        attribute: "Product1",
        multiple: true,
        max: 10,
        attributeLabel: "workspace.dataMapping.fields.Product1",
        example: "Product A",
        dataType: "workspace.dataMapping.dataType.string",
      },
    ],
    title: "workspace.dataMapping.titles.sourceData",
    subTitle: "workspace.dataMapping.subTitles.customerDataProduct",
    description: "workspace.dataMapping.productDesc",
    attrPrefix: "Product",
    dataSample: "Product A",
    addButtonLabel: "workspace.dataMapping.addButtonLabelProduct",
    continueRoute: `../${WORKSPACE_ROUTES.CUSTOMER_DATA_ADDITIONAL}`,
    backRoute: `../${WORKSPACE_ROUTES.CUSTOMER_DATA_VALUES_DATES_MISC}`,
    component: AddableDataMapping,
  },
  [WORKSPACE_ROUTES.CUSTOMER_DATA_ADDITIONAL]: {
    step: 3,
    rows: [
      {
        isOptionalIndex: true,
        attribute: "RecordID",
        attributeLabel: "workspace.dataMapping.fields.RecordID",
        example: "0015500000WO1 ZiAAL",
        dataType: "workspace.dataMapping.dataType.stringOrNumber",
      },
    ],
    title: "workspace.dataMapping.titles.sourceData",
    subTitle: "workspace.dataMapping.subTitles.customerDataAdditional",
    description: "workspace.dataMapping.additionalDesc",
    continueRoute: `../${WORKSPACE_ROUTES.CUSTOMER_DATA_REVIEW}`,
    backRoute: `../${WORKSPACE_ROUTES.CUSTOMER_DATA_PRODUCTS}`,
    component: DataMappingContent,
  },
  [WORKSPACE_ROUTES.CUSTOMER_DATA_REVIEW]: {
    step: 4,
    title: "workspace.dataMapping.titles.sourceData",
    subTitle: "workspace.dataMapping.subTitles.customerDataAdditional",
    description: "workspace.dataMapping.additionalDesc",
    component: Review,
    action: actions,
  },
};

const allRows = Object.entries(workspaceConfigs).flatMap(([key, item]) => {
  if ("rows" in item) {
    return item.rows.map((row) => {
      return { ...row, step: item.step, stepURL: `../${key}` };
    });
  }
  return [];
});
export const rowDictionary: RowType[] = [];
let index = 0;
for (const row of allRows) {
  if ("multiple" in row && "max" in row) {
    for (let i = 1; i <= (row.max as number); i++) {
      rowDictionary.push({
        ...row,
        order: index++,
        attribute: row.attribute.replace("1", "" + i),
        attributeLabel: row.attributeLabel.replace("1", "" + i),
      });
    }
  } else {
    rowDictionary.push({
      ...row,
      order: index++,
    });
  }
}
export function getLabelFromAttrHelper(attr: string) {
  const row = (rowDictionary as RowType[]).find(
    (item) => item.attribute === attr
  );
  return row?.attributeLabel || attr;
}
export function getRowFromAttrHelper(attr: string) {
  return (rowDictionary as RowType[]).find((item) => item.attribute === attr);
}

export function buildReviewRows(mappingResult: { [key: string]: string }) {
  const result = [];
  const sectionNames = [
    "Customer Data",
    "Values & Dates",
    "Product",
    "Additional",
  ];
  let sectionIndex = 0;
  for (const row of rowDictionary) {
    const newRow = { ...row };
    if (mappingResult[row.attribute] || row.isRequired) {
      if ("step" in newRow && newRow.step === sectionIndex) {
        newRow.sectionName = sectionNames[sectionIndex++];
      }
      result.push(newRow);
    }
  }
  return result;
}
