import { createAppThunk } from "common/appThunk";
import { reportClient } from "common/client/reportClient";
import { AnalysisType, ReportMeasurement, ReportView } from "common/constants";
import lodashSortBy from "lodash/sortBy";
import { ApiErrorModel } from "models/apiErrorModel";
import { Paging } from "models/collection";
import { KeyValue } from "models/common";
import { FileModel } from "models/fileModel";
import {
  ARRCohortReport,
  CARRCohortReport,
  CustomerResponse,
  CustomersByChangeCategoryResponse,
  FiscalMonth,
  RevenueByChangeCategoryResponse,
  RevenueByCustomer,
  RevenueByMonth,
  SegCustResponse,
  SegProdResponse,
} from "models/report";
import moment from "moment";
import {
  ReportData,
  ReportSettings,
  defaultReportFilters,
  defaultReportSettings,
} from "slices/reportSlice";
import {
  getMappingRecord,
  getSegmentations,
  hasFilteredOutAll,
} from "utils/report";

function checkShouldFilter(
  customerSegmentKey: string | undefined,
  productKey: string | undefined,
  customer: number[] | undefined,
  byCustomerSegmentKeys: { [key: string]: string },
  byProductKeys: { [key: string]: string },
  productFilters: { [segmentKeys: string]: number[] } | undefined,
  customerSegmentFilters: { [segmentKeys: string]: number[] } | undefined,
  measurement: ReportMeasurement | undefined
) {
  const filters: {
    customerType?: string;
    productType?: string;
    customers?: string;
    byCustomerSegmentKeys?: { [key: string]: string };
    byProductKeys?: { [key: string]: string };
    chartMeasure?: ReportMeasurement;
  } = {
    customerType: customerSegmentKey,
    productType: productKey,
    customers: customer ? customer.join(",") : undefined,
    byCustomerSegmentKeys,
    byProductKeys,
    chartMeasure: measurement,
  };
  if (customerSegmentKey === "") delete filters.customerType;
  if (productKey === "") delete filters.productType;
  if (!customer) delete filters.customers;
  if (productFilters && Object.keys(productFilters).length === 0) {
    delete filters.byProductKeys;
  }
  if (
    customerSegmentFilters &&
    Object.keys(customerSegmentFilters).length === 0
  ) {
    delete filters.byCustomerSegmentKeys;
  }
  return filters;
}

function computeSegmentationFiltersPayload(segmentationFilters: {
  [segmentKeys: string]: number[];
}) {
  const segmentKeys: { [key: string]: string } = {};

  for (const [key, values] of Object.entries(segmentationFilters)) {
    const dataKey = `${key.replaceAll(" ", "")}Key`;
    segmentKeys[dataKey] = values.join(",");
  }

  return segmentKeys;
}

export async function getRevenueReport<ResponseData = unknown>(
  view: ReportView,
  fileId: string,
  customerSegmentKey?: string,
  productKey?: string,
  customer?: number[],
  customerSegmentFilters?: { [segmentKeys: string]: number[] },
  productFilters?: { [segmentKeys: string]: number[] },
  chartMeasure?: ReportMeasurement,
  initialFetch: boolean = false
): Promise<ResponseData[]> {
  if (!customerSegmentKey && !productKey) {
    return [];
  }
  if (
    !initialFetch &&
    hasFilteredOutAll(
      customerSegmentFilters || {},
      productFilters || {},
      customer
    )
  ) {
    return [];
  }
  const byCustomerSegmentKeys = computeSegmentationFiltersPayload(
    customerSegmentFilters || {}
  );
  const byProductKeys = computeSegmentationFiltersPayload(productFilters || {});

  const filters: {
    customerType?: string;
    productType?: string;
    customers?: string;
    byCustomerSegmentKeys?: { [key: string]: string };
    byProductKeys?: { [key: string]: string };
    chartMeasure?: ReportMeasurement;
  } = checkShouldFilter(
    customerSegmentKey,
    productKey,
    customer,
    byCustomerSegmentKeys,
    byProductKeys,
    productFilters,
    customerSegmentFilters,
    chartMeasure
  );

  const { data: response } = await reportClient.post("report", {
    view,
    fileId,
    filters,
  });
  return response.data;
}

function buildFilterKeyList(filteredSegments: {
  [segment: string]: KeyValue[];
}) {
  const segmentKeys: { [key: string]: string } = {};

  for (const [key, values] of Object.entries(filteredSegments)) {
    const dataKey = `${key.replaceAll(" ", "")}Key`;
    if (values.length > 0) {
      segmentKeys[dataKey] = values.map((x) => x.key).join(",");
    }
  }

  return segmentKeys;
}

function buildFilterPayload(
  type?: ReportView | null,
  segmentKey?: string | null,
  reportSettings?: ReportSettings
) {
  const filters: {
    customerType?: string | null;
    productType?: string | null;
    chartMeasure: number;
    customers: string | undefined;
    byProductKeys: { [segment: string]: string } | undefined;
    byCustomerSegmentKeys: { [segment: string]: string } | undefined;
    month?: string | undefined;
    countCustomer?: boolean;
  } = {
    customerType: segmentKey,
    productType: segmentKey,
    chartMeasure: reportSettings?.measurement as number,
    customers: reportSettings?.filters?.customers?.map((x) => x.key).join(","),
    byProductKeys: buildFilterKeyList(
      reportSettings?.filters?.segmentProducts || {}
    ),
    byCustomerSegmentKeys: buildFilterKeyList(
      reportSettings?.filters?.segmentCustomers || {}
    ),
    countCustomer:
      reportSettings?.chartSettings["customer-cohorts"]?.show === "count",
  };

  if (type === ReportView.CustomerType) delete filters.productType;
  if (type === ReportView.TopCustomerType) delete filters.productType;
  if (type === ReportView.ProductType) delete filters.customerType;
  if (type === ReportView.TopProductType) delete filters.customerType;
  if (segmentKey === "") {
    delete filters.productType;
    delete filters.customerType;
  }

  const monthStr = reportSettings?.chartSettings["top-customers"]?.month;
  if (
    !monthStr &&
    (type === ReportView.TopCustomerType || type === ReportView.TopProductType)
  ) {
    const month = moment(monthStr).endOf("month");
    filters.month = month.format("YYYY-MM-DDTHH:mm:ss[Z]");
  }

  const countCustomer = reportSettings?.chartSettings["customer-cohorts"]?.show;
  if (countCustomer === "count" && type === ReportView.CohortByTenurePeriod) {
    filters.countCustomer = countCustomer === "count";
  }

  return filters;
}

async function getReportDataRevenueBy<ResponseData = unknown>(
  fileId: string,
  reportSettings: ReportSettings,
  view?: ReportView | null,
  segmentKey?: string | null
): Promise<ResponseData[]> {
  const filters = buildFilterPayload(view, segmentKey, reportSettings);

  const { data: response } = await reportClient.post("report", {
    view,
    fileId,
    filters: {
      ...filters,
      threshold: reportSettings.params.reactivationThreshold,
    },
  });
  return response.data;
}

async function getReportDataRevenueByCategory(
  fileId: string,
  reportSettings: ReportSettings
): Promise<RevenueByChangeCategoryResponse[]> {
  const filters = buildFilterPayload(undefined, undefined, reportSettings);

  const { data: response } = await reportClient.post("report/chart/revenue", {
    fileId,
    filters: {
      ...filters,
      customerLevel: reportSettings.params.customerLevel,
      monthlyRevenue: reportSettings.params.analysisType,
      threshold: reportSettings.params.reactivationThreshold,
    },
  });
  return response.data;
}

async function getReportDataCustomerByCategory(
  fileId: string,
  reportSettings: ReportSettings
): Promise<CustomersByChangeCategoryResponse[]> {
  const filters = buildFilterPayload(undefined, undefined, reportSettings);

  const { data: response } = await reportClient.post(
    "report/chart/customer-change-category",
    {
      fileId,
      filters: {
        ...filters,
        monthlyRevenue: reportSettings.params.analysisType,
        threshold: reportSettings.params.reactivationThreshold,
      },
    }
  );
  return response.data;
}

async function fetchReportDataAsync(file: FileModel) {
  const responses = await Promise.all([
    reportClient.get(`report/customers?fileId=${file.id}`),
    reportClient.get(`report/product-types?fileId=${file.id}`),
    reportClient.get(`report/customer-segments?fileId=${file.id}`),
    reportClient.get(`report/fiscal-years?fileId=${file.id}`),
  ]);

  const mapping = getMappingRecord(file.mapping ?? []);
  const customers = (responses[0].data.data as CustomerResponse[]).map((x) => ({
    key: x.customerkey,
    value: x.customer,
  }));
  const segProducts = responses[1].data.data as SegProdResponse[];
  const segCustomers = responses[2].data.data as SegCustResponse[];
  const months = responses[3].data.data as FiscalMonth[];

  const grpSegCustomers = (
    getSegmentations(segCustomers, mapping) as {
      segmentName: string;
      data: SegCustResponse[];
    }[]
  ).map((x) => ({
    segment: x.segmentName,
    data: x.data.map((y) => ({
      key: y.CUSTOMERSEGMENTKEY,
      value: y.VALUE,
    })),
  }));
  const grpSegProducts = (
    getSegmentations(segProducts, mapping) as {
      segmentName: string;
      data: SegProdResponse[];
    }[]
  ).map((x) => ({
    segment: x.segmentName,
    data: x.data.map((y) => ({
      key: y.PRODUCTKEY,
      value: y.VALUE,
    })),
  }));

  return {
    mapping,
    customers,
    grpSegCustomers,
    grpSegProducts,
    months,
  };
}

export const getReportData = createAppThunk<
  { reportData: ReportData; reportSettings: ReportSettings },
  FileModel
>("report/filterData", async (file) => {
  try {
    const {
      mapping,
      customers,
      grpSegCustomers: segmentCustomers,
      grpSegProducts: segmentProducts,
      months,
    } = await fetchReportDataAsync(file);

    const current = moment(new Date()).format("YYYY-MM-01");
    let minDate = months.at(0)?.Month ?? current;
    let maxDate = months.at(-1)?.Month ?? current;
    minDate = moment(minDate).endOf("M").format("YYYY-MM-DD");
    maxDate = moment(maxDate).endOf("M").format("YYYY-MM-DD");

    const reportData: ReportData = {
      isLoading: false,
      file,
      mapping,
      months,
      customers,
      segmentCustomers,
      segmentProducts,
      minDate,
      maxDate,
    };
    const reportSettings: ReportSettings = {
      ...defaultReportSettings,
      filters: {
        ...defaultReportFilters,
        minDate,
        maxDate,
      },
    };
    return { reportData, reportSettings };
  } catch (error) {
    if ((error as ApiErrorModel).errorCode) {
      throw (error as ApiErrorModel).errorCode;
    }
    throw error;
  }
});

export const getRollForwardChart = createAppThunk<
  RevenueByChangeCategoryResponse[],
  void
>("chart/roll-forward", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const chartData = state.report.chartData["roll-forward"];
  const reportSettings = state.report.currentSettings[state.report.dashboardId];

  if (chartData.data === undefined || chartData.shouldFetch) {
    const response = await getReportDataRevenueByCategory(
      state.report.reportData.file!.id,
      reportSettings
    );

    return lodashSortBy(response, (x) => x.Month);
  }

  return chartData.data;
});

export const getRevenueByPeriodChart = createAppThunk<
  RevenueByMonth[],
  { view?: ReportView | null; segmentKey?: string | null }
>("chart/revenue-by-period", async (params, thunkApi) => {
  const state = thunkApi.getState();
  const chartData = state.report.chartData["revenue-by-period"];
  const reportSettings = state.report.currentSettings[state.report.dashboardId];

  if (chartData.data === undefined || chartData.shouldFetch) {
    const response = await getReportDataRevenueBy<RevenueByMonth>(
      state.report.reportData.file!.id,
      reportSettings,
      params.view ?? ReportView.CustomerType,
      params.segmentKey
    );

    return lodashSortBy(response, (x) => x.Month);
  }

  return chartData.data;
});

export const getCountChangeChart = createAppThunk<
  CustomersByChangeCategoryResponse[],
  void
>("chart/count-change", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const chartData = state.report.chartData["count-change"];
  const reportSettings = state.report.currentSettings[state.report.dashboardId];

  if (chartData.data === undefined || chartData.shouldFetch) {
    const response = await getReportDataCustomerByCategory(
      state.report.reportData.file!.id,
      reportSettings
    );

    return lodashSortBy(response, (x) => x.Month);
  }

  return chartData.data;
});

export const getTopCustomerChart = createAppThunk<
  RevenueByCustomer[],
  { view?: ReportView | null; segmentKey?: string | null }
>("chart/top-customers", async (params, thunkApi) => {
  const state = thunkApi.getState();
  const chartData = state.report.chartData["top-customers"];
  const reportSettings = state.report.currentSettings[state.report.dashboardId];

  if (chartData.data === undefined || chartData.shouldFetch) {
    const response = await getReportDataRevenueBy<RevenueByCustomer>(
      state.report.reportData.file!.id,
      reportSettings,
      params.view ?? ReportView.TopCustomerType,
      params.segmentKey
    );

    return lodashSortBy(response, (x) => x.Month);
  }

  return chartData.data;
});

export const getCustomerCohortChart = createAppThunk<
  (ARRCohortReport | CARRCohortReport)[],
  void
>("chart/customer-cohorts", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const chartData = state.report.chartData["customer-cohorts"];
  const reportSettings = state.report.currentSettings[state.report.dashboardId];

  if (chartData.data === undefined || chartData.shouldFetch) {
    const response = await getReportDataRevenueBy<
      ARRCohortReport | CARRCohortReport
    >(
      state.report.reportData.file!.id,
      reportSettings,
      ReportView.CohortByTenurePeriod,
      ""
    );

    return lodashSortBy(response, (x) => x.Month);
  }

  return chartData.data;
});

export const getRevenueByCategoryReport = async (
  fileId: string,
  measurement: ReportMeasurement,
  analysisType: AnalysisType,
  threshold: number,
  customers?: number[],
  customerSegmentFilters?: { [segmentKeys: string]: number[] }
): Promise<RevenueByChangeCategoryResponse[]> => {
  if (customers && customers.length === 0) {
    return [];
  }
  if (
    customerSegmentFilters &&
    Object.values(customerSegmentFilters).some(
      (segment) => segment.length === 0
    )
  ) {
    return [];
  }
  const byCustomerSegmentKeys = computeSegmentationFiltersPayload(
    customerSegmentFilters || {}
  );

  const { data: response } = await reportClient.post("report/chart/revenue", {
    fileId,
    filters: {
      chartMeasure: measurement,
      monthlyRevenue: analysisType,
      threshold,
      customers: customers ? customers.join(",") : undefined,
      byCustomerSegmentKeys: customerSegmentFilters
        ? byCustomerSegmentKeys
        : undefined,
    },
  });
  return response.data;
};

export const getCustomersByCategoryReport = async (
  fileId: string,
  measurement: ReportMeasurement,
  analysisType: AnalysisType,
  threshold: number,
  customers?: number[],
  customerSegmentFilters?: { [segmentKeys: string]: number[] }
): Promise<CustomersByChangeCategoryResponse[]> => {
  if (customers && customers.length === 0) {
    return [];
  }
  if (
    customerSegmentFilters &&
    Object.values(customerSegmentFilters).some(
      (segment) => segment.length === 0
    )
  ) {
    return [];
  }
  const byCustomerSegmentKeys = computeSegmentationFiltersPayload(
    customerSegmentFilters || {}
  );

  const { data: response } = await reportClient.post(
    "report/chart/customer-change-category",
    {
      fileId,
      filters: {
        chartMeasure: measurement,
        monthlyRevenue: analysisType,
        threshold,
        customers: customers ? customers.join(",") : undefined,
        byCustomerSegmentKeys: customerSegmentFilters
          ? byCustomerSegmentKeys
          : undefined,
      },
    }
  );
  return response.data;
};

const categoryMapping: { [key: string]: string } = {
  "Ending/Beginning Revenue Credits": "BEGINNING/ENDING REVENUE CREDITS",
  "Customer Reactivation": "CUSTOMER REACTIVATION",
  Downsell: "DOWNSELL",
  Upsell: "UPSELL",
  "Lost Customer": "LOST CUSTOMER",
  "New Customer": "NEW CUSTOMER",
  "Cust Segment Migration": "CUSTOMER SEGMENT MIGRATION",
};

export async function getRevenueChangeDrilldown(
  fileId: string,
  month: string,
  category: string,
  pagingFilter: Paging,
  reportSettings: ReportSettings
) {
  const filters = buildFilterPayload(undefined, undefined, reportSettings);

  const { data: response } = await reportClient.post(
    "report/chart/revenue/drill-down",
    {
      fileId,
      filters: {
        ...filters,
        monthlyRevenue: reportSettings.params.analysisType,
        threshold: reportSettings.params.reactivationThreshold,
        month: new Date(
          Date.UTC(
            Number.parseInt(month.split("-")[0]),
            Number.parseInt(month.split("-")[1]) - 1,
            1
          )
        ),
        category: categoryMapping[category],
        pagingFilter,
      },
    }
  );
  return response.data;
}
