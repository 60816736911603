import {
  GridViewOutlined,
  MessageOutlined,
  PeopleOutline,
  Person4Outlined,
  WebOutlined,
} from "@mui/icons-material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListSubheader from "@mui/material/ListSubheader";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import i18n from "common/i18n";
import { useSelector } from "common/store";
import { ROUTE_PATHS } from "common/constants";
import { Role } from "models/team";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { selectRoleByTeam } from "slices/teamSlice";

interface TeamSettingNavBarProps {
  team: string;
  section: string;
}

const navItems = [
  {
    icon: <Person4Outlined />,
    name: i18n.t("TeamSettingNavBar_ProfileSettings"),
    url: "",
    isSubheader: false,
  },
  {
    icon: <GridViewOutlined />,
    name: i18n.t("TeamSettingNavBar_MyWorkspaces"),
    url: "",
    isSubheader: false,
  },
  {
    icon: <MessageOutlined />,
    name: i18n.t("TeamSettingNavBar_Notifications"),
    url: "",
    isSubheader: false,
  },
  {
    name: i18n.t("TeamSettingNavBar_Account"),
    isSubheader: true,
  },
  {
    icon: <WebOutlined />,
    name: i18n.t("TeamSettingNavBar_TeamProfile"),
    url: "",
    isSubheader: false,
  },
  {
    name: i18n.t("TeamSettingNavBar_UserManagement"),
    isSubheader: true,
  },
  {
    icon: <PeopleOutline />,
    name: i18n.t("TeamSettingNavBar_TeamUsers"),
    url: ROUTE_PATHS.TEAMS_SETTINGS_USERS,
    isSubheader: false,
  },
];

export default function TeamSettingNavBar(props: TeamSettingNavBarProps) {
  const navigate = useNavigate();
  const params = useParams();
  const path = useLocation().pathname.split("/").at(-1) || "__none__";
  const role = useSelector(selectRoleByTeam);
  const { isSystemAdmin } = useSelector((state) => state.team);
  const navItemsByRole = navItems.filter((x) => {
    if (isSystemAdmin || role === Role.Admin) {
      return true;
    }
    return (
      x.name !== i18n.t("TeamSettingNavBar_TeamUsers") &&
      x.name !== i18n.t("TeamSettingNavBar_UserManagement")
    );
  });

  return (
    <Paper sx={{ padding: 3, display: "flex", flexDirection: "column" }}>
      <Box sx={{ padding: 2 }}>
        <Typography variant="h6">{props.team}</Typography>
        <Typography variant="h6">{props.section}</Typography>
      </Box>
      <Divider />
      <List sx={{ padding: 2 }}>
        {navItemsByRole.map((x, i) => {
          return x.isSubheader ? (
            <ListSubheader sx={{ letterSpacing: "0.1px" }} key={i}>
              {x.name}
            </ListSubheader>
          ) : (
            <ListItemButton
              selected={path === x.url}
              key={i}
              onClick={() =>
                navigate(
                  `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.TEAMS_SETTINGS}/${x.url}`
                )
              }
            >
              <ListItemIcon>{x.icon}</ListItemIcon>
              {x.name}
            </ListItemButton>
          );
        })}
      </List>
    </Paper>
  );
}
