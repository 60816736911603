import { useCallback, useEffect, useState } from "react";

interface UseFetchProps<Payload, Response> {
  fn: (payload: Payload) => Promise<Response>;
  /**
   * If payload are passed into the hook, it will trigger the prop fn automatically with the payload
   * if you want to trigger the fn prop automatically without payload, call hook with payload = true
   */
  payload?: Payload;
}

interface UseFetchResponse<Payload, Response, ErrorModel> {
  data?: Response;
  isLoading: boolean;
  error?: ErrorModel;
  execute: (payload: Payload) => Promise<Response | undefined>;
  clearError: () => void;
}

export default function useFetch<Payload, Response, ErrorModel = unknown>(
  { fn, payload }: UseFetchProps<Payload, Response>,
  dependencies: unknown[] = []
): UseFetchResponse<Payload, Response, ErrorModel> {
  const [data, setData] = useState<Response>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ErrorModel>();

  const execute = useCallback(
    async (fetchPayload: Payload) => {
      setIsLoading(true);
      try {
        const result = await fn(fetchPayload);

        setData(result);
        setError(undefined);
        setIsLoading(false);
        return result;
      } catch (error_) {
        setError(error_ as ErrorModel);
        setIsLoading(false);
      }
    },
    [data, ...dependencies]
  );

  const clearError = useCallback(() => {
    setError(undefined);
  }, []);

  useEffect(() => {
    if (payload !== undefined) {
      execute(payload);
    }
  }, [...dependencies]);

  return {
    data,
    isLoading,
    error,
    execute,
    clearError,
  };
}
