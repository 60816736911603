import { CloseOutlined } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useDispatch, useReportSelector } from "common/store";
import DatePicker from "components/Inputs/DatePicker";
import FilterMultipleSelect from "components/Inputs/FilterMultipleSelect";
import { KeyValue } from "models/common";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ReportFilters,
  defaultReportFilters,
  setReportFilters,
} from "slices/reportSlice";
import {
  getDatePickerLabel,
  getDatePickerType,
  getFiscalMonth,
  getLastMonthFromDataset,
  getSourceColumnName,
} from "utils/report";

import DashboardFilterSegment from "./DashboardFilterSegment";

interface Props {
  onClose: () => void;
}

export default function DashboardFilter(props: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { reportData, reportSettings } = useReportSelector();

  const [selectedFilter, setSelectedFilter] =
    useState<ReportFilters>(defaultReportFilters);

  function handleOnCustomersSelected(selected: KeyValue[]): void {
    setSelectedFilter((old) => {
      return { ...old, customers: selected };
    });
  }

  function handleOnSegmentCustomersSelected(
    segment: string,
    selected: KeyValue[]
  ): void {
    setSelectedFilter((old) => {
      return {
        ...old,
        segmentCustomers: {
          ...old.segmentCustomers,
          [segment]: selected,
        },
      };
    });
  }

  function handleOnSegmentProductsSelected(
    segment: string,
    selected: KeyValue[]
  ): void {
    setSelectedFilter((old) => {
      return {
        ...old,
        segmentProducts: {
          ...old.segmentProducts,
          [segment]: selected,
        },
      };
    });
  }

  function handleOnApplyFilterClick(): void {
    const now = moment(new Date()).format("YYYY-MM-DD");
    if (selectedFilter.maxDate && !selectedFilter.minDate)
      selectedFilter.minDate = now;
    if (selectedFilter.minDate && !selectedFilter.maxDate)
      selectedFilter.maxDate = now;
    dispatch(setReportFilters(selectedFilter));
    props.onClose();
  }

  function handleOnStartDateChanged(date: moment.Moment | null): void {
    if (!date) return;
    const lastDate = getLastMonthFromDataset(
      date,
      reportData!.months!,
      reportSettings.params.revenueType
    );
    const lastDateStr = lastDate?.format("YYYY-MM-DD");
    if (!lastDateStr) return;

    if (lastDateStr > selectedFilter.maxDate!) {
      setSelectedFilter((y) => ({ ...y, minDate: selectedFilter.maxDate }));
    } else {
      setSelectedFilter((y) => ({ ...y, minDate: lastDateStr }));
    }
  }

  function handleOnEndDateChanged(date: moment.Moment | null): void {
    if (!date) return;
    const lastDate = getLastMonthFromDataset(
      date,
      reportData!.months!,
      reportSettings.params.revenueType
    );
    const lastDateStr = lastDate?.format("YYYY-MM-DD");
    if (!lastDateStr) return;

    if (lastDateStr < selectedFilter.minDate!) {
      setSelectedFilter((y) => ({ ...y, maxDate: selectedFilter.minDate }));
    } else {
      setSelectedFilter((y) => ({ ...y, maxDate: lastDateStr }));
    }
  }

  useEffect(() => {
    setSelectedFilter(reportSettings.filters);
  }, [reportSettings]);

  return (
    <Stack width={360} minHeight="100vh" justifyContent="space-between">
      <CardHeader
        action={
          <IconButton onClick={props.onClose}>
            <CloseOutlined />
          </IconButton>
        }
        title={t("Dashboard.Filters.Filters")}
        sx={{ backgroundColor: "#29B6F614" }}
      />
      <Stack flex={1} overflow="auto" padding={2} spacing={2}>
        <FormLabel sx={{ color: "var(--text-secondary)" }}>
          {t("Dashboard.Filters.CustomerInfo")}
        </FormLabel>
        <FilterMultipleSelect
          title={
            getSourceColumnName(reportData.mapping, "customer") ||
            t("Dashboard.Filters.Customer")
          }
          value={selectedFilter.customers}
          options={reportData.customers || []}
          onSelect={handleOnCustomersSelected}
        />
        <DashboardFilterSegment
          type="segmentCustomers"
          title={t("Dashboard.Filters.CustomerSegments")}
          onSelect={handleOnSegmentCustomersSelected}
        />
        <FormLabel sx={{ color: "var(--text-secondary)" }}>
          {t("Dashboard.Filters.ProductInfo")}
        </FormLabel>
        <DashboardFilterSegment
          type="segmentProducts"
          title={t("Dashboard.Filters.Products")}
          onSelect={handleOnSegmentProductsSelected}
        />
        <FormLabel sx={{ color: "var(--text-secondary)" }}>
          {t("Dashboard.Filters.ValuesDates")}
        </FormLabel>
        <Box display="flex" alignItems="center">
          <DatePicker
            type={getDatePickerType(reportSettings.params.revenueType)}
            label={t("Dashboard.Filters.StartDate", {
              date: getDatePickerLabel(reportSettings.params.revenueType),
            })}
            value={moment(selectedFilter.minDate)}
            fiscalMonth={getFiscalMonth(
              reportData.mapping,
              reportSettings.params.revenueType
            )}
            onChange={handleOnStartDateChanged}
            minDate={moment(reportData.minDate)}
            maxDate={moment(selectedFilter.maxDate)}
          />
          <Box paddingX={2}>—</Box>
          <DatePicker
            type={getDatePickerType(reportSettings.params.revenueType)}
            label={t("Dashboard.Filters.EndDate", {
              date: getDatePickerLabel(reportSettings.params.revenueType),
            })}
            value={moment(selectedFilter.maxDate)}
            fiscalMonth={getFiscalMonth(
              reportData.mapping,
              reportSettings.params.revenueType
            )}
            onChange={handleOnEndDateChanged}
            minDate={moment(selectedFilter.minDate)}
            maxDate={moment(reportData.maxDate)}
          />
        </Box>
      </Stack>
      <Divider />
      <Button
        size="large"
        color="secondary"
        variant="outlined"
        sx={{ borderRadius: 120, margin: 2 }}
        onClick={handleOnApplyFilterClick}
      >
        {t("Dashboard.Filters.ApplyFilters")}
      </Button>
    </Stack>
  );
}
