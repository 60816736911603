import { AutorenewOutlined } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { FileStatus } from "common/constants";
import { useTranslation } from "react-i18next";

export function ReportStatus({ status }: { status: FileStatus }) {
  const isReady = status === FileStatus.ReportGenerated;
  const { t } = useTranslation();

  if (!status) return null;
  return (
    <Chip
      sx={{
        width: "fit-content",
        color: isReady ? "success.main" : "warning.main",
        borderColor: isReady ? "success.main" : "warning.main",
      }}
      label={isReady ? t("ReportStatus.Ready") : t("ReportStatus.InProgress")}
      icon={<AutorenewOutlined color={isReady ? "success" : "warning"} />}
      variant="outlined"
    />
  );
}
