import React, { forwardRef, useRef, useState } from "react";
import {
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
  ArrowForward,
  DeleteOutline,
} from "@mui/icons-material";
import {
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Box,
  Button,
  Collapse,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDataMappingCtx } from "containers/Workspace/DataMapping/DataMappingContext";
import { getLabelFromAttrHelper } from "containers/Workspace/config";

export type RowType = {
  isRequiredIndex?: boolean;
  isRequired?: boolean;
  sectionName?: string;
  isOptionalIndex?: boolean;
  attribute: string;
  attributeLabel?: string;
  order?: number;
  step?: number;
  stepURL?: string;
  example: string;
  dataType: string;
};

interface MappingMenuItemProp {
  key: string;
  value: string;
  children: React.ReactNode;
  row: RowType;
  handleClose: () => void;
}
const MappingMenuItem = forwardRef<HTMLLIElement, MappingMenuItemProp>(
  function MappingMenuItem(props, ref) {
    const { setOpenModal, mappingResult } = useDataMappingCtx();
    const { t } = useTranslation();
    const currentMappedTo = Object.entries(mappingResult).find(([, value]) => {
      return value === props.children;
    })?.[0];
    const [expanded, setExpanded] = useState(false);
    return (
      <MenuItem
        {...props}
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
        }}
        sx={{
          p: 0,
          cursor: "default",
          "&:hover": {
            background: "#FFF",
          },
        }}
      >
        <Stack sx={{ width: "100%" }}>
          <Stack
            direction="row"
            sx={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              p: "8px 16px",
            }}
          >
            <Typography color="text.disabled">{props.children}</Typography>
            {expanded ? (
              <ArrowDropUpOutlined
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setExpanded(false);
                }}
              />
            ) : (
              <ArrowDropDownOutlined
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setExpanded(true);
                }}
              />
            )}
          </Stack>
          <Collapse in={expanded}>
            <Box
              sx={{
                width: "100%",
                background: "var(--grey-200, #EEE)",
                p: "8px 16px",
              }}
            >
              <Typography>
                Currently mapped:{" "}
                <em>{t(getLabelFromAttrHelper(currentMappedTo || ""))}</em>
              </Typography>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpenModal(
                    true,
                    {
                      row: props.row,
                      fieldFromFile: props.children as string,
                      currentMappedTo: currentMappedTo || "",
                    },
                    props.handleClose
                  );
                }}
              >
                Remap field
              </Button>
            </Box>
          </Collapse>
        </Stack>
      </MenuItem>
    );
  }
);
const fiscalStartMonthValues = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];
export function MappingSelect({
  row,
  sourceFields,
  mappingValues,
  invalid,
  onChange,
}: {
  row: RowType;
  sourceFields: string[];
  mappingValues: Record<string, string>;
  invalid?: boolean;
  onChange: (attribute: string, value: string) => void;
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const selectRef = useRef(null);
  const handleClose = () => {
    setOpen(false);
  };
  const isMappedField = (field: string) => {
    return Object.values(mappingValues).includes(field);
  };
  const selectItem = () => {
    if (row.attribute === "FiscalStartMonth") {
      return fiscalStartMonthValues.map((field) => (
        <MenuItem value={field.value} key={field.value}>
          {field.label}
        </MenuItem>
      ));
    }
    return sourceFields.map((field) =>
      isMappedField(field) ? (
        <MappingMenuItem
          key={field}
          value={field}
          row={row}
          handleClose={handleClose}
        >
          {field}
        </MappingMenuItem>
      ) : (
        <MenuItem value={field} key={field}>
          {field}
        </MenuItem>
      )
    );
  };
  return (
    <FormControl
      fullWidth
      size="small"
      sx={{
        color: "text.secondary",
      }}
    >
      <InputLabel id="source-field-label-id">
        {t("workspace.dataMapping.selectField")}
      </InputLabel>
      <Select
        ref={selectRef}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={handleClose}
        sx={{
          "& .MuiSelect-select": {
            color: invalid ? "error.main" : "#03A9F4",
          },
        }}
        labelId="source-field-label-id"
        id="source-field-id"
        label="Select field"
        value={mappingValues[row.attribute] || ""}
        onChange={(e: SelectChangeEvent) =>
          onChange(row.attribute, e.target.value)
        }
      >
        <MenuItem value="">
          <em>{t("workspace.dataMapping.none")}</em>
        </MenuItem>
        {selectItem()}
      </Select>
    </FormControl>
  );
}
export function SimpleMappingSelect({
  row,
  sourceFields,
  mappingValues,
  onChange,
}: {
  row: RowType;
  sourceFields: string[];
  mappingValues: Record<string, string>;
  onChange: (attribute: string, value: string) => void;
}) {
  const { t } = useTranslation();
  return (
    <FormControl
      fullWidth
      size="small"
      sx={{
        color: "text.secondary",
      }}
    >
      <InputLabel id="source-field-label-id">
        {t("workspace.dataMapping.selectField")}
      </InputLabel>
      <Select
        sx={{ "& .MuiSelect-select": { color: "#03A9F4" } }}
        labelId="source-field-label-id"
        id="source-field-id"
        label="Select field"
        value={mappingValues[row.attribute] || ""}
        onChange={(e: SelectChangeEvent) =>
          onChange(row.attribute, e.target.value)
        }
      >
        <MenuItem value="">
          <em>{t("workspace.dataMapping.none")}</em>
        </MenuItem>
        {sourceFields.map((field) => (
          <MenuItem value={field} key={field}>
            {field}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
export default function MappingTable({
  rows,
  sourceFields,
  mappingValues,
  onChange,
  onDelete,
  selectComponent = MappingSelect,
}: {
  rows: RowType[];
  sourceFields: string[];
  mappingValues: { [key: string]: string };
  onChange: (attr: string, val: string) => void;
  onDelete?: (index: number) => void;
  selectComponent?: React.ComponentType<{
    row: RowType;
    sourceFields: string[];
    mappingValues: Record<string, string>;
    onChange: (attribute: string, value: string) => void;
  }>;
}) {
  const { t } = useTranslation();
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              {t("workspace.dataMapping.mappingTableFields.attribute")}
            </TableCell>
            <TableCell>
              {t("workspace.dataMapping.mappingTableFields.sourceDataField")}
            </TableCell>
            <TableCell>
              {t("workspace.dataMapping.mappingTableFields.example")}
            </TableCell>
            <TableCell>
              {t("workspace.dataMapping.mappingTableFields.dataType")}
            </TableCell>
            {onDelete && rows.length > 1 && <TableCell></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <>
              {(row.isRequiredIndex || row.isOptionalIndex) && (
                <TableRow
                  key={row.isRequiredIndex ? "Required" : "Optional"}
                  sx={{
                    backgroundColor: row.isRequiredIndex
                      ? "var(--color-row-required)"
                      : "unset",
                    width: "100%",
                  }}
                >
                  <TableCell
                    sx={{
                      padding: 0,
                      border: "none",
                    }}
                  >
                    {(row.isRequiredIndex || row.isOptionalIndex) && (
                      <CardHeader
                        sx={{
                          margin: 0,
                          padding: 0,
                          paddingLeft: "16px",
                          height: "24px",
                        }}
                        title={
                          <Typography
                            variant="overline"
                            color={
                              row.isRequiredIndex
                                ? "primary.dark"
                                : "text.primary"
                            }
                            textTransform="uppercase"
                          >
                            {row.isRequiredIndex
                              ? t("workspace.dataMapping.required")
                              : t("workspace.dataMapping.optional")}
                          </Typography>
                        }
                      />
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                    }}
                  />
                  <TableCell
                    sx={{
                      border: "none",
                    }}
                  />
                  <TableCell
                    sx={{
                      border: "none",
                    }}
                  />
                </TableRow>
              )}
              <TableRow
                key={row.attribute}
                sx={{
                  backgroundColor: row.isRequired
                    ? "var(--color-row-required)"
                    : "unset",
                }}
              >
                <TableCell>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" color="text.secondary">
                      {t(row.attributeLabel || "attribute") || row.attribute}
                    </Typography>
                    <ArrowForward
                      sx={{
                        color: "action.active",
                        width: "24px",
                        height: "24px",
                      }}
                    />
                  </Stack>
                </TableCell>
                <TableCell>
                  <Stack
                    sx={{ minWidth: 250 }}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={2}
                  >
                    {React.createElement(selectComponent, {
                      row,
                      mappingValues,
                      sourceFields,
                      onChange,
                    })}
                    <ArrowForward
                      sx={{
                        color: "action.active",
                        width: "24px",
                        height: "24px",
                        visibility:
                          row.attribute === "FiscalStartMonth"
                            ? "hidden"
                            : "visible",
                      }}
                    />
                  </Stack>
                </TableCell>
                <TableCell sx={{ maxWidth: "140px", padding: "16px" }}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      visibility:
                        row.attribute === "FiscalStartMonth"
                          ? "hidden"
                          : "visible",
                    }}
                  >
                    {row.example}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        visibility:
                          row.attribute === "FiscalStartMonth"
                            ? "hidden"
                            : "visible",
                      }}
                    >
                      {t(row.dataType)}
                    </Typography>
                  </Stack>
                </TableCell>
                {onDelete && rows.length > 1 && (
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      onClick={() => onDelete(index)}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
