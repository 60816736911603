import extractIdFromPathname from "utils/extractIdFromPathname";
import { ROUTE_PATHS } from "common/constants";

import HttpClient from "./httpClient";

const apiVersion = process.env.REACT_APP_API_VERSION;
const baseUrl = process.env.REACT_APP_API_REPORT_BASEURL;

export const reportClient = new HttpClient(`${baseUrl}${apiVersion}`, () => {
  const workspaceId = window.location.pathname.split("/")[4] || "";
  return {
    teamId: extractIdFromPathname(ROUTE_PATHS.TEAMS),
    workspaceId,
  };
});
