import { MenuItem, styled } from "@mui/material";

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&": {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    color: theme.palette.text.primary,
  },
}));
