import { administrationClient } from "common/client/administrationClient";
import { FilterCollectionPayload } from "models/collection";
import { SignUpPayload } from "models/userModel";

export async function sendInvitations(emails: string[]) {
  const { data: response } = await administrationClient.post(
    "systemadmin/invite",
    { emails }
  );
  return response.data;
}

export async function superAdminSignUp(payload: SignUpPayload) {
  const { data: response } = await administrationClient.post(
    "systemadmin/signup",
    payload
  );
  return response.data;
}

export interface CreateTeamPayload {
  name: string;
  emails: string[];
  organization?: string;
  powerBIWorkspaceId?: string;
}

export interface RenameTeamPayload {
  newName: string;
}

export async function getTeams(body: FilterCollectionPayload) {
  const { data: response } = await administrationClient.post(
    "teams/search",
    body
  );

  return response.data;
}

export async function createTeam(body: CreateTeamPayload) {
  const { data: response } = await administrationClient.post("teams", body);

  return response.data;
}

export async function renameTeam(teamId: string, body: RenameTeamPayload) {
  const { data: response } = await administrationClient.put(
    `teams/${teamId}`,
    body
  );

  return response.data;
}

export async function deleteTeam(teamId: string) {
  const { data: response } = await administrationClient.delete(
    `teams/${teamId}`
  );

  return response.data;
}

export async function archiveTeam(teamId: string) {
  const { data: response } = await administrationClient.delete(
    `teams/${teamId}/archive`
  );

  return response.data;
}
