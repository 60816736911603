/* eslint-disable @typescript-eslint/no-shadow */
import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import React, { ReactNode, createContext, useCallback, useState } from "react";

interface ToastOptions {
  message: string | React.ReactNode;
  severity?: AlertColor;
  duration?: number;
}

interface ToastContextType {
  showSuccess: (options: ToastOptions) => void;
  showError: (options: ToastOptions) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

const ToastProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string | React.ReactNode>("");
  const [severity, setSeverity] = useState<AlertColor>("info");
  const [duration, setDuration] = useState(2000);

  const showSuccess = useCallback(
    ({ message, duration = 2000 }: ToastOptions) => {
      setMessage(message);
      setSeverity("success");
      setDuration(duration);
      setOpen(true);
    },
    []
  );

  const showError = useCallback(
    ({ message, duration = 5000 }: ToastOptions) => {
      setMessage(message);
      setSeverity("error");
      setDuration(duration);
      setOpen(true);
    },
    []
  );

  const hideToast = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <ToastContext.Provider value={{ showSuccess, showError }}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={duration}
        onClose={hideToast}
      >
        <Alert
          variant="filled"
          severity={severity}
          sx={{ maxWidth: "516px" }}
          iconMapping={{
            success: <CheckCircleOutline />,
            error: <ErrorOutline />,
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
};

export { ToastContext, ToastProvider };
