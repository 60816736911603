import { ErrorOutline } from "@mui/icons-material";
import {
  CardMedia,
  CardHeader,
  CardContent,
  CardActions,
  Card,
  Typography,
  Button,
} from "@mui/material";
import { ROUTE_PATHS } from "common/constants";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

export default function ImportFailed({
  error: { title, description },
  startOver,
}: {
  error: { title: string; description: string };
  startOver: () => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "24px",
        marginTop: "100px",
        width: "732px",
      }}
    >
      <CardMedia>
        <ErrorOutline sx={{ width: "120px", height: "120px" }} color="error" />
      </CardMedia>
      <CardHeader title={<Typography variant="h3">{title}</Typography>} />
      <CardContent sx={{ padding: "16px" }}>
        <Typography variant="h4" align="center">
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="outlined"
          sx={{ borderRadius: "120px" }}
          onClick={startOver}
        >
          {t("workspace.import.startOver")}
        </Button>
        <Button
          variant="text"
          sx={{ borderRadius: "120px" }}
          onClick={() => {
            navigate(
              `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}`
            );
          }}
        >
          {t("workspace.import.failedSkip")}
        </Button>
      </CardActions>
    </Card>
  );
}
