import { Configuration, LogLevel } from "@azure/msal-browser";
import { ROUTE_PATHS } from "common/constants";

const CLIENT_ID = process.env.REACT_APP_MSAL_CLIENT_ID || "";
const TENANT_ID = process.env.REACT_APP_MSAL_TENANT_ID || "";
const APP_BASE_URL = process.env.REACT_APP_BASE_URL || "";
const SCOPES: string[] = [
  `api://${process.env.REACT_APP_MSAL_CLIENT_ID}/Application.Access`,
];

export const msalConfig: Configuration = {
  auth: {
    clientId: CLIENT_ID,
    redirectUri: `${APP_BASE_URL}/${ROUTE_PATHS.LOGIN_SUCCESS}`,
    navigateToLoginRequestUrl: false, // to avoid redirect back to original login page
    authority: `https://login.microsoftonline.com/${TENANT_ID}`,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ) => {
        if (containsPii) return;
        switch (level) {
          case LogLevel.Error: {
            console.error(message);
            return;
          }
          case LogLevel.Info: {
            console.info(message);
            return;
          }
          case LogLevel.Verbose: {
            console.debug(message);
            return;
          }
          case LogLevel.Warning: {
            console.warn(message);
          }
        }
      },
    },
  },
};

export const loginRequest = { scopes: SCOPES };
