import {
  ArrowBack,
  FilterAltOutlined,
  FilterCenterFocusOutlined,
} from "@mui/icons-material";
import Box from "@mui/material/Box";
import lodashUniq from "lodash/uniq";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ROUTES, ROUTE_PATHS } from "common/constants";
import i18n from "common/i18n";
import { useReportSelector } from "common/store";
import AppBar from "components/AppBar";
import PatternCard from "components/Card/PatternCard";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { ChartCacheData, setChartShouldFetch } from "slices/reportSlice";

const backToPage = "RRI Report";

const navItems = [
  {
    name: i18n.t("DashboardSettings.NavBar.DataReporting"),
  },
  {
    icon: <FilterCenterFocusOutlined />,
    name: i18n.t("DashboardSettings.NavBar.GlobalParameters"),
    url: ROUTE_PATHS.REPORT_SETTINGS_PARAMETERS,
  },
  {
    icon: <FilterAltOutlined />,
    name: i18n.t("DashboardSettings.NavBar.GlobalFilters"),
    url: ROUTE_PATHS.REPORT_SETTINGS_FILTERS,
  },
];

function DashboardSettingNavBar({ path }: { path: string }) {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Paper sx={{ padding: 3, display: "flex", flexDirection: "column" }}>
      <Box sx={{ padding: 2 }}>
        <Typography variant="h6">
          {t("DashboardSettings.NavBar.Title")}
        </Typography>
        <Typography variant="body1">
          {t("DashboardSettings.NavBar.SubTitle")}
        </Typography>
      </Box>
      <Divider />
      <List sx={{ padding: 2 }}>
        {navItems.map((x, i) => {
          return x.url === undefined ? (
            <ListSubheader sx={{ letterSpacing: "0.1px" }} key={i}>
              {x.name}
            </ListSubheader>
          ) : (
            <ListItemButton
              key={i}
              selected={path === x.url}
              onClick={() =>
                navigate(
                  ROUTES.REPORT_SETTINGS(
                    params.id!,
                    params.wsId!,
                    params.dashboardId!
                  ) + `/${x.url}`
                )
              }
            >
              <ListItemIcon>{x.icon}</ListItemIcon>
              <ListItemText>{x.name}</ListItemText>
            </ListItemButton>
          );
        })}
      </List>
    </Paper>
  );
}

export default function DashboardSettings() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { t } = useTranslation();
  const { originalSettings, reportSettings } = useReportSelector();

  const path = useLocation().pathname.split("/").at(-1) || "__none__";
  const pageName = useMemo(() => {
    return navItems.find((x) => path === x.url)?.name;
  }, [path]);

  function handleOnBackClick(): void {
    const changedCharts: (keyof ChartCacheData)[] = [];

    if (
      originalSettings?.params.customerLevel !==
      reportSettings.params.customerLevel
    ) {
      changedCharts.push("roll-forward");
    }

    if (
      originalSettings?.params.reactivationThreshold !==
      reportSettings.params.reactivationThreshold
    ) {
      changedCharts.push("roll-forward", "count-change");
    }

    if (
      originalSettings?.params.analysisType !==
      reportSettings.params.analysisType
    ) {
      changedCharts.push("roll-forward", "count-change");
    }

    dispatch(setChartShouldFetch(lodashUniq(changedCharts)));

    navigate(ROUTES.REPORT(params.id!, params.wsId!, params.dashboardId!));
  }

  return (
    <Stack sx={{ minHeight: "100vh", backgroundColor: "var(--bg-color)" }}>
      <AppBar>
        <IconButton onClick={handleOnBackClick}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h6">
          {t("Common.BackTo", { page: backToPage })}
        </Typography>
      </AppBar>
      <Grid container spacing={3} padding={3}>
        <Grid size={3}>
          <DashboardSettingNavBar path={path} />
        </Grid>
        <Grid size={9}>
          <PatternCard
            size="medium"
            title={t("DashboardSettings.CardTitle", { name: pageName })}
          >
            <Outlet />
          </PatternCard>
        </Grid>
      </Grid>
    </Stack>
  );
}
