import { Add, DeleteOutlined, MoreHorizOutlined } from "@mui/icons-material";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import type { GridColDef } from "@mui/x-data-grid";
import DataGrid from "components/Table/DataGrid";
import useFetch from "utils/hook/useFetch";
import i18n from "common/i18n";
import LoadingButton from "components/Button/LoadingButton";
import IconMenu from "components/Menu/IconMenu";
import Modal from "components/Modal";
import RoleSelect from "components/RoleSelect";
import { Role } from "models/team";
import { WorkspaceAssignment, WorkspaceModel } from "models/workspace";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getUserWorkspace,
  inviteUserToWorkspaceBatch,
  removeUserFromWorkspace,
  updateUserRoleFromWorkspace,
} from "services/workspaceService";
import { useToast } from "utils/hook/useNotification";
import {
  FilterCollectionPayload,
  DEFAULT_PAYLOAD,
  Collection,
} from "models/collection";

interface WorkspacesAccessModalProps {
  open: boolean;
  userId: string;
  email: string;
  userName: string;
  onClose: () => void;
}

const getContextMenuOptions = (
  tab: WorkspaceAssignment,
  row: WorkspaceModel,
  onAddClick: (wsId: string, wsName: string, role: Role) => void,
  onUnassignClick: (wsId: string) => void,
  roles: {
    [wsId: string]: Role | null;
  }
) => {
  if (tab === WorkspaceAssignment.ASSIGNED) {
    return [
      {
        id: "remove",
        icon: <DeleteOutlined />,
        title: "Remove",
        color: "error.main",
        onClick: () => onUnassignClick(row.id),
      },
    ];
  }
  return [
    {
      id: "add",
      icon: <Add />,
      title: "Add",
      onClick: () =>
        onAddClick(row.id, row.name, roles[row.id] || Role.ReportViewer),
    },
  ];
};

const getColumns = (
  tab: WorkspaceAssignment,
  onAddClick: (wsId: string, wsName: string, role: Role) => void,
  onUnassignClick: (workspaceId: string) => void,
  roles: {
    [wsId: string]: Role | null;
  },
  onChangeRole: (wsId: string, wsName: string, role: Role) => void,
  shouldDisableEditRole: boolean,
  isLoading: boolean
): GridColDef<WorkspaceModel>[] => {
  return [
    {
      field: "Name",
      valueGetter: (_, row) => row.name,
      headerName: i18n.t("Common.Workspace"),
      renderCell: (params) => (
        <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
          <Typography
            variant="body2"
            color="primary"
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
      flex: 2,
    },
    {
      field: "Created",
      valueGetter: (_, row) => moment(row.createdAt).format("MM/DD/YY"),
      headerName: "Created",
      flex: 1,
    },
    {
      field: "Role",
      headerName: i18n.t("Common.Role"),
      valueGetter: (_, row) => row.id,
      renderCell: (params) => (
        <RoleSelect
          value={
            roles[params.value] ||
            (tab === WorkspaceAssignment.UNASSIGNED &&
              !isLoading &&
              Role.ReportViewer) ||
            null
          }
          onRoleChange={(role) =>
            onChangeRole(params.value, params.row.name, role)
          }
          disabled={shouldDisableEditRole}
        />
      ),
      flex: 1,
    },
    {
      field: "id",
      renderHeader: () => null,
      flex: 1,
      renderCell: ({ row }) => (
        <IconMenu
          icon={<MoreHorizOutlined />}
          options={getContextMenuOptions(
            tab,
            row,
            onAddClick,
            onUnassignClick,
            roles
          )}
        />
      ),
      align: "right",
      sortable: false,
    },
  ];
};

export default function WorkspacesAccessModal({
  open,
  userId,
  email,
  userName,
  onClose,
}: WorkspacesAccessModalProps) {
  const { t } = useTranslation();
  const { showSuccess, showError } = useToast();

  const [currentTab, setCurrentTab] = useState<WorkspaceAssignment>(
    WorkspaceAssignment.ASSIGNED
  );
  const [roles, setRole] = useState<{ [wsId: string]: Role | null }>({});
  const [invitedWs, setInvitedWs] = useState<{
    name: string;
    role: Role;
  } | null>(null);
  const [updatedRole, setUpdatedRole] = useState<{
    wsId: string;
    wsName: string;
    role: Role;
  } | null>(null);
  const [pagination, setPagination] = useState<FilterCollectionPayload>({
    ...DEFAULT_PAYLOAD,
  });

  const {
    data,
    isLoading: isGettingWorkspaces,
    execute: getWorkspaces,
  } = useFetch<FilterCollectionPayload, Collection<WorkspaceModel>>(
    {
      fn: (filter) => {
        return getUserWorkspace(userId, currentTab, filter);
      },
    },
    [userId, currentTab]
  );
  const {
    isLoading: isInviting,
    data: invitationResponse,
    error: invitationError,
    execute: invite,
  } = useFetch<{ workspaceId: string; role: Role; userEmail: string }, unknown>(
    {
      fn: ({ workspaceId, role, userEmail }) =>
        inviteUserToWorkspaceBatch([userEmail], [{ workspaceId, role }]),
    }
  );

  const {
    isLoading: isUnassigning,
    data: unassignResponse,
    error: unassignError,
    execute: unassign,
  } = useFetch<string, unknown>(
    {
      fn: (workspaceId) => removeUserFromWorkspace(userId, workspaceId),
    },
    [userId]
  );

  const {
    isLoading: isUpdatingRole,
    data: updateRoleResponse,
    error: updateRoleError,
    execute: executeUpdateRole,
  } = useFetch<{ wsId: string; role: Role }, unknown>(
    {
      fn: ({ wsId, role }) => updateUserRoleFromWorkspace(userId, wsId, role),
    },
    [userId]
  );

  useEffect(() => {
    userId && getWorkspaces(DEFAULT_PAYLOAD);
    setRole({});
  }, [currentTab, userId]);

  useEffect(() => {
    if (data?.data) {
      const fetchedRoles: { [wsId: string]: Role | null } = {};

      for (const ws of data.data) {
        fetchedRoles[ws.id] = ws.role;
      }
      setRole(fetchedRoles);
    }
  }, [data]);

  const handleManageAccessModalClose = useCallback(() => {
    onClose();
    setCurrentTab(WorkspaceAssignment.ASSIGNED);
  }, []);

  const handleRoleChange = useCallback(
    (wsId: string, wsName: string, role: Role) => {
      if (currentTab === WorkspaceAssignment.ASSIGNED) {
        setUpdatedRole({ wsName, role, wsId });
        executeUpdateRole({ wsId, role });
      } else {
        setRole({
          ...roles,
          [wsId]: role,
        });
      }
    },
    [roles]
  );

  const handleAddClick = useCallback(
    (workspaceId: string, wsName: string, role: Role) => {
      setInvitedWs({ name: wsName, role });
      invite({ workspaceId, role, userEmail: email });
    },
    [email]
  );

  const handleRequestData = useCallback(
    (payload: FilterCollectionPayload) => {
      getWorkspaces(payload);
      setPagination(payload);
    },
    [getWorkspaces]
  );

  const titleKey = useMemo(() => {
    if (currentTab === WorkspaceAssignment.ASSIGNED) {
      return "TeamSettings.TeamUsers.ManageWorkspacesAccessModal.TitleAssigned";
    }
    return "TeamSettings.TeamUsers.ManageWorkspacesAccessModal.TitleUnassigned";
  }, [currentTab]);

  const displayUsername = useMemo(() => {
    if (userName.trim()) {
      return userName;
    }
    return email;
  }, [userName, email]);

  useEffect(() => {
    if (isInviting) {
      return;
    }
    if (invitationError) {
      showError({
        message: t(
          "TeamSettings.TeamUsers.ManageWorkspacesAccessModal.InviteFailed"
        ),
      });
    } else if (invitationResponse) {
      showSuccess({
        message: t(
          "TeamSettings.TeamUsers.ManageWorkspacesAccessModal.InviteSuccess",
          {
            user: displayUsername,
            workspace: invitedWs && invitedWs.name,
            role:
              invitedWs && invitedWs.role === Role.ReportEditor
                ? "an editor"
                : "a viewer",
          }
        ),
      });
      getWorkspaces(DEFAULT_PAYLOAD);
    }
  }, [invitationError, invitationResponse, isInviting]);

  useEffect(() => {
    if (isUnassigning) {
      return;
    }
    if (unassignError) {
      showError({
        message:
          "There was a problem unassigning user from the workspace. Please try again.",
      });
    } else if (unassignResponse) {
      showSuccess({
        message: `${displayUsername} has been unassigned from the workspace`,
      });
      getWorkspaces(DEFAULT_PAYLOAD);
    }
  }, [unassignError, unassignResponse, isUnassigning]);

  useEffect(() => {
    if (isUpdatingRole) {
      return;
    }
    if (updateRoleError) {
      showError({
        message: t(
          "TeamSettings.TeamUsers.ManageWorkspacesAccessModal.EditRoleFailed"
        ),
      });
    } else if (updateRoleResponse && updatedRole) {
      setRole({
        ...roles,
        [updatedRole.wsId]: updatedRole.role,
      });
      const msg =
        updatedRole.role === Role.ReportEditor
          ? "TeamSettings.TeamUsers.ManageWorkspacesAccessModal.EditToEditor"
          : "TeamSettings.TeamUsers.ManageWorkspacesAccessModal.EditToViewer";
      showSuccess({
        message: t(msg, {
          user: displayUsername,
          workspace: updatedRole.wsName,
        }),
      });
      setUpdatedRole(null);
    }
  }, [updateRoleError, updateRoleResponse, isUpdatingRole]);

  return (
    <Modal
      open={open}
      onClose={handleManageAccessModalClose}
      sx={{ maxWidth: 650, minWidth: 600 }}
    >
      <DialogTitle sx={{ padding: 2 }} variant="h5">
        {t(titleKey, {
          name: displayUsername,
        })}
      </DialogTitle>
      <DialogContent sx={{ padding: 2 }}>
        <Tabs value={currentTab} onChange={(_, value) => setCurrentTab(value)}>
          <Tab
            label={t("Common.Assigned")}
            value={WorkspaceAssignment.ASSIGNED}
          />
          <Tab
            label={t("Common.Unassigned")}
            value={WorkspaceAssignment.UNASSIGNED}
          />
        </Tabs>
        <Box sx={{ maxHeight: 500, overflowY: "scroll" }}>
          <DataGrid
            columnHeaderHeight={72}
            rowHeight={67}
            columns={getColumns(
              currentTab,
              handleAddClick,
              unassign,
              roles,
              handleRoleChange,
              isUpdatingRole,
              isGettingWorkspaces
            )}
            data={data}
            isLoading={isGettingWorkspaces}
            payload={pagination}
            onRequestData={handleRequestData}
            paginationModel={pagination}
            onPaginationModelChange={(model) => {
              setPagination(model);
              handleRequestData(model);
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          paddingX: 2,
          paddingY: 1,
        }}
      >
        <LoadingButton
          data-testid="WorkspacesAccessModal__DoneBtn"
          variant="contained"
          onClick={handleManageAccessModalClose}
          loading={isInviting || isUnassigning}
          disabled={isInviting || isUnassigning}
        >
          {t("Common.Done")}
        </LoadingButton>
      </DialogActions>
    </Modal>
  );
}
