import React, { createContext, useState, useContext } from "react";

type ContextType = {
  showAlert: boolean;
  alertText: string;
  alert: (message: string) => void;
  closeAlert: () => void;
};
const ImportDataContext = createContext<ContextType>({
  showAlert: false,
  alertText: "",
  alert: () => {},
  closeAlert: () => {},
});
export function ImportDataContextContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const context = {
    showAlert,
    alertText,
    alert: (message: string) => {
      setAlertText(message);
      setShowAlert(true);
    },
    closeAlert: () => setShowAlert(false),
  };
  return (
    <ImportDataContext.Provider value={context}>
      {children}
    </ImportDataContext.Provider>
  );
}

export function useImportDataContext() {
  return useContext<ContextType>(ImportDataContext);
}
