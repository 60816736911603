import { administrationClient } from "common/client/administrationClient";
import { FilterCollectionPayload } from "models/collection";
import { createAppThunk } from "common/appThunk";
import { ApiErrorModel } from "models/apiErrorModel";
import { TeamModel } from "models/team";

export const getTeamUsers = async (
  teamId: string,
  body: FilterCollectionPayload
) => {
  const { data: response } = await administrationClient.post(
    `teams/${teamId}/users`,
    body
  );

  return response.data;
};

export const getTeamsByUser = createAppThunk<{
  isSystemAdmin: boolean;
  teams: TeamModel[];
}>("teams/getTeamsByUser", async () => {
  const promises = [];
  promises.push(
    administrationClient.get("profile/teams-access"),
    administrationClient.get("profile/teams")
  );
  try {
    const [response, teamsResp] = await Promise.all(promises);
    const teams = teamsResp.data.data;
    return {
      isSystemAdmin: response.data.data.isSuperAdmin,
      teams,
    };
  } catch (error) {
    if ((error as ApiErrorModel).errorCode) {
      throw (error as ApiErrorModel).errorCode;
    }
    throw error;
  }
});

export const removeUserFromTeam = async (userId: string, teamId: string) => {
  const { data: response } = await administrationClient.delete(
    `teams/${teamId}/users/${userId}`
  );

  return response.data;
};

export const assignUserAsTeamAdmin = async (
  teamId: string,
  emails: string[]
) => {
  const { data: response } = await administrationClient.post(
    `teams/${teamId}/users/assign-as-team-admin`,
    emails
  );

  return response.data;
};
