import React, { useRef, DragEvent } from "react";
import { Cancel, ChevronLeft } from "@mui/icons-material";
import {
  CardHeader,
  Paper,
  CardMedia,
  CardContent,
  Chip,
  AlertTitle,
  Stack,
  Typography,
  Divider,
  Alert,
  Button,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useNavigate, useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { ROUTE_PATHS } from "common/constants";
import { WORKSPACE_ROUTES } from "containers/Workspace/config";

export default function ImportContent({
  handleImport,
}: {
  handleImport: (file: File | null) => void;
}) {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [file, setFile] = React.useState<File | null>(null);
  const fileInput = useRef<HTMLInputElement>(null);
  const dropHandler = (ev: DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    const files = [];
    if (ev.dataTransfer?.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (const [, item] of [...ev.dataTransfer.items].entries()) {
        // If dropped items aren't files, reject them
        if (item.kind === "file") {
          const fileItem = item.getAsFile();
          files.push(fileItem);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      files.push(...ev.dataTransfer.files);
    }
    setFile(files[0]);
  };
  return (
    <>
      <CardHeader
        title={
          <Typography variant="h3" align="center">
            {t("workspace.import.title")}
          </Typography>
        }
      />
      <Paper
        data-testid="ImportContent__Droppable"
        onDrop={dropHandler}
        onClick={() => fileInput.current?.click()}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        sx={{
          borderRadius: "16px",
          background: "#ECEFF1",
        }}
      >
        <CardHeader
          title={
            <Typography variant="h5" align="center" fontWeight={700}>
              {t("workspace.import.subtitle")}
            </Typography>
          }
        />
        <CardMedia
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            alignSelf: "stretch",
          }}
        >
          <UploadFileIcon
            sx={{
              width: "150px",
              height: "150px",
              color: "info.main",
              opacity: "0.3",
            }}
          />
        </CardMedia>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
            alignSelf: "stretch",
          }}
        >
          <Typography variant="body1">
            {`Click to browse or drag and drop ${
              file && file.type === "text/csv"
                ? "to replace your file here."
                : "your file here."
            }`}
          </Typography>
          <Typography variant="caption">
            Supports: <span style={{ fontWeight: 700 }}>csv</span>
          </Typography>
        </CardContent>
        {file && <Divider variant="middle" />}
        {file && file.name && file.type === "text/csv" && (
          <CardContent sx={{ display: "flex", justifyContent: "center" }}>
            <Chip
              color="info"
              label={file.name}
              variant="outlined"
              onDelete={() => setFile(null)}
              deleteIcon={<Cancel />}
              sx={{
                borderRadius: "8px",
              }}
            />
          </CardContent>
        )}
        {file && file.type !== "text/csv" && (
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              padding: "10px",
            }}
          >
            <Alert severity="error" variant="outlined">
              <AlertTitle>{t("workspace.import.alert")}:</AlertTitle>
              <Trans
                i18nKey={"workspace.import.alertText"}
                values={{ fileType: "csv" }}
                components={{
                  bold: <span style={{ fontWeight: 700 }} />,
                }}
              >
                We currently support{" "}
                <span style={{ fontWeight: 700 }}>csv</span>
                &nbsp;file import.
              </Trans>
            </Alert>
          </CardContent>
        )}
      </Paper>
      <Stack
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        spacing={"10px"}
        sx={{
          width: "600px",
          marginTop: "24px",
        }}
      >
        <Button
          variant="contained"
          disabled={!file || (file && file.type !== "text/csv")}
          onClick={() => handleImport(file)}
          sx={{ borderRadius: "120px" }}
        >
          {t("workspace.import.buttonText")}
        </Button>
        <Button
          variant="outlined"
          onClick={() =>
            navigate(
              `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${params.wsId}/${WORKSPACE_ROUTES.NEW_CONFIRM}`
            )
          }
          startIcon={<ChevronLeft />}
          sx={{
            borderRadius: "120px",
          }}
        >
          {t("workspace.import.buttonBackText")}
        </Button>
        <Button
          variant="text"
          sx={{ borderRadius: "120px" }}
          onClick={() => {
            navigate(
              `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}`
            );
          }}
        >
          {t("workspace.import.buttonSkipText")}
        </Button>
      </Stack>
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInput}
        data-testid="file-upload-input"
        accept="text/csv"
        onChange={(event) => {
          const selectedFile = (event.target as HTMLInputElement).files?.[0];
          setFile(selectedFile || null);
        }}
      />
    </>
  );
}
