export function formatCountValue(value?: number | null): string {
  if (value === null || value === undefined) {
    return "";
  }
  return Math.abs(value) >= 1000
    ? `${Number((Math.abs(value) / 1000).toFixed(1)).toLocaleString()}K`
    : Number(Math.abs(value).toFixed(1)).toLocaleString();
}

export function formatCurrencyValue(
  value: number | null | undefined,
  keepMinus = false,
  showZero = true
): string {
  if (!value && !showZero) {
    return "";
  }
  if (value === undefined || value === null) {
    return "";
  }
  const formatted =
    Math.abs(value) >= 1000
      ? `${Number((Math.abs(value) / 1000).toFixed(1)).toLocaleString()}K`
      : Number(Math.abs(value).toFixed(1)).toLocaleString();
  if (value < 0) {
    if (keepMinus) {
      return `-$${formatted}`;
    }
    return `($${formatted})`;
  }

  return `$${formatted}`;
}

export function getPeriodAxisLabel(value: string): string {
  const [start, end] = value.split("-");

  if (end === "01") {
    return start;
  }
  if (start === "Q1") {
    return "20" + end;
  }
  if (end === undefined) {
    return "20" + start.slice(2);
  }
  return ".";
}

export function getCustomerCountValue(
  value: number,
  keepMinus = false
): string {
  const formatted = Number(Math.abs(value).toFixed(1)).toLocaleString();

  if (value < 0) {
    if (keepMinus) {
      return `-${formatted}`;
    }
    return `(${formatted})`;
  }

  return formatted;
}

const monthMapping: { [month: string]: string } = {
  "01": "Jan",
  "02": "Feb",
  "03": "Mar",
  "04": "Apr",
  "05": "May",
  "06": "Jun",
  "07": "Jul",
  "08": "Aug",
  "09": "Sep",
  "10": "Oct",
  "11": "Nov",
  "12": "Dec",
};

type PeriodFormat = "MMM-YY" | "MMM. YYYY";

export function formatPeriodText(
  period: string,
  format: PeriodFormat = "MMM-YY"
) {
  if (period.startsWith("Q") || period.startsWith("FY")) {
    return period;
  }

  const [year, month] = period.split("-"); // format YYYY-MM

  if (format === "MMM. YYYY") {
    return `${monthMapping[month]}. ${year}`;
  }
  return `${monthMapping[month]}-${year.slice(2, 4)}`;
}

export function formatFileSize(size: number): string {
  const units = ["bytes", "KB", "MB", "GB"];
  let unitIndex = 0;
  while (size >= 1000 && unitIndex < units.length - 1) {
    size /= 1000;
    unitIndex++;
  }
  return `${size.toFixed(1)} ${units[unitIndex]}`;
}

export const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);
