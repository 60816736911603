import { ApiErrorModel } from "models/apiErrorModel";
import { FileModel } from "models/fileModel";
import extractIdFromPathname from "utils/extractIdFromPathname";
import { ROUTE_PATHS } from "common/constants";

import HttpClient from "./httpClient";

const apiVersion = process.env.REACT_APP_API_VERSION;
const baseUrl = process.env.REACT_APP_API_FILE_MANAGER_BASEURL;

export const dashboardManagerClient = new HttpClient(
  `${baseUrl}${apiVersion}`,
  () => ({
    teamId: extractIdFromPathname(ROUTE_PATHS.TEAMS),
  })
);

export default async function getDashboardsByWorkspace(workspaceId: string) {
  try {
    const { data: response } = await dashboardManagerClient.get(
      `dashboard/workspaces/${workspaceId}/dashboards`
    );
    return response.data as FileModel[];
  } catch (error) {
    if ((error as ApiErrorModel).errorCode) {
      throw (error as ApiErrorModel).errorCode;
    }
    throw error;
  }
}
