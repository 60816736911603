import React from "react";
import { useLocation, useParams } from "react-router-dom";
import PageSlide from "components/PageSlide";
import { teamManagementClient } from "common/client/teamManagementClient";
import { selectRoleByTeam } from "slices/teamSlice";
import { Role } from "models/team";
import { useSelector } from "common/store";
import { administrationClient } from "common/client/administrationClient";
import PageNotFound from "components/Router/PageNotFound";
import { getWorkspaceAccess } from "services/workspaceService";
import useFetch from "utils/hook/useFetch";

import { workspaceConfigs, newWorkspaceConfig } from "./config";
import { DataMappingContextProvider } from "./DataMapping/DataMappingContext";
import { DataMappingContentProps } from "./DataMapping/components/DataMappingContent";
import WorkspaceLayout from "./WorkspaceLayout";

export default function Workspaces() {
  const location = useLocation();
  const pathName = location.pathname.split("/").at(-1);
  const configTable = { ...workspaceConfigs, ...newWorkspaceConfig };
  const config = {
    rows: [],
    subTitle: "",
    description: "",
    ...configTable[pathName as keyof typeof workspaceConfigs],
  };
  const Component =
    config.component as React.FunctionComponent<DataMappingContentProps>;
  const role = useSelector(selectRoleByTeam);
  const { isSystemAdmin, isLoading: isLoadingTeam } = useSelector(
    (state) => state.team
  );
  const { wsId } = useParams();

  const { data: wsRole, isLoading } = useFetch<
    string | undefined,
    Role | null,
    unknown
  >(
    {
      fn: async (id) => {
        if (!id || id === "untitled") {
          return null;
        }
        const access = await getWorkspaceAccess(id);

        return access ? access.role : null;
      },
      payload: wsId,
    },
    [wsId]
  );

  if (isLoadingTeam || isLoading) return null;

  if (role !== Role.Admin && !isSystemAdmin && wsRole !== Role.ReportEditor)
    return <PageNotFound />;

  return (
    <DataMappingContextProvider>
      <WorkspaceLayout>
        <PageSlide>
          <Component {...config} />
        </PageSlide>
      </WorkspaceLayout>
    </DataMappingContextProvider>
  );
}

export async function action({
  params,
  request,
}: {
  params: { wsId?: string };
  request: Request;
}) {
  try {
    const payload = await request.formData();
    switch (request.method) {
      case "POST": {
        if (payload.get("_action") === "archive") {
          const response = await teamManagementClient.post(
            `workspaces/${params.wsId}/archive`
          );
          return {
            ok: true,
            data: response.data,
          };
        }
        return { ok: false };
      }
      case "PUT": {
        const response = await teamManagementClient.put(
          `workspaces/${params.wsId}`,
          {
            name: payload.get("workspaceName"),
          }
        );
        return {
          ok: true,
          data: response.data,
        };
      }
      case "DELETE": {
        const response = await teamManagementClient.delete(
          `workspaces/${params.wsId}`
        );
        return {
          ok: true,
          data: response.data,
        };
      }
    }
  } catch (error) {
    return {
      ok: false,
      error,
    };
  }
  return { statusText: "OK" };
}

export async function loader({
  params,
  request,
}: {
  params: { wsId?: string };
  request: Request;
}) {
  const promises = [administrationClient.get("/profile/teams")];
  if (params.wsId !== "untitled" && !request.url.endsWith("manage")) {
    promises.push(teamManagementClient.get(`workspaces/${params.wsId}`));
  }
  return await Promise.all(promises);
}
