import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import useFetch from "utils/hook/useFetch";
import LoadingButton from "components/Button/LoadingButton";
import _ from "lodash";
import { TeamModel } from "models/team";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { renameTeam } from "services/superAdminService";
import { useToast } from "utils/hook/useNotification";

interface Props {
  team?: TeamModel;
  onCompleted: (team?: TeamModel) => void;
  onCancel: () => void;
}

export default function ModalContentRenameTeam(props: Props) {
  const { t } = useTranslation();
  const { showSuccess, showError } = useToast();

  const [teamName, setTeamName] = useState("");

  useEffect(() => setTeamName(props.team?.name || ""), [props.team]);

  const disableSubmitButton = useMemo(() => {
    const value = teamName?.trim();
    return _.isEmpty(value) || value === props.team?.name;
  }, [teamName, props.team?.name]);

  const fetching = useFetch({
    fn: (payload: { id: string; name: string }) =>
      renameTeam(payload.id, { newName: payload.name }),
  });

  useEffect(() => {
    if (fetching.error) {
      showError({
        message: t("Teams.Modals.RenameTeam.RenameErrorMessage"),
      });
    } else if (fetching.data) {
      showSuccess({
        message: t("Teams.Modals.RenameTeam.RenameSuccessMessage", {
          team: teamName,
        }),
      });
      props.onCompleted({ ...props.team!, name: teamName! });
    }
  }, [fetching.error, fetching.data]);

  function handleOnSubmitButtonClick(): void {
    if (!props.team || !teamName) return;
    fetching.execute({
      id: props.team.id || "",
      name: teamName,
    });
  }

  return (
    <>
      <DialogTitle sx={{ padding: 2 }} variant="h5">
        {t("Teams.Modals.RenameTeam.Title")}
      </DialogTitle>
      <DialogContent sx={{ padding: 2 }}>
        <TextField
          sx={{ marginTop: 2 }}
          variant="outlined"
          fullWidth
          label={t("Teams.Modals.CreateTeam.TeamName")}
          placeholder={t("Teams.Modals.CreateTeam.TeamNamePlaceholder")}
          value={teamName}
          onChange={(x) => setTeamName(x.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{ display: "flex", justifyContent: "flex-start", padding: 2 }}
      >
        <LoadingButton
          loading={fetching.isLoading}
          variant="contained"
          onClick={handleOnSubmitButtonClick}
          disabled={disableSubmitButton}
        >
          {t("Common.Save")}
        </LoadingButton>
        <Button
          variant="text"
          color="secondary"
          onClick={props.onCancel}
          disabled={fetching.isLoading}
        >
          {t("Cancel")}
        </Button>
      </DialogActions>
    </>
  );
}
