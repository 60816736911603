import type { TooltipProps } from "recharts";
import type {
  ValueType,
  NameType,
  Payload,
} from "recharts/types/component/DefaultTooltipContent";
import { Paper, Box } from "@mui/material";
import { ReactNode } from "react";

interface ChartTooltipProps extends TooltipProps<ValueType, NameType> {
  hoveringDataKey: string;
  children: (payload: Payload<ValueType, NameType>) => ReactNode;
  onHoveringChange?: (payload?: Payload<ValueType, NameType>) => void;
}

export default function ChartTooltip({
  active,
  hoveringDataKey,
  payload,
  children,
  onHoveringChange,
}: ChartTooltipProps) {
  if (active && hoveringDataKey !== null) {
    const hoveringArea = payload?.find(
      (data) => data.dataKey === hoveringDataKey
    );
    onHoveringChange && onHoveringChange(hoveringArea);

    return (
      <Paper
        sx={(theme) => ({
          borderRadius: 0.5,
          padding: "4px 8px",
          backgroundColor: theme.palette.grey[700],
          color: theme.palette.primary.contrastText,
          position: "relative",
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {hoveringArea && children(hoveringArea)}
        </Box>
      </Paper>
    );
  } else {
    onHoveringChange && onHoveringChange();
  }
  return null;
}
