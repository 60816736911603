import { Autocomplete, TextField, Chip, SxProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EMAIL_REGEX } from "common/constants";

interface MultipleEmailInputProps {
  values: string[];
  onValueChanges: (values: string[]) => void;
  hasInvalidEmails: boolean;
  setHasInvalidEmail: (value: boolean) => void;
  helperText: string;
  sx?: SxProps;
}

export default function MultipleEmailInput({
  values,
  onValueChanges,
  hasInvalidEmails,
  setHasInvalidEmail,
  helperText,
  sx,
}: MultipleEmailInputProps) {
  const { t } = useTranslation();

  return (
    <Autocomplete
      multiple
      freeSolo
      options={[] as string[]}
      value={values}
      onChange={(_, newValues) => {
        onValueChanges(newValues);
        setHasInvalidEmail(newValues.some((email) => !EMAIL_REGEX.test(email)));
      }}
      renderTags={(tags: readonly string[], getTagProps) =>
        tags.map((option: string, index: number) => {
          const { key, ...tagProps } = getTagProps({ index });
          const isValid = EMAIL_REGEX.test(option);
          return (
            <Chip
              variant="outlined"
              label={option}
              key={key}
              color={isValid ? undefined : "error"}
              {...tagProps}
            />
          );
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          error={hasInvalidEmails}
          variant="outlined"
          label={t("EmailsInputLabel")}
          placeholder={t("EmailInputPlaceholder")}
          helperText={hasInvalidEmails ? t("InvalidEmailError") : helperText}
        />
      )}
      sx={sx}
    />
  );
}
