import {
  ChangeCircleOutlined,
  CheckOutlined,
  ErrorOutlineOutlined,
} from "@mui/icons-material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import { StepIconProps } from "@mui/material/StepIcon";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { FileStatus, ROUTE_PATHS } from "common/constants";
import { t } from "i18next";
import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { WORKSPACE_ROUTES } from "containers/Workspace/config";
import { Role } from "models/team";

interface StepType {
  label: string;
  status: FileStatus;
}

const steps: StepType[] = [
  {
    label: t("Dashboards.Steppers.DataImported"),
    status: FileStatus.DataImported,
  },
  {
    label: t("Dashboards.Steppers.DataMapped"),
    status: FileStatus.DataMapped,
  },
  {
    label: t("Dashboards.Steppers.DataValidated"),
    status: FileStatus.DataValidated,
  },
  {
    label: t("Dashboards.Steppers.DataSubmitted"),
    status: FileStatus.DataSubmitted,
  },
  {
    label: t("Dashboards.Steppers.ReportGenerated"),
    status: FileStatus.ReportGenerated,
  },
];

const iconStyle = { width: "20px", height: "20px" };

function StepStatusIcon(props: StepIconProps) {
  const { active, completed, error, icon } = props;

  let iconLabel: React.ReactNode | undefined;
  if (error) {
    iconLabel = <ErrorOutlineOutlined sx={iconStyle} />;
  } else if (completed) {
    iconLabel = <CheckOutlined sx={iconStyle} />;
  } else if (active) {
    iconLabel = <ChangeCircleOutlined sx={iconStyle} />;
  } else {
    iconLabel = <Typography variant="subtitle2">{icon}</Typography>;
  }

  return (
    <Box padding="2px" display="flex" justifyContent="center">
      {iconLabel}
    </Box>
  );
}

interface Props {
  workspaceId: string;
  dashboardId: string;
  status: number;
  isError?: boolean;
  wsRole?: Role | null;
  teamRole?: Role | null;
}

export default function DashboardStatus(props: Props) {
  const navigate = useNavigate();
  const params = useParams();
  const { t: translate } = useTranslation();

  const statusIndex = useMemo(() => props.status - 3, [props.status]);

  function getStepIconProps(
    index: number,
    activeStatus: FileStatus,
    activeStep: number
  ): Partial<StepIconProps> | undefined {
    let isCompleted = index < activeStep;
    if (index === 0 && activeStep === 0) isCompleted = true;
    if (
      index === 2 &&
      activeStep === 2 &&
      activeStatus === FileStatus.DataValidated
    )
      isCompleted = true;
    if (
      index === 4 &&
      activeStep === 4 &&
      activeStatus === FileStatus.ReportGenerated
    )
      isCompleted = true;

    return {
      completed: isCompleted,
    };
  }

  function getActiveStep(x: number) {
    if (x === 1) return 2;
    if (x === 3) return 4;
    return x;
  }

  return (
    <Stack spacing={3} paddingBottom={3}>
      <Stepper
        alternativeLabel
        sx={{
          marginLeft: "calc(-12.5% + 40px)",
          marginRight: "calc(-12.5% + 40px)",
          "& .MuiStepLabel-label": {
            color: "secondary.light",
          },
          "& .MuiStepLabel-label.Mui-completed": {
            color: "secondary.light",
          },
          "& .MuiStepLabel-label.Mui-active": {
            color: "secondary.light",
          },
          "& .MuiStepLabel-label.Mui-error": {
            color: "warning.main",
          },
          "& .MuiStepLabel-iconContainer.Mui-completed": {
            bgcolor: "secondary.light",
          },
          "& .MuiStepLabel-iconContainer.Mui-active": {
            bgcolor: "secondary.light",
          },
          "& .MuiStepLabel-iconContainer.Mui-error": {
            bgcolor: "warning.main",
          },
        }}
        activeStep={getActiveStep(statusIndex)}
      >
        {steps.map((x, i) => {
          return (
            <Step key={x.label}>
              <Box width="100%" display="flex" justifyContent="center">
                <StepLabel
                  error={props.isError && i === statusIndex}
                  sx={{
                    "& .MuiStepLabel-iconContainer": {
                      width: "24px",
                      height: "24px",
                      color: "white",
                      borderRadius: "12px",
                      justifyContent: "center",
                      bgcolor: "text.disabled",
                    },
                  }}
                  StepIconComponent={StepStatusIcon}
                  StepIconProps={getStepIconProps(
                    i,
                    props.status,
                    getActiveStep(statusIndex)
                  )}
                >
                  {x.label.split(" ").map((y, j) => (
                    <Box key={j}>
                      <strong>{y}</strong>
                    </Box>
                  ))}
                </StepLabel>
              </Box>
            </Step>
          );
        })}
      </Stepper>
      <Box display="flex" justifyContent="center">
        {statusIndex === 3 && !props.isError && (
          <Alert
            variant="outlined"
            severity="info"
            action={
              <Button size="small" color="inherit" variant="text">
                {translate("DashboardStatus.AnalizingAlert.Cancel")}
              </Button>
            }
          >
            <AlertTitle>
              {translate("DashboardStatus.AnalizingAlert.Title")}
            </AlertTitle>
            {translate("DashboardStatus.AnalizingAlert.Description")}
          </Alert>
        )}
        {statusIndex === 4 && props.isError && (
          <Alert
            variant="outlined"
            severity="warning"
            action={
              (props.teamRole === Role.Admin ||
                props.wsRole === Role.ReportEditor) && (
                <Button
                  size="small"
                  color="inherit"
                  variant="text"
                  onClick={() =>
                    navigate(
                      `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${props.workspaceId}/${props.dashboardId}/${ROUTE_PATHS.MANAGE_SOURCE_DATA}/${ROUTE_PATHS.MANAGE_SOURCE_DATA_FILE}`
                    )
                  }
                >
                  {translate("DashboardStatus.MappingErrorAlert.ManageSource")}
                </Button>
              )
            }
          >
            <AlertTitle>
              {translate("DashboardStatus.MappingErrorAlert.Title")}
            </AlertTitle>
            {translate("DashboardStatus.MappingErrorAlert.Description")}
          </Alert>
        )}
        {statusIndex === 2 && props.isError && (
          <Alert
            variant="outlined"
            severity="warning"
            action={
              (props.teamRole === Role.Admin ||
                props.wsRole === Role.ReportEditor) && (
                <Button
                  size="small"
                  color="inherit"
                  variant="text"
                  onClick={() =>
                    navigate(
                      `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${props.workspaceId}/${props.dashboardId}/${WORKSPACE_ROUTES.CUSTOMER_DATA_REVIEW}`
                    )
                  }
                >
                  {translate("DashboardStatus.DataErrorAlert.ReviewData")}
                </Button>
              )
            }
          >
            <AlertTitle>
              {translate("DashboardStatus.DataErrorAlert.Title")}
            </AlertTitle>
            {translate("DashboardStatus.DataErrorAlert.Description")}
          </Alert>
        )}
      </Box>
    </Stack>
  );
}
