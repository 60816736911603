import {
  CustomerByChangeCategoryChartModel,
  RevenueMeasurements,
} from "models/report";

export const USER_ROLE = {
  ADMIN: "admin",
  USER: "user",
};

export enum FileStatus {
  Init = 0,
  DataImported = 3,
  DataMapped = 4,
  DataValidated = 5,
  DataSubmitted = 6,
  ReportGenerated = 7,
  DataRemapped = 8,
}

export const FileActivityText = {
  [FileStatus.Init]: "imported data",
  [FileStatus.DataImported]: "imported data",
  [FileStatus.DataMapped]: "mapped data fields",
  [FileStatus.DataValidated]: "validated data",
  [FileStatus.DataSubmitted]: "submitted data",
  [FileStatus.ReportGenerated]: "Dashboard was generated",
  [FileStatus.DataRemapped]: "changed the source",
};

export const FileStatusText = {
  [FileStatus.Init]: "init",
  [FileStatus.DataImported]: "file imported",
  [FileStatus.DataMapped]: "data mapped",
  [FileStatus.DataValidated]: "data validated",
  [FileStatus.DataSubmitted]: "data submitted",
  [FileStatus.ReportGenerated]: "dashboard generated",
  [FileStatus.DataRemapped]: "data remapped",
};

// eslint-disable-next-line no-redeclare
export namespace FileStatus {
  export function toString(status: FileStatus): string {
    return FileStatus[status];
  }
}

export const DATETIME_FORMAT: Intl.DateTimeFormatOptions = {
  day: "numeric",
  month: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
  hour12: true,
};

interface FieldToMap {
  name: string;
  isRequired?: boolean;
  alias?: string;
}

interface FieldGroup {
  name: string;
  fields: FieldToMap[];
}

export const FIELDS_TO_MAP: FieldGroup[] = [
  {
    name: "Customer Name",
    fields: [
      {
        name: "Customer",
        isRequired: true,
      },
      {
        name: "ParentCustomer",
      },
    ],
  },
  {
    name: "Total Value",
    fields: [
      {
        name: "TotalValue",
        isRequired: true,
      },
    ],
  },
  {
    name: "Contract Date",
    fields: [
      {
        name: "StartDate",
        isRequired: true,
      },
      {
        name: "EndDate",
      },
    ],
  },
  {
    name: "Location",
    fields: [
      {
        name: "Product12",
        alias: "Region",
      },
      {
        name: "Product11",
        alias: "Geography",
      },
    ],
  },
  {
    name: "Quantity",
    fields: [
      {
        name: "Quantity",
      },
    ],
  },
  {
    name: "Contract Data",
    fields: [
      {
        name: "ContractType",
      },
      {
        name: "StartDateforCARR",
      },
      {
        name: "EndDateforCARR",
      },
      {
        name: "SignDate",
      },
    ],
  },
  {
    name: "Misc",
    fields: [
      {
        name: "RecordID",
      },
      {
        name: "Currency",
      },
      {
        name: "Recurrence",
      },
    ],
  },
  {
    name: "Product",
    fields: [
      {
        name: "Product1",
      },
      {
        name: "Product2",
      },
      {
        name: "Product3",
      },
      {
        name: "Product4",
      },
      {
        name: "Product5",
      },
      {
        name: "Product6",
      },
      {
        name: "Product7",
      },
      {
        name: "Product8",
      },
      {
        name: "Product9",
      },
      {
        name: "Product10",
      },
    ],
  },
  {
    name: "Customer Segment",
    fields: [
      {
        name: "CustomerSegment1",
      },
      {
        name: "CustomerSegment2",
      },
      {
        name: "CustomerSegment3",
      },
      {
        name: "CustomerSegment4",
      },
      {
        name: "CustomerSegment5",
      },
      {
        name: "CustomerSegment6",
      },
      {
        name: "CustomerSegment7",
      },
      {
        name: "CustomerSegment8",
      },
      {
        name: "CustomerSegment9",
      },
      {
        name: "CustomerSegment10",
      },
    ],
  },
];

export const REQUIRED_FIELDS_TO_MAP = FIELDS_TO_MAP.flatMap(
  (group) => group.fields
).filter((field) => field.isRequired);

export enum ReportMeasurement {
  ARR,
  MRR,
  CARR,
  CMRR,
}

export enum ReportSegmentation {
  Customer = "Customer Segment",
  Product = "Product",
}

export enum ReportView {
  CustomerType,
  ProductType,
  TopCustomerType,
  TopProductType,
  RevenueByCategoryCustomerLevel,
  CustomersByCategory,
  CohortByTenurePeriod,
  Extras,
}

export const MEASUREMENT_PROPERTIES: {
  [key: string]: keyof RevenueMeasurements;
} = {
  [ReportMeasurement.ARR]: "SumArrAmount",
  [ReportMeasurement.MRR]: "SumMrrAmount",
  [ReportMeasurement.CARR]: "SumCArrAmount",
  [ReportMeasurement.CMRR]: "SumCMrrAmount",
};

export enum RevenueType {
  Monthly,
  Quarterly,
  Yearly,
}

export enum AnalysisType {
  MoM,
  QoQ,
  YoY,
}

export const CUSTOMERS_BY_CATEGORY_TABLE_ROW_KEYS: (keyof CustomerByChangeCategoryChartModel)[] =
  ["Beginning Count", "New Customer", "Customer Reactivation", "Lost Customer"];

export const EMAIL_REGEX =
  /^(?=.{1,64}@)[\w!#$%&'*+/=?^{|}~-]+(?:\.[\w!#$%&'*+/=?^{|}~-]+)*@(?=.{1,255}$)(?:[\dA-Za-z](?:[\dA-Za-z-]*[\dA-Za-z])?\.)+[A-Za-z]{2,}$/;

export const PREDEFINED_COLORS = [
  "var(--color-positive-01)",
  "var(--color-positive-09)",
  "var(--color-positive-17)",
  "var(--color-positive-02)",
  "var(--color-positive-10)",
  "var(--color-positive-18)",
  "var(--color-positive-03)",
  "var(--color-positive-11)",
  "var(--color-positive-19)",
  "var(--color-positive-04)",
  "var(--color-positive-12)",
  "var(--color-positive-20)",
  "var(--color-positive-05)",
  "var(--color-positive-13)",
  "var(--color-positive-21)",
  "var(--color-positive-06)",
  "var(--color-positive-14)",
  "var(--color-positive-22)",
  "var(--color-positive-07)",
  "var(--color-positive-15)",
  "var(--color-positive-23)",
  "var(--color-positive-08)",
  "var(--color-positive-16)",
  "var(--color-positive-24)",
  "var(--color-negative-01)",
  "var(--color-negative-09)",
  "var(--color-negative-17)",
  "var(--color-negative-02)",
  "var(--color-negative-10)",
  "var(--color-negative-18)",
  "var(--color-negative-03)",
  "var(--color-negative-11)",
  "var(--color-negative-19)",
  "var(--color-negative-04)",
  "var(--color-negative-12)",
  "var(--color-negative-20)",
  "var(--color-negative-05)",
  "var(--color-negative-13)",
  "var(--color-negative-21)",
  "var(--color-negative-06)",
  "var(--color-negative-14)",
  "var(--color-negative-22)",
  "var(--color-negative-07)",
  "var(--color-negative-15)",
  "var(--color-negative-23)",
  "var(--color-negative-08)",
  "var(--color-negative-16)",
  "var(--color-negative-24)",
];

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100];

export const axisTopGap = 1.1;

export const ROUTE_PATHS = {
  TEAMS: "teams",
  TEAMS_SETTINGS: "settings",
  TEAMS_SETTINGS_USERS: "users",
  WORKSPACES: "workspaces",
  WORKSPACES_HOME: "home",
  WORKSPACES_REPORT: "report",
  SUPER_ADMIN: "super-admin",
  SUPER_ADMIN_SETTINGS: "settings",
  REPORT_SETTINGS_PARAMETERS: "parameters",
  REPORT_SETTINGS_FILTERS: "filters",
  MANAGE_SOURCE_DATA: "manage-source-data",
  MANAGE_SOURCE_DATA_FILE: "file",
  LOGIN_SUCCESS: "login-success",
  WELCOME: "welcome",
};

export const ROUTES = {
  WORKSPACE: (tmId: string, wsId: string) =>
    `/teams/${tmId}/workspaces/${wsId}/home`,
  REPORT: (tmId: string, wsId: string, dbId: string) =>
    `/teams/${tmId}/workspaces/${wsId}/${dbId}/report`,
  REPORT_SETTINGS: (tmId: string, wsId: string, dbId: string) =>
    `/teams/${tmId}/workspaces/${wsId}/${dbId}/report/settings`,
  REPORT_PARAMETERS: (tmId: string, wsId: string, dbId: string) =>
    `/teams/${tmId}/workspaces/${wsId}/${dbId}/report/settings/${ROUTE_PATHS.REPORT_SETTINGS_PARAMETERS}`,
  REPORT_FILTERS: (tmId: string, wsId: string, dbId: string) =>
    `/teams/${tmId}/workspaces/${wsId}/${dbId}/report/settings/${ROUTE_PATHS.REPORT_SETTINGS_FILTERS}`,
};

export const LAST_ACESS_DEFAULT = "00000000-0000-0000-0000-000000000000";

export enum CustomerLevel {
  Customer,
  CustomerProduct,
}
