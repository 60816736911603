import {
  CUSTOMERS_BY_CATEGORY_TABLE_ROW_KEYS,
  MEASUREMENT_PROPERTIES,
  RevenueType,
} from "common/constants";
import { Column } from "components/Table/models";
import {
  CategoriesAndPeriodsTableModel,
  CustomerByChangeCategoryChartModel,
  CustomersByChangeCategoryResponse,
} from "models/report";
import { ReportSettings } from "slices/reportSlice";
import { formatPeriodText, getCustomerCountValue } from "utils/format";
import { getLastItemOfEachGroup } from "utils/report";

export function getCustomersByChangeCategoryTableColumns(
  periods: CustomerByChangeCategoryChartModel[]
) {
  const columns: Column<CategoriesAndPeriodsTableModel>[] = [
    {
      header: "Change Category",
      render: (data) => data.category,
      footer: "Ending Count",
      sx: {
        position: "sticky",
        left: 0,
        zIndex: 3,
        textWrap: "nowrap",
      },
    },
  ];

  for (const period of periods) {
    const month = formatPeriodText(period.xValue);

    columns.push({
      header: month,
      render: (data) => {
        return getCustomerCountValue(data[month]);
      },
      footer: getCustomerCountValue(period["Ending Count"]),
      sx: {
        textWrap: "nowrap",
      },
    });
  }

  return columns;
}

export function getCustomersByChangeCategoryTableData(
  periods: CustomerByChangeCategoryChartModel[]
): CategoriesAndPeriodsTableModel[] {
  const data = [
    {
      category: "Beginning Count",
    },
    {
      category: "New Customer",
    },
    {
      category: "Customer Reactivation",
    },
    {
      category: "Lost Customer",
    },
  ] as CategoriesAndPeriodsTableModel[];

  for (const period of periods) {
    for (const [index, category] of data.entries()) {
      const month = formatPeriodText(period.xValue);

      category[month] = period[
        CUSTOMERS_BY_CATEGORY_TABLE_ROW_KEYS[index]
      ] as number;
    }
  }

  return data;
}

export function computeReportData(
  data: CustomersByChangeCategoryResponse[],
  reportSettings: ReportSettings
): CustomerByChangeCategoryChartModel[] {
  let groupedData: CustomersByChangeCategoryResponse[] = [];
  if (reportSettings.params.revenueType === RevenueType.Monthly)
    groupedData = getLastItemOfEachGroup(data, "Month");
  if (reportSettings.params.revenueType === RevenueType.Quarterly)
    groupedData = getLastItemOfEachGroup(data, "DISPLAYQTR");
  if (reportSettings.params.revenueType === RevenueType.Yearly)
    groupedData = getLastItemOfEachGroup(data, "FISCALYR");

  return groupedData.map((period) => {
    const key = MEASUREMENT_PROPERTIES[reportSettings.measurement];
    let xValue = "";
    if (reportSettings.params.revenueType === RevenueType.Monthly)
      xValue = period.Month;
    if (reportSettings.params.revenueType === RevenueType.Quarterly)
      xValue = period.DISPLAYQTR;
    if (reportSettings.params.revenueType === RevenueType.Yearly)
      xValue = period.FISCALYR;

    return {
      xValue,
      "Beginning Count": period.StartingCustomers[key] || 0,
      "New Customer":
        (period["NEW CUSTOMER"] && period["NEW CUSTOMER"][key]) || 0,
      "Customer Reactivation":
        (period["CUSTOMER REACTIVATION"] &&
          period["CUSTOMER REACTIVATION"][key]) ||
        0,
      "Lost Customer":
        (period["LOST CUSTOMER"] && period["LOST CUSTOMER"][key]) || 0,
      "Ending Count": period.EndingCustomers[key] || 0,
      year: period.Month.split("-")[0],
    };
  });
}
