import {
  ArrowDropDown,
  PeopleOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import { Typography } from "@mui/material";
import i18n from "common/i18n";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "common/store";

import IconMenu, { IconMenuItemProps } from "./IconMenu";

const options: IconMenuItemProps[] = [
  {
    id: "admin-users",
    icon: <PeopleOutlined />,
    title: i18n.t("AppBar_AdminMenu_AdminUsers"),
  },
  { id: "divider-1" },
  {
    id: "settings",
    icon: <SettingsOutlined />,
    title: i18n.t("AppBar_AdminMenu_Settings"),
  },
];

export default function AdminMenu() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isSystemAdmin } = useSelector((state) => state.team);

  function handleOnOptionSelect(optionId: string): void {
    if (optionId === "admin-users") navigate("/super-admin/settings");
  }
  const optionsByRole = isSystemAdmin
    ? options
    : options.filter(
        (option) => option.id !== "admin-users" && option.id !== "divider-1"
      );
  return (
    <>
      <Typography variant="h6" sx={{ height: 32 }}>
        {t("AppBar_AdminMenu_Title")}
      </Typography>
      <IconMenu
        icon={<ArrowDropDown />}
        options={optionsByRole}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onOptionSelect={handleOnOptionSelect}
      />
    </>
  );
}
