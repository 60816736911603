import { teamManagementClient } from "common/client/teamManagementClient";
import { FilterCollectionPayload } from "models/collection";
import { Role } from "models/team";
import { WorkspaceAccessModel, WorkspaceAssignment } from "models/workspace";

export async function searchWorkspaces(payload: FilterCollectionPayload) {
  const { data: response } = await teamManagementClient.post(
    "/workspaces/search",
    payload
  );
  return response.data;
}

export interface RenameWorkspacePayload {
  name: string;
  description: string;
}

export async function renameWorkspace(
  wsId: string,
  body: RenameWorkspacePayload
) {
  const { data: response } = await teamManagementClient.put(
    `workspaces/${wsId}`,
    body
  );

  return response.data;
}

export async function deleteWorkspace(wsId: string) {
  const { data: response } = await teamManagementClient.delete(
    `workspaces/${wsId}`
  );

  return response.data;
}

export async function archiveWorkspace(wsId: string) {
  const { data: response } = await teamManagementClient.post(
    `workspaces/${wsId}/archive`
  );

  return response.data;
}

export async function getUserWorkspace(
  userId: string,
  state: WorkspaceAssignment,
  payload: FilterCollectionPayload
) {
  const { data: response } = await teamManagementClient.post(
    `users/${userId}/workspaces`,
    { ...payload, filters: { state }, userId }
  );

  return response.data;
}

export async function inviteUserToWorkspaceBatch(
  emails: string[],
  workspaceRoles: { workspaceId: string; role: number }[]
) {
  const payload: { email: string; workspaceId?: string; role?: number }[] = [];

  if (workspaceRoles.length === 0) {
    for (const email of emails) {
      payload.push({ email, role: 3 });
    }
  } else {
    for (const email of emails) {
      for (const ws of workspaceRoles) {
        payload.push({ email, workspaceId: ws.workspaceId, role: ws.role });
      }
    }
  }

  const { data: response } = await teamManagementClient.post(
    `workspace-assignments/batch`,
    payload
  );

  return response.isSuccess;
}

export async function removeUserFromWorkspace(
  userId: string,
  workspaceId: string
) {
  const { data: response } = await teamManagementClient.delete(
    `users/${userId}/workspaces/${workspaceId}`
  );

  return response.isSuccess;
}

export async function updateUserRoleFromWorkspace(
  userId: string,
  workspaceId: string,
  role: Role
) {
  const { data: response } = await teamManagementClient.put(
    `workspace-assignments`,
    { userId, workspaceId, role }
  );

  return response.isSuccess;
}

export async function getWorkspaceAccess(
  wsId: string
): Promise<WorkspaceAccessModel | null> {
  const { data: response } = await teamManagementClient.get(
    `workspaces/${wsId}/get-access`
  );

  return response.data;
}
