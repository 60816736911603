import {
  ArrowBackOutlined,
  FileOpenOutlined,
  SyncAltOutlined,
} from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { ROUTE_PATHS } from "common/constants";
import AppBar from "components/AppBar";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

export default function ManageSourceData() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { t } = useTranslation();
  const urlWorkspaceHome = `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${params.wsId}/${ROUTE_PATHS.WORKSPACES_HOME}`;
  return (
    <>
      <AppBar>
        <Stack direction="row" alignItems={"center"} justifyContent={"center"}>
          <IconButton
            onClick={() => {
              navigate(location.state?.from || urlWorkspaceHome);
            }}
          >
            <ArrowBackOutlined />
          </IconButton>
          <Typography variant="h6" fontWeight={500}>
            Back to {location.state?.page || "Workspace Home"}
          </Typography>
        </Stack>
      </AppBar>
      <div
        style={{
          margin: "24px 24px 0px 24px",
          display: "flex",
          alignItems: "flex-start",
          gap: "24px",
        }}
      >
        <Paper elevation={0} sx={{ p: "24px" }}>
          <Card
            elevation={0}
            sx={{
              minWidth: "280px",
              height: "fit-content",
            }}
          >
            <CardHeader
              title={
                <Typography variant="h6">
                  {t("ManageSourceData.Title")}
                </Typography>
              }
              subheader={
                <Typography variant="body1">
                  {t("ManageSourceData.SubTitle")}
                </Typography>
              }
            />
            <Divider />
            <CardContent>
              <List
                subheader={
                  <Typography variant="body2" fontWeight={700}>
                    {t("ManageSourceData.SourceData")}
                  </Typography>
                }
              >
                <ListItem>
                  <ListItemButton
                    selected
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <FileOpenOutlined />
                    <Typography variant="body1">
                      {t("ManageSourceData.SourceFile")}
                    </Typography>
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <SyncAltOutlined />
                    <Typography variant="body1">
                      {t("ManageSourceData.SyncData")}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Paper>
        <Outlet />
      </div>
    </>
  );
}
