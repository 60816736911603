import React, { useEffect } from "react";
import { ChevronRight } from "@mui/icons-material";
import {
  AppBar,
  Paper,
  Toolbar,
  Stack,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { NavLink, useFetcher, useParams } from "react-router-dom";
import { ROUTE_PATHS } from "common/constants";

export default function WorkspaceLayout({
  children,
}: {
  children?: React.ReactNode;
}) {
  const fetcher = useFetcher();
  const params = useParams();
  useEffect(() => {
    fetcher.load(
      `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${params.wsId}`
    );
  }, [params.wsId]);
  const [teamData, workspaceData] = fetcher.data || [];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <AppBar position="static" sx={{ zIndex: 100 }}>
        <Paper square elevation={0}>
          <Toolbar variant="dense">
            <Stack direction="row" spacing={2} sx={{ flexGrow: 1 }}>
              <Stack direction="row" spacing={2}>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={<ChevronRight />}
                >
                  <Stack direction="row" spacing={2} alignItems={"center"}>
                    <NavLink
                      to={`/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}`}
                    >
                      <Link underline="hover" color="primary">
                        {(fetcher.state === "idle" &&
                          teamData?.data &&
                          teamData?.data?.data?.find(
                            (item: { id: string; name: string }) =>
                              item.id === params.id
                          ).name) ||
                          "<Team Name>"}
                      </Link>
                    </NavLink>
                  </Stack>
                  <NavLink
                    to={
                      params.wsId === "untitled"
                        ? `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/untitled/new`
                        : `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${params.wsId}/home`
                    }
                  >
                    <Link underline="hover" color="primary">
                      {params.wsId === "untitled"
                        ? "Untitled Workspace"
                        : (fetcher.state === "idle" &&
                            workspaceData?.data &&
                            workspaceData?.data?.data.name) ||
                          "<Workspace Name>"}
                    </Link>
                  </NavLink>
                </Breadcrumbs>
              </Stack>
            </Stack>
          </Toolbar>
        </Paper>
      </AppBar>
      {children}
    </div>
  );
}
