import { RuleObject } from "antd/es/form";

const emailRules: RuleObject[] = [
  { required: true, message: "Please input your email!" },
  { type: "email", message: "The input is not valid E-mail!" },
];
const passwordRules: RuleObject[] = [
  { required: true, message: "Please input your password!" },
  {
    pattern:
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&*+=?@^_~])[\w!#$%&*+=?@^~]{8,}$/,
    message: "The password is too weak",
  },
];

const otpRules: RuleObject[] = [
  { required: true, message: "Please input your OTP!" },
];

const nameRules: RuleObject[] = [
  { required: true, message: "Please input your name!" },
];

const organizationRules: RuleObject[] = [
  { required: true, message: "Please input your organization name!" },
];

const companyRules: RuleObject[] = [
  { required: true, message: "Please input your company name!" },
];

const confirmPasswordRules = (password: string): RuleObject[] => [
  { required: true, message: "Please confirm your password!" },
  {
    validator: (_, value) =>
      value === password
        ? Promise.resolve()
        : Promise.reject(new Error("Passwords do not match")),
  },
];

export {
  emailRules,
  passwordRules,
  otpRules,
  nameRules,
  organizationRules,
  companyRules,
  confirmPasswordRules,
};
