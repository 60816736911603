import { AuditModel } from "./common";
import { Role } from "./team";

export interface WorkspaceModel extends AuditModel {
  id: string;
  name: string;
  description: string;
  isArchived: boolean;
  isDeleted: boolean;
  lastProcessedFileStatus: number;
  lastProcessedFileName: string;
  lastActivity: string;
  members: number;
  role: Role | null;
}

export interface WorkspaceAccessModel extends AuditModel {
  workspaceId: string;
  role: Role | null;
}

export enum WorkspaceAssignment {
  ASSIGNED,
  UNASSIGNED,
}
