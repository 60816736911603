import { administrationClient } from "common/client/administrationClient";
import { SignUpPayload } from "models/userModel";

export const validateInviteCode = async (body: { code: string }) => {
  const { data: response } = await administrationClient.post(
    "tenantuser/validate-invite-code",
    body
  );
  return response.data;
};

export const signup = async (role: string, body: SignUpPayload) => {
  const { data: response } = await administrationClient.post(
    `tenantuser/signup-${role}`,
    body
  );
  return response.data;
};
