import { Box, LinearProgress, Paper, TablePagination } from "@mui/material";
import { PAGE_SIZE_OPTIONS } from "common/constants";
import InviteWorkSpaceMemberModal from "containers/InviteWorkSpaceMemberModal";
import { WorkspaceModel } from "models/workspace";
import { useMemo, useRef, useState } from "react";
import { useNavigation, useSearchParams } from "react-router-dom";

import { DataType } from "../types";

import Loading from "./Loading";
import WorkspaceCard from "./WorkspaceCard";

export function CardView({
  data,
  onOptionSelect,
}: {
  data: DataType;
  onOptionSelect: (optionId: string, row: WorkspaceModel) => void;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { state: navState } = useNavigation();
  const rowCountRef = useRef(data.pagination.total || 0);

  const [currentWorkspace, setCurrentWorkspace] = useState<WorkspaceModel>();

  const rowCount = useMemo(() => {
    if (data.pagination.total !== undefined) {
      rowCountRef.current = data.pagination.total;
    }
    return rowCountRef.current;
  }, [data.pagination.total]);

  return (
    <Paper sx={{ width: "100%", border: "none" }} elevation={0}>
      {navState === "loading" && searchParams.get("view") !== "list" ? (
        <>
          <Box sx={{ width: "100%", pt: "24px" }}>
            <LinearProgress />
          </Box>
          <Loading />
        </>
      ) : (
        data.data.map((row) => (
          <WorkspaceCard
            key={row.id}
            ws={row}
            onOptionSelect={onOptionSelect}
          />
        ))
      )}
      <TablePagination
        component="div"
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
        count={rowCount}
        page={searchParams.get("page") ? Number(searchParams.get("page")) : 0}
        onRowsPerPageChange={(event) => {
          setSearchParams((prev) => ({
            ...Object.fromEntries(prev.entries()),
            pageSize: event.target.value.toString(),
          }));
        }}
        onPageChange={(event, newPage) => {
          setSearchParams((prev) => ({
            ...Object.fromEntries(prev.entries()),
            page: newPage.toString(),
          }));
        }}
        rowsPerPage={
          searchParams.get("pageSize")
            ? Number(searchParams.get("pageSize"))
            : PAGE_SIZE_OPTIONS[0]
        }
      />
      <InviteWorkSpaceMemberModal
        open={!!currentWorkspace}
        onClose={() => setCurrentWorkspace(undefined)}
        workspaceName={currentWorkspace?.name || ""}
        workspaceId={currentWorkspace?.id || ""}
      />
    </Paper>
  );
}
