import {
  ArchiveOutlined,
  DeleteOutline,
  MoreHorizOutlined,
} from "@mui/icons-material";
import i18n from "common/i18n";
import { useSelector } from "common/store";
import IconMenu, { IconMenuItemProps } from "components/Menu/IconMenu";
import { Role } from "models/team";
import { selectRoleByTeam } from "slices/teamSlice";

const options: IconMenuItemProps[] = [
  // TODO: Uncomment when ready
  // {
  //   id: "add-to-starred",
  //   icon: <StarBorderOutlined />,
  //   title: i18n.t("Dashboards.Card.AddToStarred"),
  // },
  // { id: "divider-1" },
  {
    id: "archive",
    icon: <ArchiveOutlined />,
    title: i18n.t("Dashboards.Card.ArchiveWorkspace"),
  },
  {
    id: "delete",
    icon: <DeleteOutline />,
    title: i18n.t("Dashboards.Card.DeleteWorkspace"),
    color: "error.main",
  },
];

export default function ContextMenu({
  onOptionSelect,
}: {
  onOptionSelect?: (optionId: string) => void;
}) {
  const role = useSelector(selectRoleByTeam);
  const { isSystemAdmin } = useSelector((state) => state.team);
  const optionsByRole =
    isSystemAdmin || role === Role.Admin
      ? options
      : options.filter(
          (option) => !["delete", "archive", "divider-1"].includes(option.id)
        );
  return (
    <IconMenu
      icon={<MoreHorizOutlined sx={{ color: "white" }} />}
      options={optionsByRole}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      onOptionSelect={onOptionSelect}
    />
  );
}
