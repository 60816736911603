import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ChartTooltip from "components/ChartTooltip";

import { ChartDatum, ChartCommonProps } from "./model";
import { formatLegendLabel } from "./chartUtils";
import useChartAreaHover from "./useChartAreaHover";

interface Props<DataModel> extends ChartCommonProps<DataModel> {
  bars: ChartDatum<DataModel>[];
  layout?: "horizontal" | "vertical";
}

export default function StackBarChart<DataModel>(props: Props<DataModel>) {
  const {
    data,
    layout = "horizontal",
    xAxisKey,
    bars,
    xAxisProps,
    yAxisProps,
    renderTooltip,
    margin,
    hideLegend,
  } = props;
  const { hoveringArea, handleMouseOut, handleMouseOver } =
    useChartAreaHover<DataModel>();

  const axis =
    layout === "vertical" ? (
      <>
        <XAxis
          type="number"
          orientation="top"
          tickLine={false}
          axisLine={false}
          tick={{ fontSize: 11 }}
          {...(xAxisProps as Object)}
        />
        <YAxis
          type="category"
          dataKey={xAxisKey as string}
          interval={0}
          tickLine={false}
          axisLine={false}
          tick={{ fontSize: 11 }}
          {...(yAxisProps as Object)}
        />
      </>
    ) : (
      <>
        <XAxis
          type="category"
          dataKey={xAxisKey as string}
          tickLine={false}
          axisLine={false}
          {...(xAxisProps as Object)}
        />
        <YAxis tickLine={false} axisLine={false} {...(yAxisProps as Object)} />
      </>
    );

  return (
    <ResponsiveContainer width={props.width} height={props.height}>
      <BarChart data={data} layout={layout} stackOffset="sign" margin={margin}>
        <CartesianGrid
          strokeDasharray="3 3"
          vertical={layout === "vertical"}
          horizontal={layout === "horizontal"}
        />
        {axis}
        <Tooltip
          isAnimationActive={false}
          cursor={false}
          content={
            renderTooltip ? (
              <ChartTooltip hoveringDataKey={hoveringArea as string}>
                {(payload) => renderTooltip(payload)}
              </ChartTooltip>
            ) : undefined
          }
        />
        {!hideLegend && (
          <Legend
            verticalAlign="top"
            align="left"
            iconType="circle"
            iconSize={12}
            wrapperStyle={{
              fontSize: "12px",
              paddingBottom: 14,
            }}
            formatter={(value) => formatLegendLabel(value)}
          />
        )}
        {bars.map((bar) => (
          <Bar
            key={bar.key as string}
            dataKey={bar.key as string}
            stackId="stack"
            fill={bar.color}
            onMouseOver={() => handleMouseOver(bar.key)}
            onMouseOut={handleMouseOut}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}
