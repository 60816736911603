import {
  AssignmentIndOutlined,
  DeleteOutlined,
  GridViewOutlined,
} from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import useFetch from "utils/hook/useFetch";
import i18n from "common/i18n";
import DataGrid from "components/Table/DataGrid";
import { ApiErrorModel } from "models/apiErrorModel";
import {
  Collection,
  DEFAULT_PAYLOAD,
  FilterCollectionPayload,
} from "models/collection";
import { TeamUserModel } from "models/teamUser";
import { useEffect, useMemo, useState } from "react";
import {
  assignUserAsTeamAdmin,
  getTeamUsers,
  removeUserFromTeam,
} from "services/teamService";
import { useAlert } from "utils/hook/useAlert";
import { useToast } from "utils/hook/useNotification";
import PatternCard from "components/Card/PatternCard";
import { useSelector } from "common/store";
import { useTranslation } from "react-i18next";
import { selectRoleByTeam } from "slices/teamSlice";
import { Role } from "models/team";
import { useParams } from "react-router-dom";
import PageNotFound from "components/Router/PageNotFound";

import InviteTeamMemberModal from "./InviteTeamMemberModal";
import WorkspacesAccessModal from "./WorkspacesAccessModal";

interface TeamUser {
  firstName?: string;
  lastName?: string;
  email?: string;
}

const getTeamUserDisplay = (user: TeamUser) => {
  const { firstName, lastName, email } = user;

  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  return `${email} (${i18n.t("TeamUsers_DataGrid_ColValue_Invited")})`;
};

const getDefaultLogoDisplay = (user: TeamUser) => {
  const { firstName, lastName } = user;

  if (firstName && lastName) {
    return `${firstName[0]}${lastName[0]}`;
  }

  return "";
};

const columns: GridColDef[] = [
  {
    field: "FullName",
    valueGetter: (_, row) => getTeamUserDisplay(row),
    headerName: i18n.t("TeamUsers_DataGrid_ColHeader_Name"),
    minWidth: 400,
    flex: 2,
    renderCell: (params) => (
      <Stack direction="row" alignItems="center">
        <Avatar>{getDefaultLogoDisplay(params.row)}</Avatar>
        <Box
          component="section"
          title={params.value}
          sx={{
            marginLeft: 3,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {params.value}
        </Box>
      </Stack>
    ),
  },
  {
    field: "Role",
    valueGetter: (_, row) => {
      return row.role === Role.Admin ? "Team Admin" : "Team Member";
    },
    headerName: i18n.t("TeamUsers_DataGrid_ColHeader_Role"),
    minWidth: 150,
    flex: 1,
  },
  {
    field: "UpdatedAt",
    valueGetter: () => "N/A",
    headerName: i18n.t("TeamUsers_DataGrid_ColHeader_LastActivity"),
    minWidth: 150,
    flex: 1,
  },
  {
    field: "Workspaces",
    valueGetter: (_, row) => row.workspaces,
    headerName: i18n.t("TeamUsers_DataGrid_ColHeader_WorkspaceAccess"),
    minWidth: 150,
    flex: 1,
  },
];

export default function TeamUserListPage() {
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const { showError, showSuccess } = useToast();
  const params = useParams();
  const currentTeamId = params.id;

  const {
    teams,
    isSystemAdmin,
    isLoading: isLoadingTeam,
  } = useSelector((state) => state.team);

  const teamName = useMemo(() => {
    return teams?.find((team) => team.id === currentTeamId)?.name || "";
  }, [teams, currentTeamId]);

  const [dataPayload, setDataPayload] = useState<FilterCollectionPayload>({
    ...DEFAULT_PAYLOAD,
    orderBy: "UpdatedAt",
    order: "desc",
  });
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedUserName, setSelectedUserName] = useState("");
  const [isInviteMemberModalOpen, setIsInviteMemberModalOpen] = useState(false);

  const {
    isLoading,
    data,
    error,
    execute: fetchTeamUsers,
  } = useFetch<
    FilterCollectionPayload,
    Collection<TeamUserModel>,
    ApiErrorModel
  >(
    {
      fn: (payload) => getTeamUsers(params.id || "", payload),
    },
    [params.id]
  );

  const {
    execute: executeRemoveFromTeam,
    error: removeFromTeamError,
    data: removeFromTeamSuccess,
    isLoading: isRemovingFromTeam,
  } = useFetch<{ userId: string; teamId: string }, string>({
    fn: ({ userId, teamId }) => removeUserFromTeam(userId, teamId),
  });

  const {
    execute: executeChangeToAdmin,
    error: changeToAdminError,
    data: changeToAdminSuccess,
    isLoading: isChangeToAdminLoading,
  } = useFetch<{ teamId: string; email: string }, string>({
    fn: ({ teamId, email }) => assignUserAsTeamAdmin(teamId, [email]),
  });

  const role = useSelector(selectRoleByTeam);

  function handleOnRequestData(payload: FilterCollectionPayload): void {
    fetchTeamUsers(payload);
    setDataPayload(payload);
  }

  function handleRemoveFromTeamClick(userId: string, teamId: string) {
    showAlert({
      title: "Remove user from Team?",
      message:
        "All Team workspaces and reports will become unavailable to this user.",
      confirmLabel: "Remove from team",
      cancelLabel: "Cancel",
      onConfirm: () => executeRemoveFromTeam({ userId, teamId }),
    });
  }

  function handleChangeToTeamAdminClick(teamId: string, email: string) {
    showAlert({
      title: i18n.t(
        "TeamSettings.TeamUsers.ChangeToAdmin.ChangeToAdminModalTitle"
      ),
      message: i18n.t(
        "TeamSettings.TeamUsers.ChangeToAdmin.ChangeToAdminModalMessage"
      ),
      confirmLabel: i18n.t("Confirm"),
      cancelLabel: i18n.t("Cancel"),
      onConfirm: () => executeChangeToAdmin({ teamId, email }),
    });
  }

  function handleManageAccessClick(
    userId: string,
    email: string,
    name: string
  ) {
    setSelectedEmail(email);
    setSelectedUserId(userId);
    setSelectedUserName(name);
  }

  useEffect(() => {
    fetchTeamUsers(dataPayload);
  }, [params.id]);

  useEffect(() => {
    if (isRemovingFromTeam === true) return;
    if (removeFromTeamError) {
      showError({
        message: "There was a problem removing this user. Please try again.",
      });
    } else if (removeFromTeamSuccess) {
      showSuccess({
        message: "User removed.",
      });
      fetchTeamUsers(dataPayload);
    }
  }, [isRemovingFromTeam]);

  useEffect(() => {
    if (isChangeToAdminLoading === true) return;
    if (changeToAdminSuccess) {
      showSuccess({
        message: i18n.t(
          "TeamSettings.TeamUsers.ChangeToAdmin.ChangeToAdminSuccess"
        ),
      });
      fetchTeamUsers(dataPayload);
    } else if (changeToAdminError) {
      showError({
        message: i18n.t(
          "TeamSettings.TeamUsers.ChangeToAdmin.ChangeToAdminFailed"
        ),
      });
    }
  }, [isChangeToAdminLoading]);

  if (isLoadingTeam) return null;
  if (error?.status === 403) return <PageNotFound />;
  if (!isSystemAdmin && role !== Role.Admin) return <PageNotFound />;
  return (
    <>
      <PatternCard
        size="medium"
        title={t("TeamSettingLayout_CardTitle", { team: teamName })}
        actionTitle={t("TeamSettingLayout_CardAction")}
        onActionClick={() => setIsInviteMemberModalOpen(true)}
      >
        <DataGrid
          columns={columns}
          data={data}
          isLoading={isLoading || isRemovingFromTeam}
          payload={dataPayload}
          hasSearchBox={true}
          hasFilterButton={false}
          rowSelection={false}
          isRowSelectable={false}
          onRequestData={handleOnRequestData}
          rowContextMenuOptions={(row) => {
            const isDisableChangeToAdmin = row.role !== Role.User;
            const items = [
              {
                id: "manage-access",
                icon: <GridViewOutlined />,
                title: i18n.t(
                  "TeamSettings.TeamUsers.RowContextMenu.ManageWorkspaceAccess"
                ),
                onClick: () =>
                  handleManageAccessClick(
                    row.id,
                    row.email,
                    `${row.firstName} ${row.lastName}`
                  ),
              },
              { id: "divider-1" },
              {
                id: "change-to-admin",
                icon: <AssignmentIndOutlined />,
                title: i18n.t(
                  "TeamSettings.TeamUsers.RowContextMenu.ChangeToAdmin"
                ),
                disabled: isDisableChangeToAdmin,
                onClick: () =>
                  handleChangeToTeamAdminClick(params.id || "", row.email),
              },
              { id: "divider-2" },
              {
                id: "remove-from-team",
                icon: <DeleteOutlined />,
                title: i18n.t(
                  "TeamSettings.TeamUsers.RowContextMenu.RemoveFromTeam"
                ),
                color: "error.main",
                onClick: () =>
                  handleRemoveFromTeamClick(row.id, params.id || ""),
              },
            ];
            if (row.role === Role.Admin) {
              return items.filter(
                (item) => item.id !== "divider-1" && item.id !== "manage-access"
              );
            }
            return items;
          }}
        />
        <WorkspacesAccessModal
          open={!!selectedEmail && !!selectedUserId}
          onClose={() => {
            fetchTeamUsers(dataPayload);
            setSelectedUserId("");
            setSelectedEmail("");
            setSelectedUserName("");
          }}
          userId={selectedUserId}
          email={selectedEmail}
          userName={selectedUserName}
        />
      </PatternCard>
      <InviteTeamMemberModal
        open={isInviteMemberModalOpen}
        teamName={teamName}
        onClose={() => {
          fetchTeamUsers(dataPayload);
          setIsInviteMemberModalOpen(false);
        }}
      />
    </>
  );
}
