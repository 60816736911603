import React, { createContext, useContext, useState } from "react";
import type { FC } from "react";

interface AbortControllerContextProp {
  abortController: AbortController | null;
  setAbortController: (abortController: AbortController | null) => void;
}
const AbortControllerContext = createContext<AbortControllerContextProp>({
  // eslint-disable-next-line unicorn/no-null
  abortController: null,
  setAbortController: () => {},
});

export const useAbortController = () => useContext(AbortControllerContext);

export const AbortControllerProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [abortController, setAbortController] =
    // eslint-disable-next-line unicorn/no-null
    useState<AbortController | null>(null);
  return (
    <AbortControllerContext.Provider
      value={{
        abortController,
        setAbortController,
      }}
    >
      {children}
    </AbortControllerContext.Provider>
  );
};
