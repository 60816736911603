import FormContainer from "components/FormContainer";

const PageNotFound = () => {
  return (
    <FormContainer>
      <h2>Page Not Found</h2>
    </FormContainer>
  );
};

export default PageNotFound;
