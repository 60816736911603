/* eslint-disable no-unused-vars */
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import getWorkspace from "common/client/teamManagementClient";
import store, { useSelector } from "common/store";
import PageNotFound from "components/Router/PageNotFound";
import { FileModel } from "models/fileModel";
import { WorkspaceModel } from "models/workspace";
import { MutableRefObject, useRef } from "react";
import { Params, useLoaderData } from "react-router-dom";
import { getDashboard } from "services/dashboardManagerService";
import { getReportData } from "services/reportService";
import { selectRoleByTeam } from "slices/teamSlice";

import DashboardAppBar from "./components/DashboardAppBar";
import DashboardNavBar from "./components/DashboardNavBar";
import DashboardToolbar from "./components/DashboardToolbar";
import classes from "./index.module.css";
import SectionRollForward from "./sections/SectionRollForward";
import SectionGrowthRate from "./sections/SectionGrowthRate";
import SectionCountChanges from "./sections/SectionCountChanges";
import SectionTopCustomers from "./sections/SectionTopCustomers";
import SectionCohorts from "./sections/SectionCohorts";

const drawerWidth = 256;

interface PageData {
  error?: boolean;
  dashboard?: FileModel;
  workspace?: WorkspaceModel;
}

export default function DashboardPage() {
  const role = useSelector(selectRoleByTeam);
  const { isLoading: isLoadingTeam } = useSelector((state) => state.team);
  const data = useLoaderData() as PageData;

  const toolbarRef = useRef<HTMLDivElement | null>(null);
  const sectionRefs: { [key: string]: MutableRefObject<HTMLElement | null> } = {
    SectionRollForward: useRef<HTMLElement | null>(null),
    SectionGrowthRate: useRef<HTMLElement | null>(null),
    SectionCountChanges: useRef<HTMLElement | null>(null),
    SectionTopCustomers: useRef<HTMLElement | null>(null),
    SectionCohorts: useRef<HTMLElement | null>(null),
  };

  function handleOnItemClick(key: string): void {
    const heightOffset =
      toolbarRef.current!.offsetTop + toolbarRef.current!.clientHeight;
    const elementOffset = sectionRefs[key]?.current?.offsetTop ?? 0;
    window.scroll({ behavior: "smooth", top: elementOffset - heightOffset });
  }

  if (isLoadingTeam) return null;
  if (!Number.isFinite(role)) {
    return <PageNotFound />;
  }
  return (
    <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
      <CssBaseline />
      <DashboardAppBar workspace={data.workspace} />
      <DashboardNavBar width={drawerWidth} onItemClick={handleOnItemClick} />
      <Box component="main" sx={{ px: 3, marginLeft: `${drawerWidth}px` }}>
        <Toolbar sx={{ marginTop: 2 }} ref={toolbarRef} />
        <DashboardToolbar workspace={data.workspace} />
        <section
          className={classes.sectionRollForward}
          ref={sectionRefs.SectionRollForward}
        >
          <Box marginBottom={3}>
            <SectionRollForward />
          </Box>
        </section>
        <section ref={sectionRefs.SectionGrowthRate}>
          <Box marginBottom={3}>
            <SectionGrowthRate />
          </Box>
        </section>
        <section
          className={classes.sectionCountChanges}
          ref={sectionRefs.SectionCountChanges}
        >
          <Box marginBottom={3}>
            <SectionCountChanges />
          </Box>
        </section>
        <section ref={sectionRefs.SectionTopCustomers}>
          <Box marginBottom={3}>
            <SectionTopCustomers />
          </Box>
        </section>
        <section ref={sectionRefs.SectionCohorts}>
          <Box marginBottom={3}>
            <SectionCohorts />
          </Box>
        </section>
      </Box>
    </Box>
  );
}

export async function loader({
  params,
}: {
  params: Params;
}): Promise<PageData> {
  const currentDbId = store.getState().report.dashboardId;
  const dashboardId = params.dashboardId ?? "";
  const workspaceId = params.wsId ?? "";
  const response = await Promise.all([
    getDashboard(workspaceId, dashboardId),
    getWorkspace(workspaceId),
  ]);
  if (dashboardId !== currentDbId) store.dispatch(getReportData(response[0]));
  return { dashboard: response[0], workspace: response[1] };
}
