import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { FileModel } from "models/fileModel";
import { getDashboards } from "services/dashboardManagerService";
import error from "utils/error";

export interface FileState {
  isLoading: boolean;
  dashboards?: FileModel[] | null;
  error?: string;
}

function handleGetDashboards(builder: ActionReducerMapBuilder<FileState>) {
  builder
    .addCase(getDashboards.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    })
    .addCase(getDashboards.fulfilled, (state, action) => {
      state.isLoading = false;
      state.dashboards = action.payload;
    })
    .addCase(getDashboards.rejected, (state, action) => {
      state.isLoading = false;
      state.error = error(action.error);
    });
}

export const initialState: FileState = {
  isLoading: false,
};

const slice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    updateDashboard(
      state,
      action: PayloadAction<{
        dashboardId: string;
        data: Partial<FileModel>;
      }>
    ) {
      if (!state.dashboards) {
        return;
      }
      const { dashboardId, data } = action.payload;
      const fileIndex = state.dashboards.findIndex(
        (dahboard) => dahboard.id === dashboardId
      );

      state.dashboards[fileIndex] = {
        ...state.dashboards[fileIndex],
        ...data,
      };
    },
  },
  extraReducers: (builder) => {
    handleGetDashboards(builder);
  },
});

export const { updateDashboard } = slice.actions;
export default slice.reducer;
