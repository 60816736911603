// Use "any" to match Recharts' type
// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
export function formatLegendLabel(value: any) {
  return (
    <span style={{ color: "var(--neutral-07)", verticalAlign: "middle" }}>
      {value}
    </span>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
export function calculateMaxChartValue(obj: any, listOfKeyToCalculate: any) {
  let sum = 0;

  for (const key in obj) {
    if (
      !Number.isNaN(obj[key]) &&
      obj[key] > 0 &&
      listOfKeyToCalculate.some((e: any) => e.key === key) // eslint-disable-line @typescript-eslint/no-explicit-any
    )
      sum += Number.parseInt(obj[key]);
  }

  return sum;
}
