import React, { forwardRef } from "react";
import { CheckCircleOutlined } from "@mui/icons-material";
import { Alert } from "@mui/material";

const SuccessSnackbar = forwardRef<HTMLDivElement, { message: string }>(
  (props, ref) => (
    <Alert
      ref={ref}
      severity="success"
      variant="filled"
      sx={{
        width: "fit-content",
        maxWidth: "600px",
        borderRadius: "4px",
        backgroundColor: "success.main",
      }}
      icon={<CheckCircleOutlined />}
    >
      {props.message}
    </Alert>
  )
);
SuccessSnackbar.displayName = "CustomSnackbar";

export default SuccessSnackbar;
