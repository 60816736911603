import { Close } from "@mui/icons-material";
import type { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import React from "react";

interface Props {
  open: boolean;
  minWidth?: number;
  onClose?: () => void;
  sx?: SxProps;
}

const Modal: React.FC<Props> = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      PaperProps={{
        sx: {
          padding: 3,
          minWidth: props.minWidth,
          ...props.sx,
        },
      }}
      disableScrollLock
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <IconButton aria-label="close" onClick={props.onClose}>
          <Close />
        </IconButton>
      </Box>
      {props.children}
    </Dialog>
  );
};

export default Modal;
