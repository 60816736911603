import { CustomerLevel } from "common/constants";
import { ChartDatum } from "components/Charts/model";
import { RevenueByChangeCategoryChartModel } from "models/report";
import {
  CUSTOMER_LEVEL_RF_CHART_BARS,
  CUSTOMER_PRODUCT_LEVEL_RF_CHART_BARS,
} from "common/constants/rollForwardReport";

const bars = {
  "Beginning Balance": {
    key: "Beginning Balance",
    color: "var(--color-positive-02)",
  },
  "New Customer": {
    key: "New Customer",
    color: "var(--color-positive-09)",
    clickable: true,
  },
  Upsell: {
    key: "Upsell",
    color: "var(--indigo-02)",
    clickable: true,
  },
  "Customer Reactivation": {
    key: "Customer Reactivation",
    color: "var(--violet-primary)",
    clickable: true,
  },
  "Cust Segment Migration": {
    key: "Cust Segment Migration",
    color: "var(--color-negative-01)",
    clickable: true,
  },
  "Ending/Beginning Revenue Credits": {
    key: "Ending/Beginning Revenue Credits",
    color: "var(--color-positive-18)",
    clickable: true,
  },
  Downsell: {
    key: "Downsell",
    color: "var(--color-negative-07)",
    clickable: true,
  },
  "Lost Customer": {
    key: "Lost Customer",
    color: "var(--color-negative-05)",
    clickable: true,
  },
  "Cross-Sell": {
    key: "Cross-Sell",
    color: "var(--color-positive-22)",
    clickable: true,
  },
  "Lost Product": {
    key: "Lost Product",
    color: "var(--color-negative-09)",
    clickable: true,
  },
  "Product Reactivation": {
    key: "Product Reactivation",
    color: "var(--color-positive-16)",
    clickable: true,
  },
} as const;

export function getBars(
  customerLevel: CustomerLevel
): ChartDatum<RevenueByChangeCategoryChartModel>[] {
  if (customerLevel === CustomerLevel.CustomerProduct) {
    return CUSTOMER_PRODUCT_LEVEL_RF_CHART_BARS.map(
      (category) => bars[category]
    );
  }
  return CUSTOMER_LEVEL_RF_CHART_BARS.map((category) => bars[category]);
}
