import {
  CardHeader,
  CardContent,
  CardActions,
  Card,
  Typography,
  Button,
  Stack,
  IconButton,
  Backdrop,
  Fade,
  Modal,
  Paper,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export default function SubmitConfirmModal({
  open,
  setOpenModal,
  onSubmit,
}: {
  open: boolean;
  setOpenModal: (value: boolean) => void;
  onSubmit: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Modal
      aria-labelledby="transition-modal-title-mapping-confirm"
      aria-describedby="transition-modal-description-mapping-confirm"
      open={open}
      onClose={() => setOpenModal(false)}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Paper
          elevation={4}
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "16px",
            maxWidth: "600px",
          }}
        >
          <div
            style={{
              background:
                "linear-gradient(270deg, var(--palette-primary-light, #0097A7) 0%, #293252 100%)",
              height: "16px",
              borderRadius: "16px 16px 0 0",
            }}
          ></div>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{
              width: "100%",
              px: "24px",
            }}
          >
            <IconButton onClick={() => setOpenModal(false)}>
              <Close />
            </IconButton>
          </Stack>
          <Card
            sx={{
              p: "24px",
              pt: 0,
              borderRadius: "16px",
            }}
          >
            <CardHeader
              title={
                <Typography variant="h5">
                  {t("MappingReview.AnalysisConfirmModal.Header")}
                </Typography>
              }
            />
            <CardContent>
              <Typography variant="body1">
                {t("MappingReview.AnalysisConfirmModal.Body")}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                onClick={() => {
                  setOpenModal(false);
                  onSubmit();
                }}
              >
                {t("MappingReview.AnalysisConfirmModal.StartBtn")}
              </Button>
              <Button onClick={() => setOpenModal(false)}>{t("Cancel")}</Button>
            </CardActions>
          </Card>
        </Paper>
      </Fade>
    </Modal>
  );
}
