import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
} from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ARRCohortReport, CohortReport, CARRCohortReport } from "models/report";
import lodashGroupBy from "lodash/groupBy";
import { ReportMeasurement, RevenueType } from "common/constants";
import {
  formatPeriodText,
  formatCountValue,
  formatCurrencyValue,
} from "utils/format";
import lodashOrderBy from "lodash/orderBy";
import { getLastItemOfEachGroup } from "utils/report";

import {
  getTenureTotal,
  getBgColor,
  getTextColor,
  getColumns,
  getTenureKey,
  getTenureHeader,
} from "./utils";

interface CohortHeatMapProps {
  data: CohortReport[];
  axis: "cohort-tenure" | "calendar-period";
  revenueType: RevenueType;
  measurement: ReportMeasurement;
  show: "measure" | "count" | "volume";
}

const measurementKeyMapping = {
  [ReportMeasurement.ARR]: "SumArrAmount",
  [ReportMeasurement.MRR]: "SumMrrAmount",
  [ReportMeasurement.CARR]: "SumCArrAmount",
  [ReportMeasurement.CMRR]: "SumCMrrAmount",
};

const calendarPeriodMapping = {
  [RevenueType.Monthly]: "Month",
  [RevenueType.Quarterly]: "DISPLAYQTR",
  [RevenueType.Yearly]: "FISCALYR",
};

export default function CohortHeatMap({
  data,
  axis,
  revenueType,
  measurement,
  show,
}: CohortHeatMapProps) {
  const { t } = useTranslation();

  const headerKey = useMemo(() => {
    if (axis === "calendar-period") {
      return calendarPeriodMapping[revenueType];
    }
    return getTenureKey(measurement, revenueType);
  }, [axis, measurement, revenueType]);

  const valueKey = useMemo(() => {
    if (show === "count") {
      return "TotalCountCustomer";
    }
    return measurementKeyMapping[measurement];
  }, [show, measurement]);

  const groupedData = useMemo(() => {
    const groupKey =
      measurement === ReportMeasurement.ARR ||
      measurement === ReportMeasurement.MRR
        ? "ARRSTARTDATE"
        : "CARRSTARTDATE";
    return lodashGroupBy(data, (item) => item[groupKey as keyof CohortReport]);
  }, [data, measurement]);

  const columns = useMemo(() => {
    return getColumns(groupedData, headerKey);
  }, [data, headerKey]);

  const lastPeriodOfEachGroup = useMemo(() => {
    const sorted = lodashOrderBy(data, ({ Month }) => Month);
    return getLastItemOfEachGroup(
      sorted,
      calendarPeriodMapping[revenueType] as keyof CohortReport
    );
  }, [data, revenueType]);

  const rows = useMemo(() => {
    const sorted = lodashOrderBy(Object.entries(groupedData), (key) => key);

    const rowsByMonth = sorted.map(([key, values]) => {
      const filteredValues = getLastItemOfEachGroup(
        lodashOrderBy(values, (item) => item.Month),
        headerKey as keyof CohortReport
      );

      return {
        key,
        data: filteredValues,
      };
    });
    if (
      headerKey === "ARRTENURE" ||
      headerKey === "CARRTENURE" ||
      headerKey === "Month"
    ) {
      return rowsByMonth;
    }
    const summedRows: { key: string; data: CohortReport[] }[] = [];

    for (const [index, period] of lastPeriodOfEachGroup.entries()) {
      const filtered = rowsByMonth.filter((r) => {
        const month = r.key.slice(0, 7);

        if (index === 0) {
          return month <= period.Month;
        } else if (index === lastPeriodOfEachGroup.length - 1) {
          return month > lastPeriodOfEachGroup[index - 1].Month;
        } else {
          return (
            month > lastPeriodOfEachGroup[index - 1].Month &&
            month <= period.Month
          );
        }
      });
      if (filtered.length > 0) {
        const cols: CohortReport[] = [];

        for (const col of columns) {
          let TotalCountCustomer: number | null = null;
          let SumArrAmount: number | null = null;
          let SumMrrAmount: number | null = null;
          let SumCArrAmount: number | null = null;
          let SumCMrrAmount: number | null = null;

          for (const p of filtered) {
            const current = p.data.find(
              (i) => i[headerKey as keyof CohortReport] === col
            );
            if (
              current?.TotalCountCustomer !== null &&
              current?.TotalCountCustomer !== undefined
            ) {
              TotalCountCustomer = TotalCountCustomer || 0;
              TotalCountCustomer += current?.TotalCountCustomer || 0;
            }
            if (
              (current as ARRCohortReport)?.SumArrAmount !== null &&
              (current as ARRCohortReport)?.SumArrAmount !== undefined
            ) {
              SumArrAmount = SumArrAmount || 0;
              SumArrAmount += (current as ARRCohortReport)?.SumArrAmount || 0;
            }
            if (
              (current as ARRCohortReport)?.SumMrrAmount !== null &&
              (current as ARRCohortReport)?.SumMrrAmount !== undefined
            ) {
              SumMrrAmount = SumMrrAmount || 0;
              SumMrrAmount += (current as ARRCohortReport)?.SumMrrAmount || 0;
            }
            if (
              (current as CARRCohortReport)?.SumCArrAmount !== null &&
              (current as CARRCohortReport)?.SumCArrAmount !== undefined
            ) {
              SumCArrAmount = SumCArrAmount || 0;
              SumCArrAmount +=
                (current as CARRCohortReport)?.SumCArrAmount || 0;
            }
            if (
              (current as CARRCohortReport)?.SumCMrrAmount !== null &&
              (current as CARRCohortReport)?.SumCMrrAmount !== undefined
            ) {
              SumCMrrAmount = SumCMrrAmount || 0;
              SumCMrrAmount +=
                (current as CARRCohortReport)?.SumCMrrAmount || 0;
            }
          }

          cols.push({
            Month: period.Month,
            FISCALYR: period.FISCALYR,
            DISPLAYQTR: period.DISPLAYQTR,
            [headerKey]: col,
            TotalCountCustomer,
            SumArrAmount,
            SumMrrAmount,
            SumCArrAmount,
            SumCMrrAmount,
          } as unknown as CohortReport);
        }
        summedRows.push({
          key: period[
            calendarPeriodMapping[revenueType] as keyof CohortReport
          ] as string,
          data: cols,
        });
      }
    }
    return summedRows;
  }, [groupedData, headerKey, lastPeriodOfEachGroup, columns]);

  const maxValueLength = useMemo(() => {
    return Math.max(
      ...rows
        .flatMap((item) => item.data)
        .map((item) => {
          const value = item[valueKey as keyof CohortReport] as number;
          return show === "count"
            ? formatCountValue(value).length
            : formatCurrencyValue(value, true).length;
        })
    );
  }, [rows, valueKey, show]);

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                position: "sticky",
                left: 0,
                zIndex: 3,
                textWrap: "nowrap",
                backgroundColor: "var(--white)",
              }}
            >
              {t("CohortHeatMap.PeriodCol")}
            </TableCell>
            {columns.map((col) => (
              <TableCell
                data-testid="CohortHeatMap__HeaderCell"
                key={col}
                sx={{
                  textWrap: "nowrap",
                }}
              >
                {getTenureHeader(col, columns.length)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(({ key, data: filteredValues }) => {
            return (
              <TableRow data-testid="CohortHeatMap__DataRow" key={key}>
                <TableCell
                  variant="head"
                  sx={{
                    position: "sticky",
                    left: 0,
                    zIndex: 3,
                    textWrap: "nowrap",
                    backgroundColor: "var(--white)",
                  }}
                >
                  {typeof key === "string" ? formatPeriodText(key) : key}
                </TableCell>
                {columns.map((tenure, index) => {
                  const value = filteredValues.find(
                    (i) => i[headerKey as keyof CohortReport] === tenure
                  )?.[valueKey as keyof CohortReport] as number;

                  let previousValue: number | undefined;

                  if (index > 0) {
                    previousValue = filteredValues.find(
                      (i) =>
                        i[headerKey as keyof CohortReport] ===
                        columns[index - 1]
                    )?.[valueKey as keyof CohortReport] as number;
                  }

                  const backgroundColor = getBgColor(value, previousValue);
                  const color = getTextColor(value, previousValue);

                  return (
                    <TableCell
                      key={`tenure-${index}`}
                      sx={{
                        backgroundColor,
                        color,
                        minWidth: `${maxValueLength * 10}px`,
                      }}
                    >
                      {show === "count"
                        ? formatCountValue(value)
                        : formatCurrencyValue(value, true)}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell
              variant="head"
              sx={{
                position: "sticky",
                left: 0,
                zIndex: 3,
                textWrap: "nowrap",
                backgroundColor: "var(--white)",
              }}
            >
              {t("CohortHeatMap.TotalLabel")}
            </TableCell>
            {columns.map((col) => {
              const total = getTenureTotal(
                groupedData,
                headerKey,
                col,
                valueKey
              );
              return (
                <TableCell key={col} variant="head">
                  {show === "count"
                    ? formatCountValue(total)
                    : formatCurrencyValue(total, true)}
                </TableCell>
              );
            })}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
